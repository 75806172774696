define('crm/components/dashboard-rewards', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        user: Ember.computed.alias('currentUser.user'),
        salesUnit: Ember.computed.alias('user.salesUnit'),

        rewardsSorting: ['amount'],
        sortedRewards: Ember.computed.sort('salesUnit.rewards', 'rewardsSorting'),

        currentPosition: Ember.computed('sortedRewards.@each', 'user.monthlyCommissions', function () {
            var rewards = this.get('sortedRewards'),
                highestReward = rewards.get('lastObject'),
                monthlyCommissions = this.get('user.monthlyCommissions');

            return monthlyCommissions / highestReward.get('amount') * 100;
        }),

        rewards: Ember.computed('sortedRewards.@each', 'user.monthlyCommissions', function () {
            var rewards = this.get('sortedRewards'),
                monthlyCommissions = this.get('user.monthlyCommissions');

            rewards.forEach(function (reward) {
                var amount = reward.get('amount');

                if (monthlyCommissions >= amount) {
                    reward.set('fulfilled', true);
                }
            });

            return rewards;
        })
    });
});