define('crm/components/dropdown-list/menu', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['dropdown-menu'],
        classNameBindings: ['right:dropdown-menu-right', 'menuClass'],

        menuClass: null,

        right: true
    });
});