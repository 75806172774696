define('crm/models/field', ['exports', 'ember-data', 'crm/models/base', 'crm/mixins/model/persist-relationships', 'crm/config/environment'], function (exports, _emberData, _base, _persistRelationships, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = _base.default.extend(_persistRelationships.default, {
        taskType: attr('number'),
        fieldType: attr('number'),
        label: attr('string'),
        description: attr('string'),
        required: attr('boolean'),
        multiple: attr('boolean'),

        options: hasMany('field-option', {
            persist: true
        }),

        isInput: Ember.computed.equal('fieldType', 1),
        isSelect: Ember.computed.equal('fieldType', 2),
        isTextarea: Ember.computed.equal('fieldType', 3),
        isCheckbox: Ember.computed.equal('fieldType', 4),

        hasOptions: Ember.computed.equal('fieldType', 2),

        taskTypeLabel: Ember.computed('taskType', function () {
            return _environment.default.taskTypes[this.get('taskType')];
        }),
        fieldTypeLabel: Ember.computed('fieldType', function () {
            return _environment.default.fieldTypes[this.get('fieldType')];
        })
    });
});