define('crm/models/regularly-invoiced-item', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        description: _emberData.default.attr('string'),
        qty: _emberData.default.attr('string'),
        price: _emberData.default.attr('float', {
            defaultValue: 0
        }),
        note: _emberData.default.attr('string'),
        client: _emberData.default.belongsTo('client'),
        invoicesLimit: _emberData.default.attr('number'),
        invoiced: _emberData.default.attr('number'),

        canBeInvoiced: Ember.computed('invoiced', 'invoicesLimit', function () {
            return this.get('invoicesLimit') ? this.get('invoiced') < this.get('invoicesLimit') : true;
        })
    });
});