define('crm/components/comment-bubble', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    exports.default = Ember.Component.extend({
        classNames: ['comment'],
        classNameBindings: ['isMine:comment-me'],
        comment: null,

        isMine: alias('comment.isMine')
    });
});