define('crm/services/entities', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      get = Ember.get,
      computed = Ember.computed,
      filterBy = Ember.computed.filterBy,
      service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),
    currentUser: service(),

    users: computed(function () {
      return get(this, 'store').peekAll('user');
    }),
    activeUsers: filterBy('users', 'isActive', true),
    employedUsers: filterBy('users', 'isEmployed', true),
    visibleUsers: computed('currentUser.user.{isSuperadmin,managingTeams.[]}', 'employedUsers', function () {
      var users = get(this, 'employedUsers'),
          managingTeams = get(this, 'currentUser.user.managingTeams').map(function (team) {
        return get(team, 'id');
      });

      return get(this, 'currentUser.isSuperadmin') ? users : users.filter(function (user) {
        var teams = get(user, 'teams').map(function (team) {
          return get(team, 'id');
        }),
            intersection = teams.filter(function (team) {
          return managingTeams.some(function (managingTeam) {
            return managingTeam === team;
          });
        });

        return intersection.length > 0;
      });
    }),

    accountManagers: computed('users.@each.accountingClients', function () {
      return get(this, 'users').filter(function (user) {
        return get(user, 'accountingClients.length') > 0;
      });
    }),

    salesUnits: computed(function () {
      return get(this, 'store').peekAll('sales-unit');
    }),
    roles: computed(function () {
      return get(this, 'store').peekAll('role');
    }),
    teams: computed(function () {
      return get(this, 'store').peekAll('team').sortBy('order');
    }),
    clients: computed(function () {
      return get(this, 'store').peekAll('client');
    }),
    invoicedClients: filterBy('clients', 'hasInvoice', true),
    contractedClients: filterBy('clients', 'isInvoicedMonthly', true),
    reportableClients: filterBy('clients', 'reportable', true),

    invoices: computed(function () {
      return get(this, 'store').peekAll('invoice');
    }),
    questions: computed(function () {
      return get(this, 'store').peekAll('lead-analysis/question');
    }),
    holidays: computed(function () {
      return get(this, 'store').peekAll('holiday');
    }),
    invoicingCompanies: computed(function () {
      return get(this, 'store').peekAll('invoicing-company');
    }),
    invoiceTypes: computed(function () {
      return get(this, 'store').peekAll('invoice-type');
    }),
    fields: computed(function () {
      return get(this, 'store').peekAll('field');
    }),
    workLogCategories: computed(function () {
      return get(this, 'store').peekAll('work-log-category');
    })
  });
});