define('crm/routes/user/commissions', ['exports', 'crm/mixins/routes/loading'], function (exports, _loading) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        model: function model() {
            return Ember.RSVP.hash({
                commissions: this.store.findAll('commission'),
                commissionsDiscounts: this.store.findAll('commission-discount')
            });
        }
    });
});