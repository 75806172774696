define('crm/controllers/user/contracts/add', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend(_modalController.default, {
        entities: service(),

        title: 'Vytvoriť zmluvu',

        wizard: null,

        goToGenerator: function goToGenerator() {
            get(this, 'wizard').next();
        }
    });
});