define('crm/controllers/user/invoice-reminders', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),

        reminderOrders: {
            '-1': 'Pripomienka',
            0: 'Manuálne',
            1: '1. upomienka',
            2: '2. upomienka',
            3: '3. upomienka'
        },
        messageTypes: {
            0: 'SMS',
            1: 'E-mail'
        },

        actions: {
            enterDetailDialog: function enterDetailDialog(model) {
                this.send('openModal', 'user/invoice-reminders/detail', model, 'user/invoice-reminders/detail');
            },
            enterInvoiceDownloadDialog: function enterInvoiceDownloadDialog(model) {
                this.send('openModal', 'user/download-document', model, 'user/invoices/download');
            }
        }
    });
});