define('crm/components/filtered-model', ['exports', 'crm/utils/grid', 'crm/macros/depends'], function (exports, _grid, _depends) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        dependentKeys: computed('filters.[]', function () {
            var keys = [];

            get(this, 'filters').forEach(function (filter) {
                return keys.push(get(filter, 'property'));
            });

            return keys.uniq();
        }),

        computedModel: (0, _depends.default)('model', 'dependentKeys'),
        filteredModel: computed('computedModel.[]', 'filters.[]', function () {
            return this.filter(get(this, 'computedModel'));
        }),

        updateFilters: function updateFilters(filters) {
            set(this, 'filters', filters);
        },
        filter: function filter(model) {
            var filters = get(this, 'filters');

            filters.forEach(function (filter) {
                var property = get(filter, 'property'),
                    operator = get(filter, 'operator'),
                    value = get(filter, 'value');

                model = model.filter(function (row) {
                    if (property.indexOf('[]') !== -1) {
                        var _property$split = property.split('.[].'),
                            _property$split2 = _slicedToArray(_property$split, 2),
                            collectionName = _property$split2[0],
                            collectionProperty = _property$split2[1];

                        return get(row, collectionName).filter(function (child) {
                            var rowValue = get(child, collectionProperty);

                            return (0, _grid.filterValue)(operator, value, rowValue);
                        }).get('length') > 0;
                    } else {
                        var rowValue = get(row, property);

                        return (0, _grid.filterValue)(operator, value, rowValue);
                    }
                });
            });

            return model;
        },
        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'filters', []);

            this.events.subscribe('data-grid:filter', this, 'updateFilters');
        },
        willDestroyElement: function willDestroyElement() {
            this.events.unsubscribe('data-grid:filter', this, 'updateFilters');
        }
    });
});