define('crm/transforms/float', ['exports', 'ember-data', 'crm/utils/numbers'], function (exports, _emberData, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var NumberTransform = _emberData.default.NumberTransform;
    exports.default = NumberTransform.extend({
        serialize: function serialize(deserialized) {
            return this._super((0, _numbers.toFloat)(deserialized));
        }
    });
});