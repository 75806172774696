define('crm/components/navbar-toggle', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['navbar-toggle'],

        click: function click() {
            var $navbar = this.$().parents('.navbar');

            $navbar.toggleClass('open');
        }
    });
});