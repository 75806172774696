define('crm/models/invoicing-company', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    var alias = Ember.computed.alias;
    exports.default = _base.default.extend({
        billingCompany: attr('string'),
        billingAddress: attr('string'),
        billingCity: attr('string'),
        billingZip: attr('string'),

        companyId: attr('number'),
        companyTin: attr('number'),
        companyVat: attr('string'),

        taxPayer: attr('boolean'),
        taxPayerSince: attr('date'),
        tax: attr('number', {
            defaultValue: 0
        }),
        iban: attr('string'),
        logo: attr(),
        stamp: attr(),
        emailBody: attr('string'),

        name: alias('billingCompany')
    });
});