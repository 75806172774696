define('crm/serializers/sales-unit', ['exports', 'crm/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
    exports.default = _application.default.extend(EmbeddedRecordsMixin, {
        attrs: {
            rewards: { embedded: 'always' },
            members: {
                serialize: 'ids',
                deserialize: 'records'
            }
        }
    });
});