define('crm/controllers/user/meetings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Controller.extend({
        entities: service(),

        actions: {
            enterEditDialog: function enterEditDialog(model) {
                this.send('openModal', 'user/meetings/edit', model, 'user/meetings/edit');
            }
        }
    });
});