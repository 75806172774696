define('crm/services/store', ['exports', 'ember-data/store'], function (exports, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = _store.default.extend({
        unloadAll: function unloadAll() {
            var _this = this;

            var args = arguments;
            return new Ember.RSVP.Promise(function (resolve) {
                _this._super.apply(_this, _toConsumableArray(args));

                Ember.run.schedule('destroy', _this, function () {
                    resolve();
                });
            });
        }
    });
});