define('crm/controllers/user/invoice-reminders/detail', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Detail upomienky',

        reminderOrders: {
            '-1': 'Pripomienka',
            '0': 'Manuálne',
            '1': '1. upomienka',
            '2': '2. upomienka',
            '3': '3. upomienka'
        },
        messageTypes: {
            sms: 'SMS',
            email: 'E-mail'
        }
    });
});