define('crm/helpers/and', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.and = and;
    var helper = Ember.Helper.helper;
    function and(params) {
        return params[0] && params[1];
    }

    exports.default = helper(and);
});