define('crm/services/notifications', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        service = Ember.inject.service;
    exports.default = Ember.Service.extend({
        toasts: service('toast-messages'),

        processPusherNotification: function processPusherNotification(data) {
            get(this, 'toasts').info(data.type, {
                autoClose: false
            });
        }
    });
});