define('crm/components/lead-state', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        tagName: 'span',

        color: computed('state', function () {
            var state = get(this, 'state');

            switch (state) {
                case 0:
                    {
                        return '#00bcd4';
                    }

                case 1:
                    {
                        return '#2196f3';
                    }

                case 2:
                    {
                        return '#FF5252';
                    }

                case 3:
                    {
                        return '#FFC400';
                    }

                case 4:
                    {
                        return '#4CAF50';
                    }
            }
        }),

        stateLabel: computed('state', function () {
            var state = get(this, 'state');

            return _environment.default.leadStates[state];
        })
    });
});