define('crm/controllers/user/clients/cancel', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Ukončenie spolupráce',

        onSave: function onSave() {
            this.toasts.info('Spolupráca s klientom bola ukončená.');
        }
    });
});