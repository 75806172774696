define('crm/models/credit-note', ['exports', 'ember-data', 'crm/models/base', 'crm/config/environment', 'crm/mixins/model/persist-relationships', 'moment', 'crm/utils/numbers'], function (exports, _emberData, _base, _environment, _persistRelationships, _moment, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    var get = Ember.get,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        mapBy = _Ember$computed.mapBy,
        alias = _Ember$computed.alias,
        sum = _Ember$computed.sum;
    exports.default = _base.default.extend(_persistRelationships.default, {
        variableSymbol: attr('number'),
        invoice: belongsTo('invoice'),
        issueDate: attr('date', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)();
            }
        }),
        dueDate: attr('date', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)().add(_environment.default.defaultDuePeriod, 'days');
            }
        }),
        deliveryDate: attr('date', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)();
            }
        }),
        noTax: attr('boolean', {
            defaultValue: false
        }),

        client: belongsTo('client'),
        branch: belongsTo('client-branch'),
        items: hasMany('credit-note-item', {
            persist: true
        }),
        invoicingCompany: belongsTo('invoicing-company'),

        prices: mapBy('items', 'price'),
        totalPrice: sum('prices'),
        totalPriceWithVat: computed('totalPrice', 'invoicingCompany.taxPayer', function () {
            var taxPayer = get(this, 'invoicingCompany.taxPayer'),
                noTax = get(this, 'noTax');

            return (0, _numbers.toFloat)(get(this, 'totalPrice') * (taxPayer && !noTax ? 1.2 : 1));
        }),

        clientName: alias('client.name')
    });
});