define('crm/controllers/user/teams/edit', ['exports', 'crm/controllers/user/teams/add'], function (exports, _add) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _add.default.extend({
        title: 'Upraviť tím',

        saved: function saved() {
            this.get('toasts').success('Tím bol uložený.');
        }
    });
});