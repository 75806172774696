define('crm/components/lead-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        classNames: ['lead-card'],
        classNameBindings: ['expandMeeting:lead-card-expand-meeting'],
        expandMeeting: false,
        expandNotes: false,
        showState: false,

        notes: computed('lead.latestNotes.@each', function () {
            return get(this, 'lead.latestNotes').slice(0, 3);
        }),

        onClick: function onClick() {},
        openDetail: function openDetail() {
            get(this, 'onClick')(get(this, 'lead'));
        }
    });
});