define('crm/routes/user/documents', ['exports', 'crm/mixins/routes/loading'], function (exports, _loading) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        model: function model() {
            return Ember.RSVP.hash({
                folders: this.store.findAll('folder')
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            var folder = model.folders.get('firstObject');

            if (folder) {
                controller.set('folder', folder);
            }
        }
    });
});