define('crm/controllers/user/production', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),

        totalAmounts: Ember.computed.mapBy('model.deliveryNotes', 'totalPrice'),
        highestAmount: Ember.computed.max('totalAmounts'),

        downloadInvoice: function downloadInvoice(invoice) {
            this.send('openModal', 'user/download-document', invoice, 'user/invoices/download');
        },
        downloadInvoices: function downloadInvoices(invoices) {
            this.send('openModal', 'user/download-document', invoices, 'user/invoices/download');
        },
        createInvoice: function createInvoice(model) {
            if (confirm('Vytvorením faktúry nebude možné meniť dodací list. Chcete pokračovať?')) {
                this.send('openModal', 'user/production/create-invoice', model, 'user/production/create-invoice');
            }
        },


        actions: {
            enterEditDialog: function enterEditDialog(model) {
                this.send('openModal', 'user/production/edit', model, 'user/production/edit');
            },
            enterReceivedDialog: function enterReceivedDialog(model) {
                this.send('openModal', 'user/production/confirm', model, 'user/production/confirm');
            },
            enterDownloadDialog: function enterDownloadDialog(model) {
                this.send('openModal', 'user/download-document', model, 'user/production/download');
            }
        }
    });
});