define('crm/routes/login', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        service = Ember.inject.service;
    exports.default = Ember.Route.extend({
        session: service(),

        beforeModel: function beforeModel() {
            if (get(this, 'session.isAuthenticated')) {
                this.transitionTo('user');
            }
        }
    });
});