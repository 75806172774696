define('crm/controllers/user/users', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),
        users: Ember.computed.alias('entities.users'),

        states: {
            0: 'Povolený',
            1: 'Zakázaný'
        },

        actions: {
            enterCreateDialog: function enterCreateDialog() {
                this.send('openModal', 'user/users/edit', this.store.createRecord('user'), 'user/users/add');
            },
            enterEditDialog: function enterEditDialog(model) {
                this.send('openModal', 'user/users/edit', model, 'user/users/edit');
            },
            enterChangePasswordDialog: function enterChangePasswordDialog(model) {
                this.send('openModal', 'user/users/changePassword', model, 'user/users/changePassword');
            },
            suspend: function suspend(model) {
                if (window.confirm('Naozaj si želáte zakázať zvoleného používateľa?')) {
                    model.set('suspended', true);
                    model.save();
                }
            },
            activate: function activate(model) {
                model.set('suspended', false);
                model.save();
            }
        }
    });
});