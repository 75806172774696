define('crm/components/range-input', ['exports', 'crm/utils/numbers'], function (exports, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        observer = Ember.observer;
    exports.default = Ember.Component.extend({
        tagName: 'input',
        attributeBindings: ['name'],

        onToFromChanged: observer('to', 'from', function () {
            if (this._slider && !this._slider.is_active) {
                var property = arguments[1];

                this._slider.update(this.getProperties(property));
            }
        }),

        onFinish: function onFinish(data) {
            this.setProperties({
                from: data.from,
                to: data.to
            });
        },
        init: function init() {
            this._super.apply(this, arguments);

            this._slider = null;
        },
        didInsertElement: function didInsertElement() {
            var onFinish = get(this, 'onFinish');

            this.$().ionRangeSlider({
                type: 'double',
                min: get(this, 'min'),
                max: get(this, 'max'),

                force_edges: true,

                prettify: function prettify(value) {
                    return (0, _numbers.toFloat)(value).toFixed(0) + '&euro;';
                },


                onFinish: onFinish.bind(this)
            });
            this._slider = this.$().data('ionRangeSlider');
        }
    });
});