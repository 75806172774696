define('crm/components/user-picture', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['user-picture'],
        storageURL: _environment.default.storageURL,

        actions: {
            deletePicture: function deletePicture() {
                this.sendAction('onDelete');
            }
        }
    });
});