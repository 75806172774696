define('crm/models/invoice', ['exports', 'ember-data', 'crm/models/base', 'crm/mixins/model/persist-relationships', 'crm/utils/numbers', 'moment'], function (exports, _emberData, _base, _persistRelationships, _numbers, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    var get = Ember.get,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        mapBy = _Ember$computed.mapBy,
        sort = _Ember$computed.sort,
        alias = _Ember$computed.alias,
        sum = _Ember$computed.sum;
    exports.default = _base.default.extend(_persistRelationships.default, {
        variableSymbol: attr('number'),

        issueDate: attr('date', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)();
            }
        }),
        deliveryDate: attr('date', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)();
            }
        }),
        dueDate: attr('date'),

        sendReminder: attr('boolean', {
            defaultValue: true
        }),
        noTax: attr('boolean', {
            defaultValue: false
        }),
        collection: attr('boolean'),

        client: belongsTo('client'),
        branch: belongsTo('client-branch'),
        items: hasMany('invoice-item', {
            persist: true
        }),
        deliveryNote: belongsTo('delivery-note'),
        creditNotes: hasMany('credit-note'),
        payments: hasMany('payment'),
        invoicingCompany: belongsTo('invoicing-company'),
        invoiceType: belongsTo('invoice-type'),

        prices: mapBy('items', 'price'),
        totalPrice: sum('prices'),
        totalPriceWithVat: computed('totalPrice', 'noTax', 'invoicingCompany.taxPayer', function () {
            var taxPayer = get(this, 'invoicingCompany.taxPayer'),
                noTax = get(this, 'noTax');

            return (0, _numbers.toFloat)(get(this, 'totalPrice') * (taxPayer && !noTax ? 1.2 : 1));
        }),

        sortedPayments: sort('payments', function () {
            return ['date'];
        }),
        paidAmounts: mapBy('sortedPayments', 'paidAmount'),
        paidAmount: sum('paidAmounts'),
        paidAmountDifference: computed('paidAmount', 'totalPriceWithVat', function () {
            var paidAmount = (0, _numbers.toFloat)(Math.abs(get(this, 'paidAmount'))),
                totalPriceWithVat = get(this, 'totalPriceWithVat');

            return (0, _numbers.toFloat)(totalPriceWithVat - paidAmount);
        }),
        lastPayment: alias('sortedPayments.lastObject'),

        isPaid: computed('sortedPayments.@each', function () {
            return get(this, 'sortedPayments.length') > 0;
        }),
        isFullyPaid: computed('paidAmountDifference', function () {
            return get(this, 'paidAmountDifference') === 0;
        }),
        isDue: computed('dueDate', 'isFullyPaid', function () {
            if (get(this, 'isPaid')) {
                return false;
            }

            var now = (0, _moment.default)(new Date()),
                dueDate = (0, _moment.default)(get(this, 'dueDate'));

            return dueDate.diff(now, 'days') <= 3;
        }),
        isOverdue: computed('dueDate', 'isFullyPaid', function () {
            if (get(this, 'isPaid')) {
                return false;
            }

            var now = (0, _moment.default)(new Date()),
                dueDate = (0, _moment.default)(get(this, 'dueDate'));

            return now.diff(dueDate, 'days') > 0;
        }),
        isUnpaid: computed.not('isPaid'),
        isPaidOverdue: computed('isFullyPaid', 'lastPayment.date', 'dueDate', function () {
            if (!get(this, 'isFullyPaid')) {
                return false;
            }

            var paid = (0, _moment.default)(get(this, 'lastPayment.date')),
                dueDate = (0, _moment.default)(get(this, 'dueDate'));

            return paid.diff(dueDate, 'days') > 0;
        }),

        state: computed('isPaid', 'isFullyPaid', 'isOverdue', 'isDue', 'isPaidOverdue', function () {
            if (this.get('isOverdue')) {
                return 1;
            }

            if (this.get('isPaidOverdue')) {
                return 4;
            }

            if (this.get('isFullyPaid')) {
                return 3;
            }

            if (this.get('isPaid')) {
                return 2;
            }

            if (this.get('isDue')) {
                return 5;
            }

            return 0;
        }),
        rowClass: computed('isOverdue', function () {
            return get(this, 'isOverdue') ? 'table-overdue' : null;
        }),

        clientName: alias('client.name')
    });
});