define('crm/controllers/user/commissions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),

        user: Ember.computed.alias('currentUser.user'),
        commissions: Ember.computed.alias('currentUser.user.commissions'),

        users: Ember.computed('user.isSuperadmin', 'entities.users.@each', function () {
            var isSuperadmin = this.get('user.isSuperadmin'),
                users = [this.get('user')];

            if (isSuperadmin) {
                users = this.get('entities.users');
            }

            return users;
        }),

        actions: {
            enterEditCommissionDialog: function enterEditCommissionDialog(model) {
                this.send('openModal', 'user/commissions/discount', model, 'user/commissions/discount');
            }
        }
    });
});