define("crm/utils/dates", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var roundTo = exports.roundTo = function roundTo(date, type, offset) {
        var val = date[type](),
            roundedVal = Math.ceil((val + 1) / offset) * offset;

        return date[type](roundedVal);
    };
});