define('crm/controllers/user/invoices/create', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Vytvoriť faktúru',

        entities: Ember.inject.service(),
        clients: Ember.computed.alias('entities.clients'),

        addItem: function addItem() {
            this.get('model.items').createRecord({
                'order': parseInt(this.get('model.items.length')) + 1
            });
        },
        removeItem: function removeItem(item) {
            item.destroyRecord();
        },
        saved: function saved() {
            var self = this;

            this.get('toasts').success('<b>Faktúra bola vytvorená.</b> Klikutím sem ju stiahnete.', {
                onClick: function onClick() {
                    self.send('downloadInvoiceDialog', self.get('model'));
                }
            });
        }
    });
});