define('crm/services/modal-manager', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        getOwner = Ember.getOwner,
        service = Ember.inject.service;
    exports.default = Ember.Service.extend({
        events: service(),

        currentModal: null,

        open: function open(template, model, controller) {
            var _this = this;

            return new Promise(function (resolve) {
                get(_this, 'events').subscribe('modal-dialog:open', _this, function () {
                    get(this, 'events').unsubscribe('modal-dialog:open');

                    resolve();
                });

                _this.getApplicationRoute().render(template, {
                    outlet: 'modal',
                    into: 'application',
                    controller: controller,
                    model: model
                });
            });
        },
        close: function close() {
            var _this2 = this;

            return new Promise(function (resolve) {
                get(_this2, 'events').subscribe('modal-dialog:close', _this2, function () {
                    get(this, 'events').unsubscribe('modal-dialog:close');

                    resolve();
                });

                _this2.getApplicationRoute().disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            });
        },
        getApplicationRoute: function getApplicationRoute() {
            return getOwner(this).lookup('route:application');
        }
    });
});