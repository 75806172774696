define('crm/controllers/user/invoices/pay', ['exports', 'crm/mixins/controller/modal-controller', 'moment'], function (exports, _modalController, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Manuálne uhradenie faktúry',

        maxDate: (0, _moment.default)(),
        minDate: Ember.computed('model.invoice.issueDate', function () {
            return (0, _moment.default)(this.get('model.invoice.issueDate'));
        })
    });
});