define('crm/components/signature-pad', ['exports', 'npm:signature_pad'], function (exports, _npmSignature_pad) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var self = this,
                canvas = this.$().find('canvas')[0],
                signaturePad = new _npmSignature_pad(canvas, {
                onEnd: function onEnd() {
                    self.set('value', signaturePad.toDataURL());
                }
            });
        }
    });
});