define('crm/models/lead-note', ['exports', 'ember-data', 'moment', 'crm/models/base'], function (exports, _emberData, _moment, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    var alias = Ember.computed.alias;
    exports.default = _base.default.extend({
        note: attr('string'),
        createdAt: attr('datetime', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)();
            }
        }),
        message: alias('note'),

        lead: belongsTo('lead'),
        user: belongsTo('user')
    });
});