define('crm/routes/user/work-log/calendar', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        hash = Ember.RSVP.hash;
    exports.default = Ember.Route.extend({
        date: (0, _moment.default)(),

        beforeModel: function beforeModel() {},
        model: function model() {
            var date = (0, _moment.default)(get(this, 'date'));

            return hash({
                date: date,
                report: this.ajax.request('/workLogs/report', {
                    data: {
                        from: date.clone().startOf('month').format('YYYY-MM-DD'),
                        to: date.clone().endOf('month').format('YYYY-MM-DD')
                    }
                })
            });
        },


        actions: {
            reload: function reload(date) {
                set(this, 'date', date);

                this.refresh();
            }
        }
    });
});