define('crm/components/data-filter/filters/checklist', ['exports', 'crm/mixins/component/filter'], function (exports, _filter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        or = _Ember$computed.or,
        gt = _Ember$computed.gt;
    exports.default = Ember.Component.extend(_filter.default, {
        classNames: ['filter-checklist'],

        model: null, // Array of models
        content: null, // Array of plain objects
        searchQuery: null,
        primaryKey: 'id',
        labelProperty: 'name',

        options: computed('content', function () {
            var content = get(this, 'content');

            if (!content) {
                return null;
            }

            var keys = Object.keys(content);

            return keys.map(function (key) {
                return {
                    key: parseInt(key),
                    name: content[key]
                };
            });
        }),
        data: or('{model,options}'),
        search: gt('data.length', 10),
        items: computed('{data.@each,labelProperty,searchQuery}', function () {
            var labelProperty = get(this, 'labelProperty'),
                searchQuery = get(this, 'searchQuery');

            var data = get(this, 'data');

            if (searchQuery) {
                var escapedValue = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

                data = data.filter(function (item) {
                    return new RegExp(escapedValue, 'i').test(get(item, labelProperty));
                });
            }

            return data;
        }),
        computedPrimaryKey: computed('{primaryKey,model}', function () {
            return get(this, 'model') ? get(this, 'primaryKey') : 'key';
        }),

        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'selection', Ember.A());
        },
        updateSelection: function updateSelection(selection) {
            set(this, 'selection', selection);

            Ember.run.schedule('actions', this, function () {
                get(this, 'filter').updateFilters();
            });
        },
        clearSelection: function clearSelection() {
            set(this, 'selection', []);

            Ember.run.schedule('actions', this, function () {
                get(this, 'filter').updateFilters();
            });
        },
        getFilteringCondition: function getFilteringCondition() {
            var selection = get(this, 'selection');

            if (get(selection, 'length') > 0) {
                var primaryKey = get(this, 'computedPrimaryKey'),
                    items = selection.map(function (item) {
                    return get(item, primaryKey);
                });

                return {
                    property: get(this, 'name'),
                    value: items,
                    operator: 'in'
                };
            }
        },
        setFilteringCondition: function setFilteringCondition(condition) {
            var selection = [],
                data = get(this, 'data'),
                primaryKey = get(this, 'computedPrimaryKey');

            condition.value.forEach(function (value) {
                var item = data.findBy(primaryKey, value);

                selection.push(item);
            });

            this.updateSelection(selection);
        },
        clearFilter: function clearFilter() {
            set(this, 'searchQuery', null);
            set(this, 'selection', []);
        }
    });
});