define('crm/controllers/user/reports/create-monthly', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        clientSorting: ['sortId'],
        reportableClients: Ember.computed.filterBy('model.clients', 'reportable', true),
        sortedClients: Ember.computed.sort('reportableClients', 'clientSorting'),

        clients: Ember.computed('sortedClients.@each.signatureDate', function () {
            return this.get('sortedClients').reject(function (client) {
                return (0, _moment.default)(client.get('signatureDate')).isAfter((0, _moment.default)(), 'month');
            });
        }),

        title: Ember.computed('model.dateFrom', 'model.dateTo', function () {
            var from = this.get('model.dateFrom').format('L'),
                to = this.get('model.dateTo').format('L');

            return Ember.String.htmlSafe('Vytvoriť mesačný report <small>(' + from + ' - ' + to + ')</small>');
        }),

        selectClient: function selectClient(client) {
            this.get('clientReport').get('setClient').perform(client);
            this.set('selectedClient', client);

            window.scrollTo(0, 0);
        },
        selectNextClient: function selectNextClient(selectedClient) {
            var clients = this.get('clients'),
                selectedIndex = clients.indexOf(selectedClient);

            selectedClient.set('sent', true);

            if (clients.get('length') > selectedIndex + 1) {
                var nextClient = clients.objectAt(selectedIndex + 1);

                this.selectClient(nextClient);
            }
        },
        createEmployeeDialog: function createEmployeeDialog(client) {
            var employee = client.get('employees').createRecord();

            this.send('openModal', 'user/employees/edit', employee, 'user/employees/add');
        }
    });
});