define('crm/helpers/multiply', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.multiply = multiply;
    var helper = Ember.Helper.helper;
    function multiply(params) {
        return parseFloat(params[0]) * parseFloat(params[1]);
    }

    exports.default = helper(multiply);
});