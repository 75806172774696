define('crm/controllers/user/documents/edit-folder', ['exports', 'crm/controllers/user/documents/create-folder'], function (exports, _createFolder) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _createFolder.default.extend({
        title: 'Premenovať priečinok',

        saved: function saved() {
            this.get('toasts').success('Priečinok bol premenovaný.');
        }
    });
});