define('crm/controllers/user/sales-units', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            enterCreateDialog: function enterCreateDialog() {
                this.send('openModal', 'user/sales-units/edit', this.store.createRecord('sales-unit'), 'user/sales-units/add');
            },
            enterEditDialog: function enterEditDialog(model) {
                this.send('openModal', 'user/sales-units/edit', model, 'user/sales-units/edit');
            },
            deleteSalesUnit: function deleteSalesUnit(model) {
                if (window.confirm('Naozaj si želáte odstrániť obchodnú skupinu?')) {
                    model.destroyRecord();
                }
            }
        }
    });
});