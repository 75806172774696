define('crm/controllers/user/credit-notes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),

        totalAmounts: Ember.computed.mapBy('model.creditNotes', 'totalPrice'),
        highestAmount: Ember.computed.max('totalAmounts'),

        selectedCreditNotes: Ember.A(),

        actions: {
            export: function _export() {
                this.send('openModal', 'user/download-document', this.get('selectedCreditNotes'), 'user/credit-notes/download');
            },
            enterEditDialog: function enterEditDialog(model) {
                this.send('openModal', 'user/credit-notes/edit', model, 'user/credit-notes/edit');
            },
            enterSendDialog: function enterSendDialog(model) {
                this.send('openModal', 'user/credit-notes/send', model, 'user/credit-notes/send');
            }
        }
    });
});