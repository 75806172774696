define('crm/helpers/filesize', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.filesize = filesize;
    function filesize(params) {
        var bytes = params[0],
            sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

        if (bytes === 0) return '0B';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    exports.default = Ember.Helper.helper(filesize);
});