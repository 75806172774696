define('crm/components/file-list-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;
    exports.default = Ember.Component.extend({
        classNames: ['list-group-item', 'd-flex', 'justify-content-between', 'align-items-center'],

        onRemove: function onRemove() {},


        allowActions: true,

        canBeRemoved: Ember.computed('allowActions', 'file.user', 'currentUser.user', function () {
            var currentUser = get(this, 'currentUser.user');

            /**
             * @TODO: Fix user check
             */
            if (get(currentUser, 'isSuperadmin')) {
                return true;
            } else {
                return get(this, 'allowActions');
            }
        }),

        remove: function remove() {
            if (confirm('Naozaj chcete odstrániť tento súbor?')) {
                get(this, 'onRemove')(get(this, 'file'));
            }
        }
    });
});