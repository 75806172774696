define('crm/controllers/user/reports/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        reportSorting: ['sortId:asc'],
        reports: Ember.computed.sort('model.reports', 'reportSorting'),
        entities: Ember.inject.service(),

        actions: {
            createReport: function createReport() {},
            createMonthlyReport: function createMonthlyReport() {
                this.transitionToRoute('user.reports.create-monthly');
            },
            enterDownloadDialog: function enterDownloadDialog(report) {
                this.send('openModal', 'user/download-document', report, 'user/reports/download');
            },
            enterSendDialog: function enterSendDialog(report) {
                this.send('openModal', 'user/reports/send', report, 'user/reports/send');
            },
            enterEditDialog: function enterEditDialog(report) {
                var _this = this;

                this.get('store').unloadAll('work-log');
                this.get('store').unloadAll('delivery-note');

                Ember.RSVP.hash({
                    from: this.get('from'),
                    to: this.get('to'),
                    clients: this.store.findAll('client'),
                    users: this.store.findAll('user'),
                    workLogs: this.store.query('work-log', {
                        from: report.get('from'),
                        to: report.get('to')
                    })
                }).then(function () {
                    _this.send('openModal', 'user/reports/edit', report, 'user/reports/edit');
                });
            }
        }
    });
});