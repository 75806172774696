define('crm/components/drop-zone-queue', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;
    exports.default = Ember.Component.extend({
        classNames: ['dropzone-files'],

        removeFromQueue: function removeFromQueue(file) {
            get(this, 'queue').remove(file);
        }
    });
});