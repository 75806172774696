define('crm/models/payment', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getWithDefault = Ember.getWithDefault,
        computed = Ember.computed;
    exports.default = _base.default.extend({
        date: _emberData.default.attr('datetime'),
        amount: _emberData.default.attr('float'),
        note: _emberData.default.attr('string'),

        invoice: _emberData.default.belongsTo('invoice'),

        paidAmount: computed('amount', function () {
            return Math.abs(getWithDefault(this, 'amount', 0));
        })
    });
});