define('crm/initializers/inject-events', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        application.inject('route', 'events', 'service:events');
        application.inject('controller', 'events', 'service:events');
        application.inject('component', 'events', 'service:events');
    }

    exports.default = {
        name: 'inject-events',
        initialize: initialize
    };
});