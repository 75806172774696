define('crm/controllers/user/clients/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var _Ember$computed = Ember.computed,
        mapBy = _Ember$computed.mapBy,
        max = _Ember$computed.max;
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),

        totalMonthlyFees: mapBy('model.clients', 'monthlyFee'),
        highestMonthlyFee: max('totalMonthlyFees')
    });
});