define('crm/helpers/is-today', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.isWeekend = isWeekend;
    var helper = Ember.Helper.helper;
    function isWeekend(params) {
        var date = params[0];

        return (0, _moment.default)().isSame(date, 'day');
    }

    exports.default = helper(isWeekend);
});