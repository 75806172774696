define('crm/components/state-tag', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        htmlSafe = Ember.String.htmlSafe;
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['badge'],
        attributeBindings: ['style'],

        style: computed('color', function () {
            var color = get(this, 'color');

            return htmlSafe('background: ' + color);
        })
    });
});