define('crm/transforms/duration', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Transform = _emberData.default.Transform;
    exports.default = Transform.extend({
        serialize: function serialize(deserialized) {
            var hours = 0;

            if (!deserialized) {
                return null;
            }

            if (typeof deserialized === 'number') {
                deserialized = Number(deserialized).toString();
            }

            var matches = deserialized.match(/^(?:(\d{1,2})d)?\s*(?:(\d{1,2})h(?:od)?)?\s*(?:(\d{1,3})m(?:in)?)?$/);
            if (matches) {
                // days
                if (matches[1]) {
                    hours += parseInt(matches[1] * 8);
                }
                // hours
                if (matches[2]) {
                    hours += parseInt(matches[2]);
                }
                // minutes
                if (matches[3]) {
                    hours += parseFloat(matches[3] / 60);
                }
            } else {
                hours = parseFloat(deserialized) || 0;
            }

            return hours;
        },

        deserialize: function deserialize(serialized) {
            return serialized;
        }
    });
});