define('crm/routes/user/work-log', ['exports', 'crm/mixins/routes/loading'], function (exports, _loading) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_loading.default, {
    beforeModel: function beforeModel() {
      this.transitionTo('user.work-log.grid');
    },


    actions: {
      openCreateDialog: function openCreateDialog() {
        this.send('openModal', 'user/work-log/add', [], 'user/work-log/add');
      },
      openCreateTableDialog: function openCreateTableDialog() {
        this.send('openModal', 'user/work-log/add-table', [], 'user/work-log/add-table');
      },
      openAuditDialog: function openAuditDialog() {
        this.send('openModal', 'user/work-log/audit', [], 'user/work-log/audit');
      },
      openEditDialog: function openEditDialog(workLog) {
        this.send('openModal', 'user/work-log/edit', workLog, 'user/work-log/edit');
      },
      reloadWorkLogs: function reloadWorkLogs(data) {
        this.setProperties(data);

        this.refresh();
      }
    }
  });
});