define('crm/components/toast-message', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        classNames: ['toast-message'],
        classNameBindings: ['dismissClass', 'clickableClass'],

        toastMessages: service(),

        alertClass: computed('toast.options.type', function () {
            var type = get(this, 'toast.options.type');

            return 'alert alert-' + type;
        }),

        dismissClass: computed('toast.dismiss', function () {
            if (get(this, 'toast.dismiss')) {
                return 'toast-message-dismiss';
            }
        }),

        clickableClass: Ember.computed('toast.options.onClick', function () {
            if (get(this, 'toast.options.onClick')) {
                return 'toast-message-clickable';
            }
        }),

        click: function click() {
            if (!get(this, 'toast.dismiss') && get(this, 'toast.options.onClick')) {
                get(this, 'toast.options.onClick')(get(this, 'toast'));
            }
        },
        dismiss: function dismiss() {
            get(this, 'toastMessages').dismiss(get(this, 'toast'));
        }
    });
});