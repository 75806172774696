define('crm/components/data-loader', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['data-loader'],
        classNameBindings: ['isLoading']
    });
});