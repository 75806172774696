define('crm/components/dropdown-menu', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['dropdown'],
        size: 'btn-sm',
        right: true
    });
});