define('crm/components/navbar/navbar-notes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['navbar-notes'],

        actions: {
            enterStickyNotes: function enterStickyNotes() {
                this.sendAction('enterStickyNotes');
            }
        }
    });
});