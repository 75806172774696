define('crm/components/form-label', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'label',
        classNames: ['form-control-label']
    });
});