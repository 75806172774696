define('crm/models/lead-analysis/answer', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        leadAnalysis: _emberData.default.belongsTo('lead-analysis'),
        question: _emberData.default.belongsTo('lead-analysis/question'),
        value: _emberData.default.attr('string'),
        option: _emberData.default.belongsTo('lead-analysis/question-option'),
        options: _emberData.default.hasMany('lead-analysis/question-option'),
        note: _emberData.default.attr('string')
    });
});