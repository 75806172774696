define('crm/components/dropdown-list/toggle', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'button',
        toggleClass: '',
        classNames: ['btn', 'dropdown-toggle'],
        classNameBindings: ['toggleClass', 'caret::no-caret', 'active'],

        attributeBindings: ['data-toggle', 'disabled'],

        caret: true,
        disabled: false,

        'data-toggle': 'dropdown'
    });
});