define('crm/components/navbar/navbar-quick-access', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'ul',
        classNames: ['navbar-list', 'navbar-quick-access']
    });
});