define('crm/components/leads/lead-setup/employees', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias,
        get = Ember.get,
        set = Ember.set;
    exports.default = Ember.Component.extend({
        classNames: ['modal-section'],

        panes: null,

        client: alias('lead.client'),
        employees: alias('client.employees'),
        state: alias('lead.state'),

        isFormValid: function isFormValid() {
            return this.$().closest('form').valid();
        },
        addEmployee: function addEmployee() {
            var employee = get(this, 'employees').createRecord();

            set(employee, 'isEditing', true);

            set(this, 'employee', employee);
            get(this, 'panes').goTo(2);
        },
        editEmployee: function editEmployee(employee) {
            set(employee, 'isEditing', true);

            set(this, 'employee', employee);
            get(this, 'panes').goTo(2);
        },
        saveEmployee: function saveEmployee() {
            if (this.isFormValid()) {
                var employee = get(this, 'employee');
                set(employee, 'isEditing', false);

                set(this, 'employee', null);
                get(this, 'panes').goTo(1);
            }
        },
        rollbackEmployee: function rollbackEmployee() {
            var employee = get(this, 'employee');
            set(employee, 'isEditing', false);

            employee.rollbackAttributes();

            set(this, 'employee', null);
            get(this, 'panes').goTo(1);
        },
        removeEmployee: function removeEmployee(employee) {
            if (confirm('Naozaj chcete odstrániť túto kontaktnú osobu?')) {
                employee.deleteRecord();
            }
        }
    });
});