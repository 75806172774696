define('crm/controllers/user/leads/edit-analysis', ['exports', 'crm/controllers/user/leads/create-analysis'], function (exports, _createAnalysis) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _createAnalysis.default.extend({
        title: 'Upraviť vstupnú analýzu',

        actions: {
            initAnswers: function initAnswers() {
                var answers = this.get('answers');

                answers.forEach(function (answer) {
                    answer.set('question.answer', answer);
                });

                /*
                let answers = this.get('answers');
                 // Prepare answers
                this.get('questions').forEach((question) => {
                    let answer = answers.createRecord({
                        question: question
                    });
                     question.set('answer', answer);
                });
                */
            }
        }
    });
});