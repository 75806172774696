define('crm/serializers/notification', ['exports', 'crm/serializers/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        normalize: function normalize(typeClass, hash) {
            hash.payload = hash.data;

            return this._super.apply(this, arguments);
        }
    });
});