define('crm/controllers/user/production/add', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Vytvoriť dodací list',

        entities: Ember.inject.service(),
        clients: Ember.computed.alias('entities.clients'),

        addBranch: function addBranch(branch) {
            this.get('model.invoicedBranches').addObject(branch);
        },
        removeBranch: function removeBranch(branch) {
            this.get('model.invoicedBranches').removeObject(branch);
        },
        addItem: function addItem() {
            this.get('model.items').createRecord({
                'order': parseInt(this.get('model.items.length')) + 1
            });
        },
        removeItem: function removeItem(item) {
            item.destroyRecord();
        },
        saved: function saved() {
            var self = this;

            this.get('toasts').success('<b>Dodací list bol vytvorený.</b> Klikutím sem ho stiahnete.', {
                onClick: function onClick() {
                    self.send('openModal', 'user/download-document', self.get('model'), 'user/production/download');
                }
            });
        }
    });
});