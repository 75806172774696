define('crm/models/client', ['exports', 'ember-data', 'crm/models/base', 'crm/mixins/model/persist-relationships', 'moment'], function (exports, _emberData, _base, _persistRelationships, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = _base.default.extend(_persistRelationships.default, {
        name: attr('string'),
        logo: attr('string'),

        accountManager: belongsTo('user'),
        user: belongsTo('user'),
        invoicingCompany: belongsTo('invoicing-company'),
        contentEditor: belongsTo('user'),

        monthlyFee: attr('float'),
        penaltyFee: attr('float'),
        regularlyInvoicedItems: hasMany('regularly-invoiced-item', {
            persist: true
        }),
        noTax: attr('boolean', {
            defaultValue: false
        }),
        invoiceDueDays: attr('number', {
            defaultValue: 13
        }),
        deliveryNoteDueDays: attr('number', {
            defaultValue: 6
        }),

        companyId: attr('number'),
        companyTin: attr('number'),
        companyVat: attr('string'),

        billingCompany: attr('string'),
        billingAddress: attr('string'),
        billingCity: attr('string'),
        billingZip: attr('string'),

        useBillingAddress: attr('boolean', {
            defaultValue: true
        }),
        deliveryCompany: attr('string'),
        deliveryName: attr('string'),
        deliveryAddress: attr('string'),
        deliveryCity: attr('string'),
        deliveryZip: attr('string'),

        latitude: attr('float'),
        longitude: attr('float'),

        commitmentType: attr('number'),
        trialPeriod: attr('number'),
        noticePeriod: attr('number'),
        cooperationDuration: attr('number'),

        signatureDate: attr('date'),

        suspended: attr('boolean', {
            defaultValue: false
        }),
        canceled: attr('date'),
        reasonOfCancelation: attr('string'),
        gdprSigned: attr('boolean', {
            defaultValue: false
        }),
        credit: attr('number', {
            defaultValue: 0
        }),

        worklogGraphic: attr('number'),

        reminders: attr('boolean', {
            defaultValue: true
        }),
        reminderPay: attr('number', {
            defaultValue: 1
        }),
        reminderPayOverdue1: attr('number', {
            defaultValue: 1
        }),
        reminderPayOverdue2: attr('number', {
            defaultValue: 3
        }),
        reminderPayOverdue3: attr('number', {
            defaultValue: 5
        }),

        note: attr('string'),

        employees: hasMany('employee', {
            persist: true
        }),
        workLogs: hasMany('work-log'),
        commissions: hasMany('commission'),
        branches: hasMany('client-branch'),
        reports: hasMany('report'),
        deliveryNotes: hasMany('delivery-note'),
        invoices: hasMany('invoice'),
        files: hasMany('file'),
        lead: belongsTo('lead'),
        gdpr: belongsTo('gdpr'),
        reportSettings: belongsTo('report-settings'),
        creditLogs: hasMany('credit-log'),

        isCanceled: Ember.computed('canceled', function () {
            return this.get('canceled') !== null;
        }),
        isInvoicedMonthly: Ember.computed('signatureDate', function () {
            return !!this.get('signatureDate');
        }),
        invoicedBranches: Ember.computed('branches.@each.includeInMonthlyInvoice', function () {
            return this.get('branches').filterBy('includeInMonthlyInvoice', true);
        }),
        hasInvoice: Ember.computed('invoices.[]', function () {
            return this.get('invoices.length') > 0;
        }),

        canLogWorkLogs: Ember.computed('canceled', function () {
            var canceled = this.get('canceled');

            if (!canceled) {
                return true;
            }

            return (0, _moment.default)(this.get('canceled')).toDate() > new Date();
        }),
        canBeConvertedToLead: Ember.computed('lead.id', 'signed', function () {
            var lead = this.get('lead.id'),
                signed = this.get('signed');

            return !lead && !signed;
        }),

        reportable: Ember.computed('monthlyFee', 'canceled', function () {
            if (!this.get('monthlyFee')) {
                return false;
            }

            if (this.get('canceled')) {
                var now = (0, _moment.default)(new Date()),
                    start = now.clone().startOf('month'),
                    end = now.clone().endOf('month'),
                    canceled = (0, _moment.default)(this.get('canceled'));

                if (canceled > now) {
                    return true;
                } else {
                    return canceled.isBetween(start, end);
                }
            }

            return true;
        }),
        sortedReports: Ember.computed('reports.@each.sortId', function () {
            return this.get('reports').sortBy('sortId');
        }),

        signed: Ember.computed.notEmpty('signatureDate')
    });
});