define('crm/models/task', ['exports', 'ember-data', 'crm/models/base', 'crm/mixins/model/copyable', 'crm/mixins/model/persist-relationships', 'moment', 'crm/config/environment', 'crm/utils/dates'], function (exports, _emberData, _base, _copyable, _persistRelationships, _moment, _environment, _dates) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    var get = Ember.get,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        equal = _Ember$computed.equal,
        alias = _Ember$computed.alias,
        service = Ember.inject.service;
    exports.default = _base.default.extend(_copyable.default, _persistRelationships.default, {
        summary: attr('string'),
        description: attr('string', {
            defaultValue: ''
        }),
        dueDate: attr('datetime', {
            defaultValue: function defaultValue() {
                return (0, _dates.roundTo)((0, _moment.default)().add(1, 'hour'), 'minutes', 15);
            }
        }),
        startDate: attr('date'),
        status: attr('number', {
            defaultValue: 0
        }),
        state: alias('status'),
        createdAt: attr('datetime'),
        updatedAt: attr('datetime'),
        private: attr('boolean'),

        type: attr('number'),
        priority: attr('number', {
            defaultValue: 1
        }),
        parent_task: attr('number'),
        recurrence: attr('number'),
        recurrenceStart: attr('date', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)();
            }
        }),
        recurrenceTime: attr('', {
            defaultValue: function defaultValue() {
                return (0, _dates.roundTo)((0, _moment.default)().add(1, 'hour'), 'minutes', 15).format('HH:mm');
            }
        }),
        estimate: _emberData.default.attr('duration'),

        // Relationships
        assignee: belongsTo('user'),
        team: belongsTo('team'),
        client: belongsTo('client'),
        clients: hasMany('client'),
        user: belongsTo('user'),

        days: hasMany('task-day', {
            persist: true
        }),
        todos: hasMany('task-todo', {
            persist: true
        }),
        comments: hasMany('comment'),
        files: hasMany('file'),
        watchers: hasMany('user'),
        fields: hasMany('task-field', {
            persist: true
        }),

        textType: computed('type', function () {
            return _environment.default.taskTypes[get(this, 'type')];
        }),

        selectedDays: computed('days.@each', function () {
            return this.get('days').map(function (day) {
                return day.get('day');
            });
        }),

        isDue: computed('dueDate', 'isRecurrent', 'isDone', function () {
            if (this.get('isDone') || this.get('isInReview') || this.get('isRecurrent')) {
                return false;
            }

            var now = (0, _moment.default)(new Date()),
                dueDate = (0, _moment.default)(this.get('dueDate'));

            return dueDate.isSame(now, 'day');
        }),
        isOverdue: computed('dueDate', 'isDone', 'isInReview', 'isRecurrent', function () {
            if (this.get('isDone') || this.get('isInReview') || this.get('isRecurrent')) {
                return false;
            }

            var now = (0, _moment.default)(new Date()),
                dueDate = (0, _moment.default)(this.get('dueDate'));

            return now.diff(dueDate, 'seconds') > 0;
        }),

        dueDateClass: computed('isDue', 'isOverdue', function () {
            var isDue = get(this, 'isDue'),
                isOverdue = get(this, 'isOverdue');

            if (isOverdue) {
                return 'text-danger';
            }

            if (isDue) {
                return 'text-warning';
            }
        }),

        isOpen: equal('status', 0),
        isInProgress: equal('status', 1),
        isDone: equal('status', 2),
        isInReview: equal('status', 3),

        isRecurrent: computed('recurrence', function () {
            return this.get('recurrence');
        }),
        isRecurrentDaily: equal('recurrence', 1),
        isRecurrentWeekly: equal('recurrence', 2),
        isRecurrentMonthly: equal('recurrence', 3),

        textRecurrence: computed('recurrence', function () {
            switch (this.get('recurrence')) {
                case 1:
                    {
                        return 'Denne';
                    }

                case 2:
                    {
                        return 'Týždenne';
                    }

                case 3:
                    {
                        return 'Mesačne';
                    }

                case 4:
                    {
                        return 'Na konci mesiaca';
                    }
            }
        }),

        currentUser: service(),
        assignedTo: computed('assignee', 'currentUser.user', function () {
            var assignee = get(this, 'assignee'),
                user = get(this, 'currentUser.user');

            return assignee ? get(assignee, 'id') === get(user, 'id') : false;
        }),
        assignedToMyTeam: computed('team.id', 'currentUser.user.teamIds.[]', function () {
            var team = get(this, 'team'),
                teams = get(this, 'currentUser.user.teamIds');

            return teams.indexOf(get(team, 'id')) !== -1;
        }),

        isWatchedByMe: computed('watchers.@each.id', 'currentUser.user', function () {
            return get(this, 'watchers').includes(get(this, 'currentUser.user'));
        }),

        clientName: alias('client.name'),
        assigneeName: alias('assignee.name'),
        userName: alias('user.name'),
        teamName: alias('team.name'),
        teamId: alias('team.id'),

        // Grouping aliases
        assigneeGroupingValue: alias('assignee.id'),
        userGroupingValue: alias('user.id'),
        clientGroupingValue: alias('client.id'),
        teamGroupingValue: alias('team.id'),
        dueDateGroupingValue: computed('dueDate', function () {
            return (0, _moment.default)(get(this, 'dueDate')).format('L');
        })
    });
});