define('crm/components/navbar/navbar-nav', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'ul',
        classNames: ['navbar-nav', 'mr-auto']
    });
});