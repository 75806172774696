define('crm/helpers/lt', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.ge = ge;
    var helper = Ember.Helper.helper;
    function ge(params) {
        return params[0] < params[1];
    }

    exports.default = helper(ge);
});