define('crm/components/contract-generator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var SECTIONS = [{
        title: 'Úvodné ustanovenia',
        blocks: [{
            type: 'text',
            content: 'Zmluvné strany tejto Zmluvy, Klient na jednej strane a Agentúra na strane druhej, v súlade s ustanovením § 269 ods. 2 a nasl. zákona č. 513/1991 Zb. Obchodný zákonník v znení neskorších predpisov, uzatvárajú túto Zmluvu (ďalej len „Zmluva“).'
        }]
    }, {
        title: 'Predmet Zmluvy',
        blocks: [{
            type: 'text',
            content: 'Agentúra sa zaväzuje poskytovať služby uvedené v článku II bod 2.2 a bod 2.3 Klientovi priebežne počas platnosti a účinnosti Zmluvy podľa požiadaviek Klienta v prípade, že sa jedná o služby typu a rozsahu uvedenom v tomto článku Zmluvy (ak nie je ustanovené inak) a Klient sa zaväzuje uhrádzať za tieto služby odmenu riadne a včas.'
        }, {
            type: 'services',
            title: 'Služby poskytované bez limitu resp. podľa primeranej účelnej potreby'
        }, {
            type: 'services',
            title: 'Služby poskytované na základe individuálne limitovaného rozsahu'
        }, {
            type: 'text',
            content: 'Jednotlivé limity uvedené v článku II bodu 2.3 tejto Zmluvy platia vždy na uvedené obdobie, takže sa aj v prípade ich nevyužitia nekumulujú a neprenášajú do ďalších období.'
        }, {
            type: 'text',
            content: 'V prípade, že si plnenie Zmluvy bude vyžadovať prekročenie štandardných limitov, ktoré nie je pokryté v cene paušálu, má Agentúra povinnosť upozorniť na tento stav a následne vyjednať alternatívnu dohodu resp. objednávku služieb nad rámec, ktoré budú spoplatnené individuálne dohodnutou cenou služby.'
        }]
    }];

    exports.default = Ember.Component.extend({
        sections: SECTIONS
    });
});