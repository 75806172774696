define('crm/components/leads/lead-meeting', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        service = Ember.inject.service,
        alias = Ember.computed.alias;
    exports.default = Ember.Component.extend({
        isCreating: false,

        store: service(),
        meeting: alias('lead.meeting'),

        create: function create() {
            set(this, 'isCreating', true);

            get(this, 'store').createRecord('meeting', {
                lead: get(this, 'lead')
            });
        }
    });
});