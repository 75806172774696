define('crm/components/form-select', ['exports', 'crm/components/simple-form-select'], function (exports, _simpleFormSelect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed;
    exports.default = _simpleFormSelect.default.extend({
        primaryKey: 'sortId',
        labelProperty: 'name',
        castInteger: false,
        markDirty: null,

        selectedValue: computed('value', 'primaryKey', 'castInteger', function () {
            var value = get(this, 'value');

            if (get(this, 'castInteger')) {
                var primaryKey = get(this, 'primaryKey');

                return get(this, 'model').findBy(primaryKey, value);
            } else {
                return value;
            }
        }),

        actions: {
            select: function select(value) {
                value = parseInt(value);

                var primaryKey = get(this, 'primaryKey'),
                    castInteger = get(this, 'castInteger'),
                    model = get(this, 'model').findBy(primaryKey, value),
                    multiple = get(this, 'multiple');

                if (castInteger) {
                    model = get(model, primaryKey);
                }

                if (multiple) {
                    get(this, 'value').pushObject(model);
                } else {
                    set(this, 'value', model);
                }

                if (get(this, 'markDirty')) {
                    get(this, 'markDirty').send('becomeDirty');
                }

                get(this, 'onSelect')(get(this, 'value'));
                get(this, 'onChange')(get(this, 'value'));
            },
            unselect: function unselect(value) {
                var primaryKey = get(this, 'primaryKey'),
                    castInteger = get(this, 'castInteger'),
                    model = get(this, 'model').findBy(primaryKey, parseInt(value)),
                    multiple = get(this, 'multiple');

                if (castInteger) {
                    model = get(model, primaryKey);
                }

                if (multiple) {
                    get(this, 'value').removeObject(model);
                } else {
                    set(this, 'value', null);
                }

                if (get(this, 'markDirty')) {
                    get(this, 'markDirty').send('becomeDirty');
                }

                get(this, 'onDeselect')();
                get(this, 'onChange')(null);
            }
        }
    });
});