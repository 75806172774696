define('crm/components/modal-panes/pane', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        classNames: ['modal-pane'],

        isCurrent: computed('panes.{panes.@each,current}', function () {
            var panes = get(this, 'panes'),
                steps = get(panes, 'panes'),
                index = steps.indexOf(this),
                current = get(panes, 'current');

            return current === index + 1;
        }),

        offset: computed(function () {
            return this.element.offsetLeft;
        }),

        didInsertElement: function didInsertElement() {
            get(this, 'panes').addPane(this);
        },
        willDestroyElement: function willDestroyElement() {
            get(this, 'panes').removePane(this);
        }
    });
});