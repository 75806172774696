define('crm/utils/time', ['exports', 'crm/utils/numbers'], function (exports, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.humanTime = undefined;


    var humanTime = function humanTime(time) {
        time = (0, _numbers.toFloat)(time);

        if (time === 0) {
            return '0h';
        }

        var hours = parseInt(time),
            minutes = Math.round(parseFloat(time) % 1 * 60),
            output = [];

        if (hours) {
            output.push(hours + 'h');
        }
        if (minutes) {
            output.push(minutes + 'm');
        }

        return output.join(' ');
    };

    exports.humanTime = humanTime;
});