define('crm/components/team-name', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        htmlSafe = Ember.String.htmlSafe;
    exports.default = Ember.Component.extend({
        classNames: ['badge'],
        attributeBindings: ['style'],

        style: computed('team.color', function () {
            var color = get(this, 'team.color');

            if (color) {
                return htmlSafe('background: ' + color);
            }
        })
    });
});