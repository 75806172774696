define('crm/controllers/user/dashboard', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        leads: Ember.computed.alias('model.leads'),
        myLeads: Ember.computed.filter('leads', function (lead) {
            return lead.get('user.id') === this.get('currentUser.id');
        }),
        openLeads: Ember.computed.filterBy('myLeads', 'status', 1)
    });
});