define('crm/models/credit-note-item', ['exports', 'ember-data', 'crm/models/base', 'crm/utils/numbers'], function (exports, _emberData, _base, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        get = Ember.get;
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _base.default.extend({
        description: attr('string'),
        qty: attr('string'),
        price: attr('float'),
        note: attr('string'),
        order: attr('number'),
        creditNote: belongsTo('credit-note'),

        priceWithVat: computed('price', function () {
            return (0, _numbers.toFloat)(get(this, 'price') * 1.2);
        })
    });
});