define('crm/routes/user/attendance', ['exports', 'crm/mixins/routes/loading', 'moment'], function (exports, _loading, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        oneWay = Ember.computed.oneWay,
        hash = Ember.RSVP.hash;
    exports.default = Ember.Route.extend(_loading.default, {
        user: oneWay('currentUser.user'),

        month: (0, _moment.default)().month(),
        year: (0, _moment.default)().year(),

        model: function model() {
            var month = get(this, 'month'),
                year = get(this, 'year'),
                from = (0, _moment.default)([year, month, 1]).startOf('month'),
                to = from.clone().endOf('month');

            return hash({
                month: month,
                year: year,
                waitingForApproval: this.store.query('attendance', {
                    waitingForApproval: true
                }),
                overview: this.ajax.request('/attendance/monthlyOverview', {
                    data: {
                        user: get(this, 'user.id'),
                        month: month,
                        year: year
                    }
                }),
                attendance: this.store.query('attendance', {
                    filter: {
                        userId: get(this, 'user.id'),
                        dateFrom: from.format('YYYY-MM-DD'),
                        dateTo: to.format('YYYY-MM-DD')
                    }
                }),
                absents: this.store.query('attendance', {
                    absents: true,
                    month: month,
                    year: year
                })
            });
        },


        actions: {
            reloadAttendance: function reloadAttendance(data) {
                this.setProperties(data);

                this.refresh();
            }
        }
    });
});