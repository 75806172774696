define('crm/components/attendance-timeline', ['exports', 'moment', 'npm:moment-range'], function (exports, _moment, _npmMomentRange) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var moment = _npmMomentRange.default.extendMoment(_moment.default);

    var get = Ember.get,
        computed = Ember.computed;


    function isWeekend(date) {
        return date.weekday() === 5 || date.weekday() === 6;
    }

    exports.default = Ember.Component.extend({
        classNames: ['table-responsive'],

        month: moment().month(),
        year: moment().year(),

        onClick: function onClick() {},


        from: computed('month', 'year', function () {
            var _getProperties = this.getProperties('month', 'year'),
                month = _getProperties.month,
                year = _getProperties.year;

            return moment([year, month, 1]).startOf('month');
        }),
        to: computed('from', function () {
            var from = get(this, 'from');

            return from.clone().endOf('month');
        }),

        dates: computed('from', 'to', function () {
            var _getProperties2 = this.getProperties('from', 'to'),
                from = _getProperties2.from,
                to = _getProperties2.to;

            return Array.from(moment.range(from, to).by('day'));
        }),

        visibleUsers: computed('users.[]', 'currentUser.user.role.canViewAllAttendance', function () {
            var users = get(this, 'users'),
                teams = get(this.currentUser, 'user.teams').mapBy('id');

            if (!get(this.currentUser, 'user.role.canViewAllAttendance')) {
                users = users.filter(function (user) {
                    return get(get(user, 'teams').filter(function (team) {
                        return teams.includes(get(team, 'id'));
                    }), 'length') > 0;
                });
            }

            return users;
        }),

        timelines: computed('users.[]', 'absents.[]', 'dates.[]', function () {
            var _this = this;

            var _getProperties3 = this.getProperties('users', 'absents', 'dates'),
                users = _getProperties3.users,
                absents = _getProperties3.absents,
                dates = _getProperties3.dates;

            var timelines = {},
                timelineFrom = get(this, 'from'),
                timelineTo = get(this, 'to');

            users.forEach(function (user) {
                var id = get(user, 'id'),
                    timeline = dates.map(function (date) {
                    var dayClass = 'timeline-day';

                    if (isWeekend(date)) {
                        dayClass += ' timeline-day-weekend';
                    }

                    if (_this.calendar.isHoliday(date)) {
                        dayClass += ' timeline-day-holiday';
                    }

                    return {
                        date: date,
                        absence: null,
                        dayClass: dayClass
                    };
                });

                absents.filterBy('user.id', id).forEach(function (absence) {
                    var from = moment(get(absence, 'dateFrom')),
                        to = moment(get(absence, 'dateTo'));

                    if (from.isBefore(timelineFrom)) {
                        from = timelineFrom;
                    }

                    if (to.isAfter(timelineTo)) {
                        to = timelineTo;
                    }

                    var range = moment.range(from, to);

                    var _iteratorNormalCompletion = true;
                    var _didIteratorError = false;
                    var _iteratorError = undefined;

                    try {
                        for (var _iterator = range.by('day')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                            var day = _step.value;

                            var index = day.date() - 1;

                            timeline[index].absence = absence;

                            if (get(absence, 'isSingleDay')) {
                                if (get(absence, 'isAM')) {
                                    timeline[index].dayClass += ' timeline-day-am';
                                } else if (get(absence, 'isPM')) {
                                    timeline[index].dayClass += ' timeline-day-pm';
                                } else {
                                    timeline[index].dayClass += ' timeline-day-whole-day';
                                }
                            } else {
                                timeline[index].dayClass += ' timeline-day-whole-day';
                            }

                            if (day.isSame(from, 'day')) {
                                timeline[index].dayClass += ' timeline-day-start';
                            }

                            if (day.isSame(to, 'day')) {
                                timeline[index].dayClass += ' timeline-day-end';
                            }
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return) {
                                _iterator.return();
                            }
                        } finally {
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }
                });

                timelines[id] = timeline;
            });

            return timelines;
        }),

        openDetail: function openDetail(model) {
            return get(this, 'onClick')(model);
        }
    });
});