define('crm/components/date-picker', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      on = Ember.on,
      isArray = Ember.isArray;
  exports.default = Ember.Component.extend({
    tagName: 'span',
    toggleClass: 'btn-primary',
    targetElement: '.datepicker-calendar',

    onChange: function onChange() {},
    select: function select(formattedDate, date) {
      if (get(this, 'range')) {
        if (isArray(date)) {
          get(this, 'onChange')(date.map(function (date) {
            return (0, _moment.default)(date);
          }));
        }
      } else {
        get(this, 'onChange')((0, _moment.default)(date));
      }
    },
    didInsertElement: function didInsertElement() {
      console.log('didInsertElement');

      var calendar = this.calendar,
          targetElement = get(this, 'targetElement'),
          timepicker = getWithDefault(this, 'time', false),
          range = getWithDefault(this, 'range', false),
          startDate = getWithDefault(this, 'selected', null),
          hoursStep = getWithDefault(this, 'hoursStep', 1),
          minutesStep = getWithDefault(this, 'minutesStep', 1),
          position = getWithDefault(this, 'position', 'bottom left');

      var datepicker = this.$(targetElement).datepicker({
        range: range,
        timepicker: timepicker,
        timeFormat: 'hh:ii',
        hoursStep: hoursStep,
        minutesStep: minutesStep,
        autoClose: true,
        position: position,

        language: 'sk',
        multipleDatesSeparator: ' - ',
        offset: 2,
        prevHtml: '<i class="fa fa-chevron-left"></i>',
        nextHtml: '<i class="fa fa-chevron-right"></i>',

        onRenderCell: function onRenderCell(date, cellType) {
          var isHoliday = calendar.isHoliday(date),
              isWeekend = calendar.isWeekend(date),
              classes = '';

          if (cellType === 'day') {
            if (isWeekend) {
              classes += ' -weekend-';
            }

            if (isHoliday) {
              classes += ' -holiday-';
            }
          }

          return {
            classes: classes
          };
        }
      });

      if (startDate) {
        if (range) {
          datepicker.data('datepicker').selectDate(startDate.map(function (date) {
            return (0, _moment.default)(date).toDate();
          }));
        } else {
          datepicker.data('datepicker').selectDate((0, _moment.default)(startDate).toDate());
        }
      }

      datepicker.data('datepicker').update({
        onSelect: get(this, 'select').bind(this)
      });

      this.updateDatepicker();
    },


    updateDatepicker: on('didUpdateAttrs', function () {
      var datepicker = this.$(get(this, 'targetElement')).data('datepicker');

      var _getProperties = this.getProperties('minDate', 'maxDate', 'clearButton'),
          minDate = _getProperties.minDate,
          maxDate = _getProperties.maxDate,
          clearButton = _getProperties.clearButton;

      if (minDate) {
        datepicker.update('minDate', (0, _moment.default)(minDate).toDate());
      }

      if (maxDate) {
        datepicker.update('maxDate', (0, _moment.default)(maxDate).toDate());
      }

      datepicker.update('clearButton', clearButton);
    }),

    willDestroyElement: function willDestroyElement() {
      this.$(get(this, 'targetElement')).data('datepicker').destroy();
    }
  });
});