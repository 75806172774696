define('crm/routes/user/clients', ['exports', 'crm/mixins/routes/loading'], function (exports, _loading) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        model: function model() {
            return Ember.RSVP.hash({
                clients: this.store.findAll('client')
            });
        },


        actions: {
            // Actions
            enterEditDialog: function enterEditDialog(client) {
                this.send('openModal', 'user/clients/edit', client, 'user/clients/edit');
            },
            enterReportSettingsDialog: function enterReportSettingsDialog(client) {
                var _this = this;

                client.get('reportSettings').then(function (reportSettings) {
                    _this.send('openModal', 'user/clients/report-settings', reportSettings, 'user/clients/report-settings');
                });
            }
        }
    });
});