define('crm/models/commission', ['exports', 'ember-data', 'crm/models/base', 'crm/utils/numbers'], function (exports, _emberData, _base, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        user: _emberData.default.belongsTo('user'),
        client: _emberData.default.belongsTo('client'),
        monthlyFee: _emberData.default.attr('float'),
        period: _emberData.default.attr('number', {
            defaultValue: 0
        }),
        firstYear: _emberData.default.attr('float'),
        secondYear: _emberData.default.attr('float'),
        oneTimeCommission: _emberData.default.attr('float'),
        fixCommission: _emberData.default.attr('float'),

        computedMonthlyFee: Ember.computed('monthlyFee', 'client.monthlyFee', function () {
            return (0, _numbers.toFloat)(this.get('monthlyFee')) || this.get('client.monthlyFee');
        }),

        firstYearComputed: Ember.computed('firstYear', 'computedMonthlyFee', function () {
            return this.get('computedMonthlyFee') * ((0, _numbers.toFloat)(this.get('firstYear')) / 100);
        }),
        secondYearComputed: Ember.computed('secondYear', 'computedMonthlyFee', function () {
            return this.get('computedMonthlyFee') * ((0, _numbers.toFloat)(this.get('secondYear')) / 100);
        }),

        isOneTimeCommission: Ember.computed.equal('period', 0),
        isHalfYearCommission: Ember.computed.equal('period', 6),
        isOneYearCommission: Ember.computed.equal('period', 12),
        isTwoYearsCommission: Ember.computed.equal('period', 24)
    });
});