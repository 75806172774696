define('crm/components/data-grid', ['exports', 'crm/utils/grid', 'crm/macros/depends', 'ember-awesome-macros'], function (exports, _grid, _depends, _emberAwesomeMacros) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        map = _Ember$computed.map,
        alias = _Ember$computed.alias,
        A = Ember.A,
        isArray = Ember.isArray,
        w = Ember.String.w,
        observer = Ember.observer;
    exports.default = Ember.Component.extend({
        classNames: ['table-responsive'],

        offset: 0,
        limit: 20,

        groupBy: null,
        selection: null,

        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'filters', new A());
            set(this, 'sortBy', new A());

            this.initializeSorting();

            this.events.subscribe('data-grid:filter', this, 'updateFilter');
        },


        iteratorKey: computed('selection', function () {
            return get(this, 'selection') ? 'id' : '@index';
        }),

        dependentKeys: computed('sortBy.@each.column', 'filters.[]', function () {
            var keys = [];

            get(this, 'sortBy').forEach(function (sortingColumn) {
                return keys.push(get(sortingColumn, 'column'));
            });
            get(this, 'filters').forEach(function (filter) {
                return keys.push(get(filter, 'property'));
            });

            return keys.uniq();
        }),
        computedModel: (0, _depends.default)('model', 'dependentKeys'),

        sortingDefinition: map('sortBy.@each.{column,direction}', function (item) {
            return get(item, 'column') + ':' + get(item, 'direction');
        }),

        filteredModel: computed('computedModel.[]', 'filters.[]', function () {
            return this.filter(get(this, 'computedModel'));
        }),
        sortedModel: _emberAwesomeMacros.array.sort('filteredModel', 'sortingDefinition.[]'),
        paginatedModel: computed('sortedModel.[]', 'offset', 'limit', function () {
            return this.paginate(get(this, 'sortedModel'));
        }),
        groupedModel: _emberAwesomeMacros.array.groupBy('paginatedModel', 'groupBy'),

        rows: alias('paginatedModel'),
        groups: alias('groupedModel'),

        willDestroyElement: function willDestroyElement() {
            this.events.unsubscribe('data-grid:filter', this, 'updateFilter');
        },
        initializeSorting: function initializeSorting() {
            var _this = this;

            var columns = get(this, 'initialSorting');

            if (columns) {
                if (!isArray(columns)) {
                    columns = w(columns);
                }

                columns.forEach(function (columnDefinition) {
                    var _columnDefinition$spl = columnDefinition.split(':'),
                        _columnDefinition$spl2 = _slicedToArray(_columnDefinition$spl, 2),
                        column = _columnDefinition$spl2[0],
                        direction = _columnDefinition$spl2[1];

                    _this.sortMultiple(column, direction);
                });
            }
        },
        sort: function sort(column) {
            var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'asc';

            var sortBy = get(this, 'sortBy'),
                sorted = sortBy.findBy('column', column);

            if (sorted) {
                direction = sorted.direction === 'asc' ? 'desc' : 'asc';
            } else if (get(sortBy, 'length') > 0) {
                direction = get(sortBy, 'firstObject.direction');
            }

            sortBy.clear().addObject(Ember.Object.create({
                column: column,
                direction: direction
            }));
        },
        sortMultiple: function sortMultiple(column) {
            var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'asc';

            var sortBy = get(this, 'sortBy');

            if (get(sortBy, 'length') === 0) {
                return this.sort(column, direction);
            }

            var sorted = sortBy.findBy('column', column);

            if (sorted) {
                direction = sorted.direction === 'asc' ? 'desc' : 'asc';

                sorted.set('direction', direction);
            } else {
                sortBy.addObject(Ember.Object.create({
                    column: column,
                    direction: direction
                }));
            }
        },
        updateFilter: function updateFilter(filters) {
            this.setProperties({
                offset: 0,
                filters: filters
            });
        },
        filter: function filter(model) {
            var filters = get(this, 'filters');

            filters.forEach(function (filter) {
                var property = get(filter, 'property'),
                    operator = get(filter, 'operator'),
                    value = get(filter, 'value');

                model = model.filter(function (row) {
                    if (property.indexOf('[]') !== -1) {
                        var _property$split = property.split('.[].'),
                            _property$split2 = _slicedToArray(_property$split, 2),
                            collectionName = _property$split2[0],
                            collectionProperty = _property$split2[1];

                        return get(row, collectionName).filter(function (child) {
                            var rowValue = get(child, collectionProperty);

                            return (0, _grid.filterValue)(operator, value, rowValue);
                        }).get('length') > 0;
                    } else {
                        var rowValue = get(row, property);

                        return (0, _grid.filterValue)(operator, value, rowValue);
                    }
                });
            });

            return model;
        },
        paginate: function paginate(model) {
            var offset = get(this, 'offset'),
                limit = get(this, 'limit');

            if (limit) {
                return model.slice(offset, offset + limit);
            }

            return model;
        },


        scrollToTop: observer('offset', 'limit', function () {
            Ember.$(window).scrollTop(this.$().offset().top);
        })
    });
});