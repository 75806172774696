define('crm/components/data-filter/section', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    exports.default = Component.extend({
        classNames: ['filter-section'],
        classNameBindings: ['isOpen:open'],

        isOpen: false
    });
});