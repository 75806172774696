define('crm/models/invoice-reminder', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    var get = Ember.get,
        computed = Ember.computed,
        alias = Ember.computed.alias;


    var TYPE_MAP = {
        sms: 0,
        email: 1
    };

    exports.default = _base.default.extend({
        recipient: attr('string'),
        type: attr('string'),
        computedType: computed('type', function () {
            return TYPE_MAP[get(this, 'type')];
        }),
        order: attr('string'),
        date: attr('datetime'),
        body: attr('string'),

        invoice: belongsTo('invoice'),
        employee: belongsTo('employee'),
        client: Ember.computed.alias('invoice.client'),

        clientName: alias('client.name'),
        variableSymbol: alias('invoice.variableSymbol')
    });
});