define('crm/components/reports/report-summary', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        client: Ember.computed.alias('report.client'),
        summary: Ember.computed.alias('report.summary'),
        reportSettings: Ember.computed.alias('client.reportSettings')
    });
});