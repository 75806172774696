define('crm/controllers/user/roles/edit', ['exports', 'crm/controllers/user/roles/add'], function (exports, _add) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _add.default.extend({
        title: 'Upraviť skupinu',

        saved: function saved() {
            this.get('toasts').success('Skupina bola uložená.');
        }
    });
});