define('crm/routes/user', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'crm/config/environment'], function (exports, _authenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    modalManager: service(),
    session: service(),

    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        get(_this, 'session').authorize('authorizer:oauth2', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;

          _this.ajax.request('/user', { headers: headers }).then(function (user) {
            return Ember.RSVP.hash({
              user: _this.store.find('user', user.id),
              users: _this.store.query('user', {
                onlyEmployees: true
              }),
              teams: _this.store.findAll('team'),
              clients: _this.store.query('client', {
                onlyReportable: true
              }),
              attendance: _this.store.query('attendance', {
                filter: {
                  userId: user.id
                }
              }),
              contractConditions: _this.store.findAll('contract-condition'),
              holidays: _this.store.findAll('holiday'),
              invoicingCompanies: _this.store.findAll('invoicing-company'),
              invoiceTypes: _this.store.findAll('invoice-type'),
              fields: _this.store.findAll('field'),
              workLogCategories: _this.store.findAll('work-log-category')
            }).then(resolve, reject);
          });
        });
      });
    },
    afterModel: function afterModel(model) {
      set(this, 'currentUser.user', model.user);

      //this.connectToPusher();
      this.setRavenUserContext(model.user);
    },
    setRavenUserContext: function setRavenUserContext(user) {
      this.raven.callRaven('setUserContext', {
        id: get(user, 'id'),
        name: get(user, 'name'),
        email: get(user, 'email')
      });
    },
    connectToPusher: function connectToPusher() {
      var _this2 = this;

      get(this, 'session').authorize('authorizer:oauth2', function (headerName, headerValue) {
        var headers = {};

        headers[headerName] = headerValue;

        get(_this2, 'pusher').setup(_environment.default.pusher.key, {
          cluster: 'eu',
          encrypted: true,
          authEndpoint: _environment.default.api.host + '/broadcasting/auth',
          auth: {
            headers: headers
          }
        });
      });
    },


    /**
     * Common actions across the whole system
     */
    actions: {
      openAttendanceDialog: function openAttendanceDialog() {
        this.send('openModal', 'user/attendance/manage', null, 'user/attendance/manage');
      },
      openProfileDialog: function openProfileDialog() {
        this.send('openModal', 'user/profile/edit', null, 'user/profile/edit');
      },


      // Tasks
      createTaskDialog: function createTaskDialog() {
        this.send('openModal', 'user/tasks/edit', this.store.createRecord('task', {
          user: this.get('user')
        }), 'user/tasks/add');
      },


      // Work logs
      addWorkLogDialog: function addWorkLogDialog() {
        this.send('openModal', 'user/work-log/add', [], 'user/work-log/add');
      },


      // Clients
      addClientDialog: function addClientDialog() {
        this.send('openModal', 'user/clients/add', this.store.createRecord('client'), 'user/clients/add');
      },


      // Invoices
      createInvoiceDialog: function createInvoiceDialog() {
        var invoiceType = get(this, 'entities.invoiceTypes').findBy('default', true),
            invoice = this.store.createRecord('invoice', {
          invoiceType: invoiceType
        });

        this.send('openModal', 'user/invoices/edit', invoice, 'user/invoices/create');
      },
      editInvoiceDialog: function editInvoiceDialog(model) {
        this.send('openModal', 'user/invoices/edit', model, 'user/invoices/edit');
      },
      downloadInvoiceDialog: function downloadInvoiceDialog(model) {
        this.send('openModal', 'user/download-document', model, 'user/invoices/download');
      },
      sendInvoiceDialog: function sendInvoiceDialog(model) {
        this.send('openModal', 'user/invoices/send', model, 'user/invoices/send');
      },


      // Delivery notes
      createDeliveryNoteDialog: function createDeliveryNoteDialog() {
        this.send('openModal', 'user/production/edit', this.store.createRecord('delivery-note'), 'user/production/add');
      },


      // Credit notes
      downloadCreditNoteDialog: function downloadCreditNoteDialog(creditNote) {
        this.send('openModal', 'user/download-document', creditNote, 'user/credit-notes/download');
      },


      // Reports
      downloadReportDialog: function downloadReportDialog(model) {
        this.send('openModal', 'user/download-document', model, 'user/reports/download');
      },


      // Leads
      createLeadDialog: function createLeadDialog() {
        var lead = this.store.createRecord('lead', {
          user: get(this, 'currentUser.user')
        });

        this.send('openModal', 'user/leads/create', lead, 'user/leads/create');
      },
      openLeadDetail: function openLeadDetail(lead) {
        this.send('openModal', 'user/leads/detail', lead, 'user/leads/detail');
      },


      // Attendance
      addAttendanceDialog: function addAttendanceDialog() {
        var model = get(this, 'currentUser.user.attendance').createRecord({
          manually: true
        });

        this.send('openModal', 'user/attendance/edit', model, 'user/attendance/add');
      }
    }
  });
});