define('crm/components/data-filter/section/header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;
    exports.default = Ember.Component.extend({
        tagName: 'h6',
        classNames: ['filter-section-header'],

        onClick: function onClick() {},
        click: function click() {
            get(this, 'onClick')();
        }
    });
});