define('crm/helpers/time', ['exports', 'crm/utils/time'], function (exports, _time) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.time = time;
    var helper = Ember.Helper.helper;
    function time(params) {
        return (0, _time.humanTime)(params[0]);
    }

    exports.default = helper(time);
});