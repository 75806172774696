define('crm/components/has-many-control', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var set = Ember.set;
    exports.default = Ember.Component.extend({
        minItems: 0,

        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'selection', null);
        },
        addItem: function addItem() {},
        removeItem: function removeItem() {},
        select: function select(selection) {
            set(this, 'selection', selection);

            this.addItem(selection);
            this.clearSelection();
        },
        clearSelection: function clearSelection() {
            set(this, 'selection', null);
        }
    });
});