define('crm/components/color-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.TextField.extend({
        tagName: 'input',
        classNames: ['form-control'],

        didInsertElement: function didInsertElement() {
            this.$().minicolors({
                theme: 'bootstrap'
            });
        }
    });
});