define('crm/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var JSONSerializer = _emberData.default.JSONSerializer;
    var get = Ember.get,
        service = Ember.inject.service,
        underscore = Ember.String.underscore;
    exports.default = JSONSerializer.extend({
        currentUser: service(),
        includeUser: false,

        serialize: function serialize(snapshot) {
            var json = this._super.apply(this, arguments);

            // Creating a new record
            if (get(this, 'includeUser') && !snapshot.id) {
                json.user_id = get(this, 'currentUser.user.id');
            } else if (get(this, 'includeUser') && snapshot.id) {
                delete json.user_id;
            }

            return json;
        },


        keyForAttribute: function keyForAttribute(attr) {
            return underscore(attr);
        },

        keyForRelationship: function keyForRelationship(key, relationship) {
            if (relationship === 'belongsTo') {
                return underscore(key) + '_id';
            } else {
                return underscore(key);
            }
        }
    });
});