define('crm/services/ajax', ['exports', 'ember-ajax/services/ajax', 'crm/config/environment'], function (exports, _ajax, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var _get = Ember.get,
        computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = _ajax.default.extend({
        session: service(),
        host: _environment.default.api.host,
        namespace: 'api',
        trustedHosts: [_environment.default.api.host],

        headers: computed('session.data.authenticated.access_token', {
            get: function get() {
                var headers = {};

                _get(this, 'session').authorize('authorizer:oauth2', function (headerName, headerValue) {
                    headers[headerName] = headerValue;
                });

                return headers;
            }
        }).volatile()
    });
});