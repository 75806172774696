define('crm/controllers/user/statistics', ['exports', 'moment', 'ember-awesome-macros'], function (exports, _moment, _emberAwesomeMacros) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DATE_FORMAT = 'M-Y';

    exports.default = Ember.Controller.extend({
        queryParams: ['month'],

        month: (0, _moment.default)().format(DATE_FORMAT),
        title: Ember.computed('month', function () {
            var month = (0, _moment.default)(this.get('month'), DATE_FORMAT);

            return Ember.String.htmlSafe('\u0160tatistiky <small>(' + month.format('MM.YYYY') + ')</small>');
        }),

        previousMonth: Ember.computed('month', function () {
            var month = this.get('month');

            return (0, _moment.default)(month, DATE_FORMAT).subtract(1, 'month');
        }),
        nextMonth: Ember.computed('month', function () {
            var month = this.get('month');

            return (0, _moment.default)(month, DATE_FORMAT).add(1, 'month');
        }),

        clients: Ember.computed.alias('model.statistics.clients'),
        users: Ember.computed.alias('model.statistics.users'),
        issuedInvoices: Ember.computed.alias('model.statistics.issuedInvoices'),
        invoices: Ember.computed.alias('model.invoices'),

        groupInvoicesBy: 'state',
        invoicesByState: _emberAwesomeMacros.array.groupBy('invoices', 'groupInvoicesBy'),

        /*
        issuedInvoicesChartData: Ember.computed('issuedInvoices', function () {
            let issuedInvoices = this.get('issuedInvoices'),
                chartData = {
                    labels: [],
                    datasets: []
                };
             Object.keys(issuedInvoices).forEach(function(key) {
                chartData.labels.addObjects(Object.keys(issuedInvoices[key]));
                 chartData.datasets.push({
                    label: key,
                    data: Object.values(issuedInvoices[key])
                });
            });
             return chartData;
        }),
        chartOptions: {
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    barThickness: 16,
                    fontSize: 14,
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        padding: 10,
                        beginAtZero: true,
                        fontStyle: 'bold',
                        callback: function(value) {
                            return value + ' €';
                        }
                    },
                    gridLines: {
                        color: '#E0E0E0'
                    }
                }]
            }
        },
        */

        goToDate: function goToDate(date) {
            this.set('month', date.format(DATE_FORMAT));
        }
    });
});