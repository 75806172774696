define('crm/controllers/user/roles/add', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Pridať skupinu',

        clientsVisibility: {
            1: 'Obchodná skupina',
            2: 'Bez obmedzení'
        },
        attendanceVisibility: {
            1: 'Len moja dochádzka',
            2: 'Dochádzka môjho tímu',
            3: 'Bez obmedzení'
        },
        reportsVisibility: {
            1: 'Len môj report',
            2: 'Reporty mojich tímov',
            3: 'Bez obmedzení'
        },
        clientProfileAccess: {
            1: 'Read-only právo',
            2: 'Write právo'
        },

        saved: function saved() {
            this.get('toasts').success('Skupina bola vytvorená.');
        }
    });
});