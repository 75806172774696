define('crm/helpers/inc', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.inc = inc;
    function inc(params) {
        return params[0] + 1;
    }

    exports.default = Ember.Helper.helper(inc);
});