define('crm/controllers/user/work-log/edit', ['exports', 'crm/mixins/controller/modal-controller', 'crm/config/environment', 'moment'], function (exports, _modalController, _environment, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Upraviť záznam o činnosti',

        user: Ember.computed.alias('currentUser.user'),
        entities: Ember.inject.service(),
        clients: Ember.computed.alias('entities.contractedClients'),
        types: _environment.default.taskTypes,

        minDate: Ember.computed(function () {
            if (this.get('user.isSuperadmin')) {
                return null;
            } else {
                return (0, _moment.default)().add(-1, 'days');
            }
        })
    });
});