define('crm/models/base', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model;
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed,
        service = Ember.inject.service,
        String = Ember.String,
        guidFor = Ember.guidFor;
    exports.default = Model.extend({
        currentUser: service(),
        ownerProperty: 'user.id',

        sortId: computed('id', function () {
            return parseInt(get(this, 'id')) || guidFor(this);
        }),

        isMine: computed('currentUser.user', 'ownerProperty', function () {
            var ownerProperty = get(this, 'ownerProperty');

            return get(this, 'currentUser.user.id') === get(this, ownerProperty);
        }),

        setPropertiesFromApi: function setPropertiesFromApi(data) {
            var _this = this;

            var keys = Object.keys(data);

            keys.map(function (key) {
                var property = String.camelize(key),
                    value = data[key];

                set(_this, property, value);
            });
        }
    });
});