define('crm/controllers/user/leads/create-analysis', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Vytvoriť vstupnú analýzu',

        entities: Ember.inject.service(),
        questions: Ember.computed.alias('entities.questions'),
        answers: Ember.computed.alias('model.answers'),

        actions: {
            initAnswers: function initAnswers() {
                var answers = this.get('answers');

                // Prepare answers
                this.get('questions').forEach(function (question) {
                    var answer = answers.createRecord({
                        question: question
                    });

                    question.set('answer', answer);
                });
            }
        }
    });
});