define('crm/utils/sorting', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var normalizeSortProperties = function normalizeSortProperties(sortProperties) {
        return sortProperties.reject(function (p) {
            return p === null;
        }).map(function (p) {
            var _p$split = p.split(':'),
                _p$split2 = _slicedToArray(_p$split, 2),
                prop = _p$split2[0],
                direction = _p$split2[1];

            direction = direction || 'asc';

            return [prop, direction];
        });
    };

    exports.normalizeSortProperties = normalizeSortProperties;
});