define('crm/components/leads-pipeline/column/options', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        oneWay = Ember.computed.oneWay,
        _Ember$inject = Ember.inject,
        controller = _Ember$inject.controller,
        service = _Ember$inject.service;
    exports.default = Ember.Component.extend({
        entities: service(),

        panes: null,
        optionsPane: false,

        states: _environment.default.leadStates,
        filterOptions: {
            'meetingFilterByValue': 'Termínu stretnutia',
            'state': 'Stavu prípadu',
            'userGroupingValue': 'Obchodného zástupcu',
            'salesUnitGroupingValue': 'Obchodnej skupiny'
        },
        meetingOptions: {
            0: 'Dnes',
            1: 'Naplánované'
        },

        groupProperty: oneWay('group.property'),
        groupValue: oneWay('group.value'),

        setProperty: function setProperty(property) {
            set(this, 'groupProperty', property);
            set(this, 'groupValue', null);
        },
        save: function save() {
            set(this, 'group.property', get(this, 'groupProperty'));
            set(this, 'group.value', get(this, 'groupValue'));

            this.events.trigger('data-pipeline:save-column-options');

            get(this, 'onSave')();
        }
    });
});