define('crm/controllers/user/profile', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service,
        alias = Ember.computed.alias;
    exports.default = Ember.Controller.extend({
        session: service(),

        user: alias('currentUser.user'),

        actions: {
            enterEditDialog: function enterEditDialog() {
                this.send('openModal', 'user/profile/edit', null, 'user/profile/edit');
            },
            logout: function logout() {
                if (window.confirm('Naozaj sa chcete odhlásiť?')) {
                    this.get('session').invalidate();
                }
            }
        }
    });
});