define('crm/helpers/not-null', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.notNull = notNull;
    function notNull(params) {
        return params[0] !== null;
    }

    exports.default = Ember.Helper.helper(notNull);
});