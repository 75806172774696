define('crm/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        service = Ember.inject.service;
    exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
        session: service(),
        modalManager: service(),

        actions: {
            logout: function logout() {
                get(this, 'session').invalidate();
            },
            openModal: function openModal(name, model, controller) {
                this.render(name, {
                    into: 'application',
                    outlet: 'modal',
                    model: model,
                    controller: controller
                });
            },
            closeModal: function closeModal() {
                this.render('components/modal-placeholder', {
                    outlet: 'modal',
                    into: 'application',
                    controller: 'application'
                });
            }
        }
    });
});