define('crm/controllers/user/lead-analyses', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),
        users: Ember.computed.alias('entities.users'),
        clients: Ember.computed.alias('entities.clients'),

        statuses: {
            1: 'Čaká na spracovanie',
            2: 'Spracovaná'
        },

        actions: {
            enterFormDesigner: function enterFormDesigner() {
                this.send('openModal', 'user/lead-analyses/form-designer', this.get('model.questions'), 'user/lead-analyses/form-designer');
            },
            enterViewDialog: function enterViewDialog(analysis) {
                this.send('openModal', 'user/leads/analysis', analysis, 'user/leads/analysis');
            },
            enterEditDialog: function enterEditDialog(analysis) {
                this.send('openModal', 'user/leads/edit-analysis', analysis, 'user/leads/edit-analysis');
            }
        }
    });
});