define('crm/controllers/user', ['exports', 'ember-pusher'], function (exports, _emberPusher) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_emberPusher.default.Bindings, {
        notifications: Ember.inject.service(),

        isLoading: false

        /*
        pusher: Ember.inject.service(),
         init() {
            this._super(...arguments);
             //let channelName = 'private-App.Models.User.' + this.currentUser.get('user.id');
             //this.get('pusher').wire(this, channelName, ['Illuminate\\Notifications\\Events\\BroadcastNotificationCreated']);
        },
         actions: {
            'illuminate\\Notifications\\Events\\BroadcastNotificationCreated'(data) {
                this.get('notifications').processPusherNotification(data);
            }
        }
        */
    });
});