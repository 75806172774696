define('crm/components/dropdown-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['dropdown'],
        classNameBindings: ['block:dropdown-block'],

        block: false
    });
});