define('crm/utils/numbers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var round = function round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + ('e-' + decimals));
    };

    var toFloat = function toFloat(value) {
        return value ? round(parseFloat(value.toString().replace(/,/, '.')), 2) : value;
    };

    exports.round = round;
    exports.toFloat = toFloat;
});