define('crm/mixins/component/has-access', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Mixin = Ember.Mixin,
        computed = Ember.computed,
        service = Ember.inject.service,
        get = Ember.get;
    exports.default = Mixin.create({
        currentUser: service(),

        isVisible: computed('accessTo', function () {
            return get(this, 'currentUser').hasAccessTo(get(this, 'accessTo'));
        })
    });
});