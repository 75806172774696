define('crm/helpers/tasks-column-title', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var get = Ember.get,
        service = Ember.inject.service;
    exports.default = Ember.Helper.extend({
        entities: service(),

        compute: function compute(_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                property = _ref2[0],
                value = _ref2[1];

            switch (property) {
                case 'state':
                    {
                        return _environment.default.taskStatuses[parseInt(value)];
                    }

                case 'userGroupingValue':
                case 'assigneeGroupingValue':
                    {
                        var user = get(this, 'entities.users').findBy('id', value);

                        return user ? get(user, 'name') : '-';
                    }

                case 'teamGroupingValue':
                    {
                        var team = get(this, 'entities.teams').findBy('id', value);

                        return team ? get(team, 'name') : '-';
                    }

                case 'clientGroupingValue':
                    {
                        var client = get(this, 'entities.clients').findBy('id', value);

                        return client ? get(client, 'name') : null;
                    }

                case 'recurrence':
                    {
                        return _environment.default.taskRecurrence[value];
                    }

                default:
                    {
                        return value;
                    }
            }
        }
    });
});