define('crm/serializers/client', ['exports', 'crm/serializers/application', 'ember-data', 'crm/mixins/serializers/files-serializer'], function (exports, _application, _emberData, _filesSerializer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
    exports.default = _application.default.extend(EmbeddedRecordsMixin, _filesSerializer.default, {
        attrs: {
            employees: { embedded: 'always' },
            commissions: { embedded: 'always' },
            branches: { embedded: 'always' },
            regularlyInvoicedItems: { embedded: 'always' },
            lead: { embedded: 'always' },
            gdpr: { embedded: 'always' },
            reportSettings: { embedded: 'always' },
            creditLogs: { embedded: 'always' }
        }
    });
});