define('crm/models/attendance', ['exports', 'ember-data', 'crm/models/base', 'crm/config/environment', 'moment'], function (exports, _emberData, _base, _environment, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        _set = Ember.set,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        equal = _Ember$computed.equal,
        none = _Ember$computed.none;
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _base.default.extend({
        user: belongsTo('user'),
        client: belongsTo('client'),
        type: attr('number'),
        dateFrom: attr('datetime'),
        dateTo: attr('datetime'),
        duration: attr('number'),
        createdAt: attr('datetime'),
        comment: attr('string', {
            defaultValue: ''
        }),
        manually: attr('boolean'),
        approved: attr('boolean', {
            allowNull: true
        }),
        dateOfApproval: attr('datetime'),
        approvedBy: belongsTo('user'),

        timeFrom: computed('dateFrom', function () {
            var date = get(this, 'dateFrom');

            return date ? (0, _moment.default)(date).format('HH:mm') : null;
        }),
        timeTo: computed('dateTo', function () {
            var date = get(this, 'dateTo');

            return date ? (0, _moment.default)(date).format('HH:mm') : null;
        }),

        dateRange: computed({
            get: function get() {},
            set: function set(key, value) {
                var dateParts = value.split(' - ');

                if (dateParts.length > 0) {
                    var from = (0, _moment.default)(dateParts[0]),
                        to = dateParts[1] ? (0, _moment.default)(dateParts[1]) : from.clone();

                    _set(this, 'dateFrom', from);
                    _set(this, 'dateTo', to);
                }
            }
        }),
        absencePeriod: computed({
            get: function get() {},
            set: function set(key, value) {
                var from = (0, _moment.default)(get(this, 'dateFrom')).minutes(0).seconds(0),
                    to = (0, _moment.default)(get(this, 'dateTo')).minutes(0).seconds(0);

                if (value === 1) {
                    from.hours(8);
                    to.hours(16);
                } else if (value === 2) {
                    from.hours(8);
                    to.hours(12);
                } else {
                    from.hours(12);
                    to.hours(16);
                }

                _set(this, 'dateFrom', from);
                _set(this, 'dateTo', to);
            }
        }),

        hours: computed('duration', function () {
            return get(this, 'duration') / 60;
        }),

        textType: computed('type', function () {
            var type = get(this, 'type');

            return _environment.default.attendanceTypes[type];
        }),

        isAbsence: computed('type', function () {
            return _environment.default.attendanceAbsents.indexOf(get(this, 'type')) !== -1;
        }),
        isPlannedAbsence: computed('isAbsence', 'isPast', function () {
            return get(this, 'isAbsence') && !get(this, 'isPast');
        }),
        isPast: computed('isOpen', 'dateTo', function () {
            if (get(this, 'isOpen')) {
                return false;
            }

            return (0, _moment.default)(new Date()).diff((0, _moment.default)(get(this, 'dateTo')), 'days') > 0;
        }),
        isToday: computed('dateFrom', function () {
            var date = get(this, 'dateFrom');

            return (0, _moment.default)().isSame(date, 'day');
        }),
        isYesterday: computed('dateFrom', function () {
            var date = get(this, 'dateFrom');

            return (0, _moment.default)().add(-1, 'day').isSame(date, 'day');
        }),
        isWaitingForApproval: none('approved'),
        isApproved: computed('isWaitingForApproval', 'approved', function () {
            return !get(this, 'isWaitingForApproval') && get(this, 'approved');
        }),
        isSingleDay: computed('dateFrom', 'dateTo', function () {
            var from = get(this, 'dateFrom'),
                to = get(this, 'dateTo');

            if (from && !to) {
                return true;
            } else if (from && to) {
                return (0, _moment.default)(from).isSame((0, _moment.default)(to), 'day');
            } else {
                return false;
            }
        }),

        isAM: computed('timeFrom', 'timeTo', function () {
            var from = get(this, 'timeFrom'),
                to = get(this, 'timeTo');

            return from && to ? (0, _moment.default)(from, 'HH:mm').hours() <= 12 && (0, _moment.default)(to, 'HH:mm').hours() <= 12 : false;
        }),
        isPM: computed('timeFrom', 'timeTo', function () {
            var from = get(this, 'timeFrom'),
                to = get(this, 'timeTo');

            return from && to ? (0, _moment.default)(from, 'HH:mm').hours() >= 12 && (0, _moment.default)(to, 'HH:mm').hours() >= 12 : false;
        }),
        isWholeDay: computed('timeFrom', 'timeTo', function () {
            var from = get(this, 'timeFrom'),
                to = get(this, 'timeTo');

            return from && to ? (0, _moment.default)(from, 'HH:mm').hours() < 12 && (0, _moment.default)(to, 'HH:mm').hours() > 12 : false;
        }),

        isOpen: none('dateTo'),
        isWork: equal('type', 1),
        isPause: equal('type', 2),

        dateGroupingValue: computed('dateFrom', function () {
            var date = get(this, 'dateFrom');

            return (0, _moment.default)(date).format('L');
        }),

        rowClass: computed('isAbsence', 'isWaitingForApproval', 'isApproved', function () {
            return get(this, 'isAbsence') && !this.get('isWaitingForApproval') && !get(this, 'isApproved') ? 'table-danger' : '';
        })
    });
});