define('crm/helpers/has-access', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Helper = Ember.Helper,
        get = Ember.get,
        service = Ember.inject.service;
    exports.default = Helper.extend({
        currentUser: service(),

        compute: function compute(params) {
            return get(this, 'currentUser').hasAccessTo(params);
        }
    });
});