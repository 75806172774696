define('crm/controllers/user/leads/analysis', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Vstupná analýza',

        entities: Ember.inject.service(),
        questions: Ember.computed.alias('entities.questions'),
        answers: Ember.computed.alias('model.answers'),

        client: Ember.computed.alias('model.lead.client'),

        actions: {
            enterEditDialog: function enterEditDialog() {
                this.send('closeModal');

                Ember.run.later(this, function () {
                    this.send('openModal', 'user/leads/edit-analysis', this.get('model'), 'user/leads/edit-analysis');
                }, 500);
            },
            processAnalysis: function processAnalysis() {
                var _this = this;

                var analysis = this.get('model');
                analysis.set('processed', true);

                analysis.save().then(function () {
                    _this.send('closeModal');
                });
            }
        }
    });
});