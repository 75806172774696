define('crm/transforms/date', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var Transform = _emberData.default.Transform;
    exports.default = Transform.extend({
        deserialize: function deserialize(serialized) {
            var type = typeof serialized === 'undefined' ? 'undefined' : _typeof(serialized);

            if (type === "string") {
                return (0, _moment.default)(serialized).format('YYYY-MM-DD');
            } else if (serialized === null || serialized === undefined) {
                return serialized;
            } else {
                return null;
            }
        },
        serialize: function serialize(deserialized) {
            if (deserialized) {
                return (0, _moment.default)(deserialized).format('YYYY-MM-DD HH:mm:ss');
            } else {
                return null;
            }
        }
    });
});