define('crm/utils/grid', ['exports', 'moment', 'crm/utils/numbers'], function (exports, _moment, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.filterValue = undefined;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var isArray = Ember.isArray;


    var filterValue = function filterValue(operator, value, rowValue) {
        switch (operator) {
            case 'like':
                {
                    var escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

                    return new RegExp(escapedValue, 'i').test(rowValue);
                }

            case 'bool':
                {
                    return value === rowValue;
                }

            case 'eq':
                {
                    return (0, _numbers.toFloat)(value) === (0, _numbers.toFloat)(rowValue);
                }

            case 'lt':
                {
                    return (0, _numbers.toFloat)(value) >= (0, _numbers.toFloat)(rowValue);
                }

            case 'gt':
                {
                    return (0, _numbers.toFloat)(value) <= (0, _numbers.toFloat)(rowValue);
                }

            case 'date':
                {
                    var dateParts = value.split(' - ');

                    if (dateParts.length > 0) {
                        var from = (0, _moment.default)(dateParts[0]),
                            to = dateParts[1] ? (0, _moment.default)(dateParts[1]) : from.clone().add(1, 'day'),
                            date = (0, _moment.default)(rowValue);

                        return rowValue !== undefined && rowValue !== null && date.isBetween(from, to, null, '[]');
                    } else {
                        return true;
                    }
                }

            case 'in':
                {
                    if (isArray(rowValue)) {
                        return rowValue.filter(function (singleRowValue) {
                            return value.indexOf(singleRowValue) !== -1;
                        }).length > 0;
                    } else {
                        return value.indexOf(rowValue) !== -1;
                    }
                }

            case 'range':
                {
                    var _value = _slicedToArray(value, 2),
                        _from = _value[0],
                        _to = _value[1];

                    return rowValue >= _from && rowValue <= _to;
                }
        }
    };

    exports.filterValue = filterValue;
});