define('crm/components/data-pipeline', ['exports', 'ember-awesome-macros', 'crm/macros/group-by', 'crm/utils/arrays', 'crm/utils/grid'], function (exports, _emberAwesomeMacros, _groupBy, _arrays, _grid) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var get = Ember.get,
        set = Ember.set,
        A = Ember.A,
        Component = Ember.Component,
        computed = Ember.computed,
        $ = Ember.$;
    exports.default = Component.extend({
        classNames: ['pipeline'],

        groupBy: null,
        sortColumnsBy: null,
        sortBy: null,
        initialSorting: null,
        groups: [],
        sortable: false,

        perfectScrollbar: computed(function () {
            return this.$().find('.ps').attr('id');
        }),

        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'filters', new A());

            this.events.subscribe('data-grid:filter', this, 'updateFilter');
        },
        didUpdateAttrs: function didUpdateAttrs() {
            $('#' + get(this, 'perfectScrollbar')).scrollLeft(0);
        },
        didRender: function didRender() {
            window.Ps.update($('#' + get(this, 'perfectScrollbar'))[0]);
        },
        willDestroyElement: function willDestroyElement() {
            this.events.unsubscribe('data-grid:filter');
        },


        sortingDefinition: computed('sortColumnsBy.[]', function () {
            return get(this, 'sortColumnsBy') ? [get(this, 'sortColumnsBy')] : [];
        }),
        groupByDefinition: computed('groupBy', 'groups.@each.{key,value}', function () {
            var groupBy = get(this, 'groupBy'),
                groups = get(this, 'groups');

            return groupBy || groups;
        }),

        sortedModel: _emberAwesomeMacros.array.sort('model.[]', 'sortingDefinition.[]'),
        filteredModel: computed('sortedModel.[]', 'filters.[]', function () {
            return this.filter(get(this, 'sortedModel'));
        }),
        columns: (0, _groupBy.default)('filteredModel', 'groupByDefinition'),

        addColumn: function addColumn(after) {
            var groups = get(this, 'groups');

            var group = _arrays.Group.create({
                property: null,
                value: null,
                saved: false
            });

            groups.insertAt(after + 1, group);

            this.events.trigger('data-pipeline:add-column');
        },
        removeColumn: function removeColumn(index) {
            if (confirm('Naozaj chcete odstrániť tento stĺpec?')) {
                var groups = get(this, 'groups');

                groups.removeAt(index);

                this.events.trigger('data-pipeline:remove-column');
            }
        },
        reorderColumns: function reorderColumns(columns) {
            this.events.trigger('data-pipeline:reorder-columns', columns);
        },
        filter: function filter(model) {
            var filters = get(this, 'filters');

            filters.forEach(function (filter) {
                var property = get(filter, 'property'),
                    operator = get(filter, 'operator'),
                    value = get(filter, 'value');

                model = model.filter(function (row) {
                    if (property.indexOf('[]') !== -1) {
                        var _property$split = property.split('.[].'),
                            _property$split2 = _slicedToArray(_property$split, 2),
                            collectionName = _property$split2[0],
                            collectionProperty = _property$split2[1];

                        return get(row, collectionName).filter(function (child) {
                            var rowValue = get(child, collectionProperty);
                            return (0, _grid.filterValue)(operator, value, rowValue);
                        }).get('length') > 0;
                    } else {
                        var rowValue = get(row, property);

                        return (0, _grid.filterValue)(operator, value, rowValue);
                    }
                });
            });

            return model;
        },
        updateFilter: function updateFilter(filters) {
            set(this, 'filters', filters);
        }
    });
});