define('crm/components/simple-form-select', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set;
    exports.default = Ember.Component.extend({
        tagName: 'select',
        attributeBindings: ['name', 'required', 'disabled', 'multiple'],

        search: true,
        prompt: 'Vyberte',
        castInteger: true,
        required: false,
        disabled: false,
        width: '100%',

        onSelect: function onSelect() {},
        onDeselect: function onDeselect() {},
        onChange: function onChange() {},


        actions: {
            select: function select(value) {
                set(this, 'value', get(this, 'castInteger') ? parseInt(value) : value);

                get(this, 'onSelect')(get(this, 'value'));
                get(this, 'onChange')(get(this, 'value'));
            },
            unselect: function unselect() {
                set(this, 'value', null);

                get(this, 'onDeselect')();
                get(this, 'onChange')(null);
            }
        },

        didRender: function didRender() {
            var _this = this;

            this.$().select2({
                width: get(this, 'width'),
                dropdownAutoWidth: true,
                theme: 'bootstrap',
                allowClear: !get(this, 'required'),
                placeholder: get(this, 'prompt'),
                minimumResultsForSearch: get(this, 'search') ? 0 : -1
            }).on('select2:select', function (e) {
                _this.send('select', e.params.data.id);
            }).on('select2:unselect', function (e) {
                _this.send('unselect', e.params.data.id);
            }).trigger('change.select2');
        }
    });
});