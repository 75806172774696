define('crm/components/tool-tip', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        placement: 'right',

        didInsertElement: function didInsertElement() {
            this.$().tooltip({
                title: this.get('title'),
                placement: this.get('placement')
            });
        }
    });
});