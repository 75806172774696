define('crm/controllers/user/commissions/discount', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Zmena výslednej provízie',

        actions: {
            cancel: function cancel() {
                if (!this.get('model.isNew')) {
                    this.get('model').rollbackAttributes();
                }

                this.send('closeModal');
            }
        }
    });
});