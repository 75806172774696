define('crm/components/bills-calculator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set;


    var BILLS = [100, 50, 20, 10, 5, 2, 1, 0.5];

    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'amounts', []);
            set(this, 'summary', {});
        },
        didInsertElement: function didInsertElement() {
            this.calculateBills();
        },
        calculateBills: function calculateBills() {
            var toCalculate = get(this, 'calculate'),
                amounts = [],
                summary = {
                bills: []
            },
                billsCount = {},
                total = 0;

            toCalculate.forEach(function (remainder) {
                var amount = {
                    amount: remainder,
                    bills: []
                };

                BILLS.forEach(function (bill) {
                    var quotient = remainder / bill >> 0;

                    amount.bills.push({
                        value: bill,
                        count: quotient
                    });

                    billsCount[bill] = billsCount[bill] ? billsCount[bill] + quotient : quotient;

                    total += quotient * bill;
                    remainder = remainder % bill;
                });

                amounts.push(amount);
            });

            BILLS.forEach(function (bill) {
                summary.bills.push({
                    value: bill,
                    count: billsCount[bill]
                });
            });

            set(this, 'amounts', amounts);
            set(this, 'summary', summary);
            set(this, 'total', total);
        }
    });
});