define('crm/components/data-grid/pagination', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed,
        equal = Ember.computed.equal;
    exports.default = Ember.Component.extend({
        classNames: ['form-inline'],

        itemsPerPage: null,

        isFirst: equal('offset', 0),
        isLast: computed('{page,pages}', function () {
            return get(this, 'page') === get(this, 'pages');
        }),
        page: computed('{offset,limit}', function () {
            var _getProperties = this.getProperties('offset', 'limit'),
                offset = _getProperties.offset,
                limit = _getProperties.limit;

            return Math.ceil(offset / limit) + 1;
        }),
        pages: computed('limit', 'rows', function () {
            var _getProperties2 = this.getProperties('limit', 'rows'),
                limit = _getProperties2.limit,
                rows = _getProperties2.rows;

            var pages = Math.ceil(rows / limit);

            return Math.max(pages, 1);
        }),

        changeLimit: function changeLimit(limit) {
            this.setProperties({
                offset: 0,
                limit: limit
            });
        },
        previousPage: function previousPage() {
            var _getProperties3 = this.getProperties('offset', 'limit'),
                offset = _getProperties3.offset,
                limit = _getProperties3.limit;

            set(this, 'offset', offset - limit);
        },
        nextPage: function nextPage() {
            var _getProperties4 = this.getProperties('offset', 'limit'),
                offset = _getProperties4.offset,
                limit = _getProperties4.limit;

            set(this, 'offset', offset + limit);
        }
    });
});