define('crm/helpers/task-type', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.taskType = taskType;
    function taskType(params) {
        return _environment.default.taskTypes[params[0]];
    }

    exports.default = Ember.Helper.helper(taskType);
});