define('crm/routes/user/clients/detail', ['exports', 'crm/mixins/routes/loading'], function (exports, _loading) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        model: function model(params) {
            var client = params.client_id;

            return Ember.RSVP.hash({
                client: this.store.findRecord('client', client),
                invoices: this.store.query('invoice', {
                    filter: {
                        clientId: client
                    }
                })
            });
        }
    });
});