define('crm/models/task-field', ['exports', 'ember-data', 'crm/models/base', 'crm/mixins/model/persist-relationships'], function (exports, _emberData, _base, _persistRelationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = _base.default.extend(_persistRelationships.default, {
        value: attr('string'),

        task: belongsTo('task'),
        field: belongsTo('field'),
        option: belongsTo('field-option'),
        options: hasMany('field-option', {
            persist: true
        })
    });
});