define('crm/models/card', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    var get = Ember.get,
        computed = Ember.computed,
        alias = Ember.computed.alias;
    exports.default = _base.default.extend({
        value: attr('string'),
        disabled: attr('boolean'),
        lastUsed: attr('datetime'),
        createdAt: attr('datetime'),
        user: belongsTo('user'),
        note: attr('string'),

        userName: alias('user.name'),
        isDisabled: alias('disabled'),
        state: computed('isDisabled', function () {
            return get(this, 'isDisabled') ? 1 : 0;
        })
    });
});