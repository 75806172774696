define('crm/helpers/price', ['exports', 'crm/utils/numbers'], function (exports, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.price = price;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var helper = Ember.Helper.helper,
        htmlSafe = Ember.String.htmlSafe;
    function price(_ref, _ref2) {
        var _ref3 = _slicedToArray(_ref, 1),
            price = _ref3[0];

        var _ref2$showSign = _ref2.showSign,
            showSign = _ref2$showSign === undefined ? false : _ref2$showSign,
            _ref2$color = _ref2.color,
            color = _ref2$color === undefined ? false : _ref2$color;

        var sign = '',
            classNames = 'text-price';

        if (showSign) {
            sign = price > 0 ? '+' : '';
        }

        if (color && price !== 0) {
            classNames += price > 0 ? ' text-success' : ' text-danger';
        }

        if (price || price === 0) {
            return htmlSafe('<span class="' + classNames + '">' + sign + (0, _numbers.toFloat)(price).toFixed(2) + '&euro;</span>');
        } else {
            return '-';
        }
    }

    exports.default = helper(price);
});