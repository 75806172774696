define('crm/components/invoice-generator', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed,
        alias = Ember.computed.alias,
        service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        entities: service(),

        canChangeItems: true,

        addItem: function addItem() {},
        removeItem: function removeItem() {},


        client: alias('invoice.client'),
        invoicingCompany: alias('invoice.invoicingCompany'),

        isEditable: computed('invoice.isNew', function () {
            return true;
        }),
        showTaxCalculations: computed('invoice.noTax', 'invoice.invoicingCompany.taxPayer', function () {
            var invoice = get(this, 'invoice'),
                taxPayer = get(invoice, 'invoicingCompany.taxPayer'),
                noTax = get(invoice, 'noTax');

            return taxPayer ? !noTax : false;
        }),

        selectInvoiceType: function selectInvoiceType(type) {
            var invoice = get(this, 'invoice'),
                invoicingCompany = get(type, 'invoicingCompany');

            if (invoicingCompany) {
                set(invoice, 'invoicingCompany', invoicingCompany);
            }
        },
        selectClient: function selectClient(client) {
            var invoice = get(this, 'invoice'),
                issueDate = (0, _moment.default)(get(invoice, 'issueDate')),
                dueDays = get(client, 'invoiceDueDays'),
                invoicingCompany = get(invoice, 'invoicingCompany');

            invoice.setProperties({
                client: client,
                noTax: get(client, 'noTax'),
                dueDate: issueDate.clone().add(dueDays, 'days')
            });

            if (!invoicingCompany) {
                set(invoice, 'invoicingCompany', get(client, 'invoicingCompany'));
            }
        }
    });
});