define('crm/models/folder', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        name: _emberData.default.attr('string'),
        files: _emberData.default.hasMany('file')
    });
});