define('crm/components/dropdown-list/header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'h6',
        classNames: ['dropdown-header']
    });
});