define('crm/components/perfect-scroll', ['exports', 'ember-perfect-scrollbar'], function (exports, _emberPerfectScrollbar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberPerfectScrollbar.PerfectScrollbarMixin, {
        onReachEnd: function onReachEnd() {},
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);

            this.element.addEventListener('ps-y-reach-end', function () {
                return _this.onReachEnd();
            });
        }
    });
});