define('crm/helpers/is-empty', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.isEmpty = isEmpty;
    var get = Ember.get;
    function isEmpty(params) {
        var input = params[0];

        if (input) {
            if (typeof input === 'string') {
                return input ? ("" + input).length === 0 : true;
            } else {
                return get(input, 'length') === 0;
            }
        }

        return true;
    }

    exports.default = Ember.Helper.helper(isEmpty);
});