define('crm/controllers/user/contract-conditions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            enterAddDialog: function enterAddDialog() {
                this.send('openModal', 'user/contract-conditions/edit', this.store.createRecord('contract-condition'), 'user/contract-conditions/add');
            },
            enterEditDialog: function enterEditDialog(condition) {
                this.send('openModal', 'user/contract-conditions/edit', condition, 'user/contract-conditions/edit');
            },
            acceptCondition: function acceptCondition(condition) {
                var user = this.get('currentUser.user');
                condition.get('users').pushObject(user);

                condition.save();
            },
            removeCondition: function removeCondition(condition) {
                condition.destroyRecord();
            }
        }
    });
});