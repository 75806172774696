define('crm/controllers/user/tasks', ['exports', 'crm/config/environment', 'moment'], function (exports, _environment, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        Controller = Ember.Controller,
        service = Ember.inject.service,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        alias = _Ember$computed.alias,
        equal = _Ember$computed.equal,
        htmlSafe = Ember.String.htmlSafe;
    exports.default = Controller.extend({
        entities: service(),
        user: alias('currentUser.user'),
        statuses: _environment.default.taskStatuses,
        types: _environment.default.taskTypes,
        priorities: _environment.default.taskPriorities,

        viewAs: 'grid',
        showAssignedToMine: false,
        showAssignedToMyTeams: false,
        groupByGrid: null,
        groupByPipeline: 'state',
        sortBy: 'createdAt:desc',

        calendarDate: (0, _moment.default)(),
        prevDate: computed('calendarDate', function () {
            return get(this, 'calendarDate').clone().subtract(1, 'month');
        }),
        nextDate: computed('calendarDate', function () {
            return get(this, 'calendarDate').clone().add(1, 'month');
        }),

        title: computed('viewAsCalendar', 'calendarDate', function () {
            var calendarDate = get(this, 'calendarDate');

            return get(this, 'viewAsCalendar') ? htmlSafe('\xDAlohy <small>(' + calendarDate.format('MM.YYYY') + ')</small>') : 'Úlohy';
        }),

        groupByOptions: {
            'state': 'Stavu úlohy',
            'clientGroupingValue': 'Klienta',
            'assigneeGroupingValue': 'Priradenej osoby',
            'userGroupingValue': 'Zadávateľa',
            'teamGroupingValue': 'Tímu',
            'recurrence': 'Doby opakovania'
        },
        sortByOptions: {
            'dueDate:asc': 'Deadline',
            'createdAt:desc': 'Dátumu vytvorenia',
            'clientName': 'Klienta',
            'assigneeName': 'Priradenej osoby',
            'userName': 'Zadávateľa',
            'teamName': 'Tímu'
        },

        defaultFilters: [{
            property: 'status',
            operator: 'in',
            value: [0, 1, 3]
        }],

        tasks: computed('model.tasks', 'showAssignedToMine', 'showAssignedToMyTeams', function () {
            var tasks = get(this, 'model.tasks'),
                showAssignedToMine = get(this, 'showAssignedToMine'),
                showAssignedToMyTeams = get(this, 'showAssignedToMyTeams');

            if (showAssignedToMine) {
                tasks = tasks.filterBy('assignedTo', true);
            }

            if (showAssignedToMyTeams) {
                tasks = tasks.filterBy('assignedToMyTeam', true);
            }

            return tasks;
        }),

        viewAsGrid: equal('viewAs', 'grid'),
        viewAsColumns: equal('viewAs', 'columns'),
        viewAsCalendar: equal('viewAs', 'calendar'),

        columnsSortingDefinition: computed('groupByPipeline', function () {
            var groupBy = get(this, 'groupByPipeline');

            if (groupBy === 'state') {
                return 'state:asc';
            }

            return groupBy;
        }),

        openTask: function openTask(task) {
            this.send('openModal', 'user/tasks/detail', task, 'user/tasks/detail');
        },
        editTask: function editTask(task) {
            this.send('openModal', 'user/tasks/edit', task, 'user/tasks/edit');
        },
        removeTask: function removeTask(task) {
            if (confirm('Naozaj chcete odstrániť túto úlohu?')) {
                task.destroyRecord();
            }
        }
    });
});