define('crm/components/field-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        filterBy = Ember.computed.filterBy;
    exports.default = Ember.Component.extend({
        option: null,
        options: filterBy('field.options', 'isDeleted', false),

        addOption: function addOption() {
            var field = get(this, 'field'),
                option = get(this, 'option');

            get(field, 'options').createRecord({
                value: option
            });
            set(this, 'option', null);
        },
        removeOption: function removeOption(option) {
            option.deleteRecord();
        }
    });
});