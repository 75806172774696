define('crm/components/leads-dashboard', ['exports', 'ember-local-storage', 'crm/utils/arrays'], function (exports, _emberLocalStorage, _arrays) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        dashboard: (0, _emberLocalStorage.storageFor)('leads-dashboard-2'),

        groups: computed('dashboard.groups.[]', function () {
            var groups = get(this, 'dashboard.groups');

            return groups.map(function (serialized) {
                return _arrays.Group.create(serialized);
            });
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.events.subscribe('data-pipeline:remove-column', this, 'saveGroups');
            this.events.subscribe('data-pipeline:save-column-options', this, 'saveGroups');
            this.events.subscribe('data-pipeline:reorder-columns', this, 'saveReorderedGroups');
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            this.events.unsubscribe('data-pipeline:remove-column');
            this.events.unsubscribe('data-pipeline:save-column-options');
            this.events.unsubscribe('data-pipeline:reorder-columns');
        },
        saveGroups: function saveGroups() {
            var groups = get(this, 'groups').map(function (group) {
                return group.serialize();
            });

            set(this, 'dashboard.groups', groups);
        },
        saveReorderedGroups: function saveReorderedGroups(groups) {
            this.beginPropertyChanges();

            set(this, 'groups', groups);
            this.saveGroups();

            this.endPropertyChanges();
        }
    });
});