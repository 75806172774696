define('crm/router', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL,

        willTransition: function willTransition(oldInfos, newInfos, transition) {
            this._super(oldInfos, newInfos, transition);

            Ember.$('body').removeClass('nav-open');
        }
    });

    Router.map(function () {
        this.route('reports');
        this.route('login');
        this.route('user', function () {
            this.route('dashboard');
            this.route('clients', function () {
                this.route('detail', { path: '/detail/:client_id' });
            });
            this.route('users');
            this.route('reports', function () {
                this.route('create-monthly');
            });
            this.route('work-log', function () {
                this.route('grid');
                this.route('calendar');
            });
            this.route('roles');
            this.route('tasks', function () {
                this.route('detail', { path: '/detail/:task_id' });
            });
            this.route('teams');
            this.route('production');
            this.route('commissions');
            this.route('invoices');
            this.route('invoice-reminders');
            this.route('credit-notes');
            this.route('daily-tasks');
            this.route('attendance', function () {
                this.route('approve', { path: '/approve/:attendance_id' });
                this.route('reject', { path: '/reject/:attendance_id' });
            });
            this.route('logout');
            this.route('leads');
            this.route('sales-units');
            this.route('profile');
            this.route('lead-analyses');
            this.route('challenges');
            this.route('documents');
            this.route('contract-conditions');
            this.route('sticky-notes');
            this.route('meetings');
            this.route('wages');
            this.route('contracts');
            this.route('invoicing-companies');
            this.route('cards');
            this.route('blacklisted-people');
            this.route('fields');
            this.route('invoice-types');
            this.route('statistics');
        });
    });

    exports.default = Router;
});