define('crm/storages/leads-dashboard-2', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Storage = _object.default.extend();

    Storage.reopenClass({
        initialState: function initialState() {
            return {
                groups: [{
                    key: 'meetingFilterByValue',
                    value: 0
                }, {
                    key: 'state',
                    value: 0
                }]
            };
        }
    });

    exports.default = Storage;
});