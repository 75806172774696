define('crm/components/leads-pipeline/column', ['exports', 'crm/components/data-pipeline/column', 'crm/templates/components/data-pipeline/column'], function (exports, _column, _column2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _column.default.extend({
        layout: _column2.default,

        toggleOptions: function toggleOptions() {},
        updateGroup: function updateGroup() {},
        setColumnProperty: function setColumnProperty() {}
    });
});