define('crm/models/contract-condition', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        title: _emberData.default.attr('string'),
        condition: _emberData.default.attr('string'),
        isAccepted: _emberData.default.attr('boolean'),

        users: _emberData.default.hasMany('user')
    });
});