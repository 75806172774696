define('crm/helpers/count', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.count = count;
    var get = Ember.get;
    function count(params) {
        var collection = params[0];

        if (!collection) {
            return;
        }

        var count = get(collection, 'length'),
            one = params[1],
            twoToFour = params[2],
            moreThanFour = params[3];

        if (params.length === 1) {
            return count;
        }

        if (count === 1) {
            return count + ' ' + one;
        } else if (count > 1 && count < 5) {
            return count + ' ' + twoToFour;
        } else {
            return count + ' ' + moreThanFour;
        }
    }

    exports.default = Ember.Helper.helper(count);
});