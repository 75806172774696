define('crm/routes/user/blacklisted-people', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var hash = Ember.RSVP.hash;
    exports.default = Ember.Route.extend({
        model: function model() {
            return hash({
                blacklist: this.store.findAll('blacklisted-person')
            });
        },


        actions: {
            enterCreateDialog: function enterCreateDialog() {
                this.send('openModal', 'user/blacklisted-people/edit', this.store.createRecord('blacklisted-person'), 'user/blacklisted-people/add');
            },
            enterEditDialog: function enterEditDialog(row) {
                this.send('openModal', 'user/blacklisted-people/edit', row, 'user/blacklisted-people/edit');
            },
            removePerson: function removePerson(row) {
                row.destroyRecord();
            }
        }
    });
});