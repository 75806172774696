define('crm/components/data-grid/header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var _get = Ember.get,
        computed = Ember.computed,
        Component = Ember.Component;
    exports.default = Component.extend({
        tagName: 'thead',

        selected: computed('selection.[]', 'rows.[]', {
            get: function get() {
                var _this = this;

                var rows = _get(this, 'rows');

                return rows.filter(function (row) {
                    return _get(_this, 'selection').includes(row);
                }).get('length') === _get(rows, 'length');
            },
            set: function set(key, selected) {
                if (selected) {
                    _get(this, 'selection').addObjects(_get(this, 'rows'));
                } else {
                    _get(this, 'selection').removeObjects(_get(this, 'rows'));
                }

                return selected;
            }
        })
    });
});