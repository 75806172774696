define('crm/mixins/routes/loading', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        Mixin = Ember.Mixin,
        service = Ember.inject.service;
    exports.default = Mixin.create({
        events: service(),

        actions: {
            loading: function loading(transition) {
                if (document.activeElement) {
                    document.activeElement.blur();
                }

                var events = get(this, 'events');

                events.publish('route:loading');

                transition.promise.finally(function () {
                    events.publish('route:loaded');
                });
            }
        }
    });
});