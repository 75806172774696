define('crm/models/blacklisted-person', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _base.default.extend({
        name: attr('string'),
        city: attr('string'),
        reason: attr('string')
    });
});