define('crm/models/report-settings', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    graphicalLimit: attr('float', {
      defaultValue: 0
    }),
    socialNetworksLimit: attr('float', {
      defaultValue: 0
    }),
    developmentLimit: attr('float', {
      defaultValue: 0
    }),
    newsletterLimit: attr('float', {
      defaultValue: 0
    }),
    campaignManagementLimit: attr('float', {
      defaultValue: 0
    }),

    administrative: attr('float', {
      defaultValue: 0
    }),
    communication: attr('float', {
      defaultValue: 0
    }),
    stockPhotos: attr('number', {
      defaultValue: 0
    }),
    departuresLimit: attr('number', {
      defaultValue: 0
    }),
    stockPhotoPrice: attr('float', {
      defaultValue: 0
    }),
    stockPhotosPriceLimit: attr('float', {
      defaultValue: 0
    }),
    departurePrice: attr('float', {
      defaultValue: 0
    }),

    developmentInMonthlyFee: attr('boolean', {
      defaultValue: false
    }),
    expectedTimeOfDevelopment: attr('string'),
    developmentCommitmentToWork: attr('number', {
      defaultValue: 0
    }),

    expectedActivities: attr('string')
  });
});