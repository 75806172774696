define('crm/components/modal-dialog', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        schedule = Ember.run.schedule;
    exports.default = Ember.Component.extend({
        classNames: ['modal', 'fade'],

        customHeader: false,
        preventClose: false,

        onInit: function onInit() {},
        onClose: function onClose() {},
        init: function init() {
            this._super.apply(this, arguments);

            get(this, 'onInit')();
        },
        didInsertElement: function didInsertElement() {
            schedule('afterRender', this, function () {
                this.$().modal({
                    backdrop: 'static',
                    keyboard: false
                }).on('shown.bs.modal', function () {
                    this.events.publish('modal-dialog:open');
                }.bind(this)).on('hidden.bs.modal', function () {
                    this.events.publish('modal-dialog:hide');

                    get(this, 'onClose')();
                }.bind(this));
            });
        },
        willClearRender: function willClearRender() {
            this._super.apply(this, arguments);

            this.$().modal('hide');
        }
    });
});