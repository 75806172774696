define('crm/components/data-grid/footer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tfoot',
        itemsPerPage: { 10: '10', 20: '20', 50: '50', 100: '100' }
    });
});