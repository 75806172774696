define('crm/components/task-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        classNames: ['task-card'],
        classNameBindings: ['stateClass'],

        showState: true,
        stateClass: computed('showState', 'task.state', function () {
            return get(this, 'showState') ? 'task-card-state-' + get(this, 'task.state') : null;
        }),

        onClick: function onClick() {},
        openDetail: function openDetail() {
            get(this, 'onClick')(get(this, 'task'));
        }
    });
});