define('crm/components/tasks-calendar', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        observer = Ember.observer,
        $ = Ember.$;


    var TASK_COLORS = {
        0: '#FFA000',
        1: '#2196F3',
        2: '#4CAF50',
        3: '#009688'
    };

    exports.default = Ember.Component.extend({
        onEventClick: function onEventClick(calendarEvent) {
            var id = calendarEvent.id,
                task = get(this, 'tasks').findBy('id', id);

            if (task) {
                get(this, 'openTask')(task);
            }
        },
        onEventDrop: function onEventDrop(calendarEvent, delta) {
            var id = calendarEvent.id,
                task = get(this, 'tasks').findBy('id', id);

            if (task) {
                var dueDate = (0, _moment.default)(get(task, 'dueDate')).add(delta),
                    startDate = get(task, 'startDate');

                if (startDate) {
                    startDate = (0, _moment.default)(startDate).add(delta);
                }

                task.setProperties({
                    dueDate: dueDate,
                    startDate: startDate
                });

                task.save();
            }
        },


        dateObserver: observer('date', function () {
            get(this, 'fullCalendar').fullCalendar('gotoDate', get(this, 'date'));
        }),

        fullCalendar: computed(function () {
            return $('.full-calendar');
        }),

        calendarData: computed('tasks.@each.{summary,dueDate,isDone,startDate,estimate}', function () {
            var tasks = get(this, 'tasks').rejectBy('dueDate', null).rejectBy('isRecurrent', true).rejectBy('isDone', true);

            return tasks.map(function (task) {
                var dueDate = get(task, 'dueDate'),
                    startDate = get(task, 'startDate') || dueDate;

                return {
                    id: get(task, 'id'),
                    title: get(task, 'summary'),
                    start: (0, _moment.default)(startDate),
                    end: (0, _moment.default)(dueDate),
                    backgroundColor: TASK_COLORS[get(task, 'state')]
                };
            });
        })
    });
});