define('crm/initializers/inject-entities', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        application.inject('route', 'entities', 'service:entities');
        application.inject('controller', 'entities', 'service:entities');
        application.inject('component', 'entities', 'service:entities');
    }

    exports.default = {
        initialize: initialize
    };
});