define('crm/controllers/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var set = Ember.set;
    exports.default = Ember.Controller.extend({
        init: function init() {
            this._super.apply(this, arguments);

            this.events.subscribe('route:loading', this, 'onRouteLoading');
            this.events.subscribe('route:loaded', this, 'onRouteLoaded');
        },
        onRouteLoading: function onRouteLoading() {
            set(this, 'isLoading', true);
        },
        onRouteLoaded: function onRouteLoaded() {
            set(this, 'isLoading', false);
        }
    });
});