define('crm/models/invoice-type', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        name: _emberData.default.attr('string'),
        default: _emberData.default.attr('boolean'),
        defaultForProduction: _emberData.default.attr('boolean'),
        defaultForCredit: _emberData.default.attr('boolean'),
        defaultForMonthlyInvoices: _emberData.default.attr('boolean'),
        altersCredit: _emberData.default.attr('boolean'),
        dueDays: _emberData.default.attr('number'),

        invoicingCompany: _emberData.default.belongsTo('invoicing-company')
    });
});