define('crm/controllers/user/lead-analyses/form-designer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            save: function save() {
                this.get('model').save();
                this.send('closeModal');
            },
            cancel: function cancel() {}
        }
    });
});