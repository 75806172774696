define('crm/components/drop-zone-queue-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        get = Ember.get,
        htmlSafe = Ember.String.htmlSafe;
    exports.default = Ember.Component.extend({
        classNames: ['list-group-item', 'd-flex', 'justify-content-between', 'align-items-center'],
        showFilesize: true,

        progressStyle: computed('file.progress', function () {
            var progress = get(this, 'file.progress');

            return htmlSafe('width: ' + progress + '%');
        })
    });
});