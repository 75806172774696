define('crm/components/data-grid/action', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        _Ember$computed = Ember.computed,
        notEmpty = _Ember$computed.notEmpty,
        not = _Ember$computed.not,
        Component = Ember.Component;
    exports.default = Component.extend({
        tagName: 'button',
        type: 'button',
        classNames: 'btn btn-primary btn-sm'.w(),
        attributeBindings: ['type', 'disabled'],

        selected: notEmpty('selection'),
        disabled: not('selected'),

        onClick: function onClick() {},
        click: function click() {
            get(this, 'onClick')();
        }
    });
});