define('crm/components/form-errors', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var set = Ember.set,
        A = Ember.A;
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'errors', A([]));
        }
    });
});