define('crm/models/reward', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        amount: _emberData.default.attr('float'),
        description: _emberData.default.attr('string'),
        icon: _emberData.default.attr('string'),
        salesUnit: _emberData.default.belongsTo('sales-unit')
    });
});