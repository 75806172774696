define('crm/routes/user/users', ['exports', 'crm/mixins/routes/loading'], function (exports, _loading) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        model: function model() {
            return Ember.RSVP.hash({
                users: this.store.findAll('user'),
                roles: this.store.findAll('role')
            });
        }
    });
});