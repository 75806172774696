define('crm/controllers/user/employees/add', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Pridať zamestnanca',

        saved: function saved() {
            get(this, 'toasts').success('Zamestnanec bol vytvorený.');
        }
    });
});