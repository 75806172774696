define('crm/controllers/user/credit-notes/edit', ['exports', 'crm/controllers/user/credit-notes/create'], function (exports, _create) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _create.default.extend({
        title: 'Upraviť dobropis',

        saved: function saved() {
            this.get('toasts').success('Dobropis bol uložený.');
        }
    });
});