define('crm/models/work-log', ['exports', 'ember-data', 'crm/models/base', 'moment', 'crm/config/environment'], function (exports, _emberData, _base, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    user: _emberData.default.belongsTo('user'),
    date: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)();
      }
    }),
    client: _emberData.default.belongsTo('client'),
    employee: _emberData.default.belongsTo('employee'),
    workLogCategory: _emberData.default.belongsTo('work-log-category'),
    type: _emberData.default.attr('number', {
      defaultValue: null
    }),
    description: _emberData.default.attr('string'),
    duration: _emberData.default.attr('duration'),
    maxDuration: _emberData.default.attr('duration'),
    note: _emberData.default.attr('string'),
    stockPhotos: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    boostPrice: _emberData.default.attr('float'),
    template: _emberData.default.attr('boolean'),

    canChangeDate: Ember.computed('date', function () {
      var today = (0, _moment.default)(),
          date = (0, _moment.default)(this.get('date'));

      return today.diff(date, 'days') <= 1;
    }),

    isGraphical: Ember.computed.equal('type', 1),
    isSocial: Ember.computed.equal('type', 2),
    isDeparture: Ember.computed.equal('type', 3),

    dateGroupingValue: Ember.computed('date', function () {
      return (0, _moment.default)(this.get('date')).format('L');
    }),
    typeGroupingValue: Ember.computed('type', function () {
      return _environment.default.taskTypes[this.get('type')];
    }),
    clientGroupingValue: Ember.computed('client.name', function () {
      return this.get('client.name');
    }),
    userGroupingValue: Ember.computed('user.name', function () {
      return this.get('user.name');
    })
  });
});