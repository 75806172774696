define('crm/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'crm/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var RESTAdapter = _emberData.default.RESTAdapter;
    exports.default = RESTAdapter.extend(_dataAdapterMixin.default, {
        authorizer: 'authorizer:oauth2',
        host: _environment.default.api.host + '/api',

        shouldReloadAll: function shouldReloadAll() {
            return true;
        },
        shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
            return true;
        }
    });
});