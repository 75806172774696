define('crm/components/add-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;
    exports.default = Ember.Component.extend({
        tagName: 'button',
        type: 'button',
        attributeBindings: ['type'],
        classNames: ['btn', 'btn-primary'],

        onClick: function onClick() {},
        click: function click(e) {
            get(this, 'onClick')();

            e.preventDefault();
        }
    });
});