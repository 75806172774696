define('crm/components/credit-note-generator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        canChangeItems: true,

        addItem: function addItem() {},
        removeItem: function removeItem() {},


        isEditable: Ember.computed('creditNote.isNew', function () {
            return this.get('creditNote.isNew');
        }),

        showTaxCalculations: computed('creditNote.noTax', 'creditNote.invoicingCompany.taxPayer', function () {
            var creditNote = get(this, 'creditNote'),
                taxPayer = get(creditNote, 'invoicingCompany.taxPayer'),
                noTax = get(creditNote, 'noTax');

            return taxPayer ? !noTax : false;
        })
    });
});