define('crm/overrides/text-field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        getOwner = Ember.getOwner;
    exports.default = Ember.TextField.reopen({
        classNames: 'form-control',
        transform: null,

        _elementValueDidChange: function _elementValueDidChange() {
            set(this, 'value', this.getTransformedValue());
        },
        getTransformedValue: function getTransformedValue() {
            var value = this.element.value;

            if (get(this, 'transform')) {
                var transform = getOwner(this).lookup('transform:' + get(this, 'transform'));

                if (transform) {
                    value = transform.serialize(value);
                }
            }

            return value;
        }
    });
});