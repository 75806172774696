define('crm/routes/index', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        state: (0, _emberLocalStorage.storageFor)('state'),

        beforeModel: function beforeModel() {
            var lastRoute = null; //this.get('state.currentRoute');

            if (lastRoute) {
                this.transitionTo(lastRoute);
            } else {
                if (this.currentUser.hasAccessTo('tasks')) {
                    this.transitionTo('user.daily-tasks');
                } else {
                    this.transitionTo('user.dashboard');
                }
            }
        }
    });
});