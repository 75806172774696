define('crm/controllers/user/sales-units/add', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Vytvoriť skupinu',

        entities: Ember.inject.service(),
        users: Ember.computed.alias('entities.users'),

        addMember: function addMember(member) {
            this.get('model.members').addObject(member);
        },
        removeMember: function removeMember(member) {
            this.get('model.members').removeObject(member);
        },
        saved: function saved() {
            this.get('toasts').success('Obchodná skupina bola vytvorená.');
        }
    });
});