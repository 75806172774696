define('crm/components/lead-analysis-designer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        types: {
            1: 'Slovná odpoveď',
            2: 'Výber jednej možnosti',
            3: 'Výber viacerých možností'
        },

        actions: {
            addQuestion: function addQuestion() {
                this.get('store').createRecord('lead-analysis/question');
            },
            addOption: function addOption(question) {
                question.get('options').createRecord({
                    value: this.get('option')
                });

                this.set('option', null);
            }
        }
    });
});