define('crm/components/submit-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        tagName: 'button',
        type: 'submit',
        value: 'Uložiť',
        buttonClass: 'btn-primary',
        classNames: ['btn'],
        classNameBindings: ['buttonClass'],
        attributeBindings: ['type', 'isDisabled:disabled'],

        onClick: function onClick() {},


        isDisabled: computed('isLoading', 'disabled', function () {
            return get(this, 'isLoading') || get(this, 'disabled');
        }),

        click: function click() {
            get(this, 'onClick')();
        }
    });
});