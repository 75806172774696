define('crm/components/leads/lead-client-employees', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        canRemoveEmployee: computed('employees.@each', function () {
            return get(this, 'employees.length') > 1;
        }),

        addEmployee: function addEmployee() {
            get(this, 'employees').createRecord({
                editing: true
            });
        },
        editEmployee: function editEmployee(employee) {
            set(employee, 'editing', true);
        },
        saveEmployee: function saveEmployee(employee) {
            set(employee, 'editing', false);
        },
        removeEmployee: function removeEmployee(employee) {
            employee.destroyRecord();
        },
        rollbackEmployee: function rollbackEmployee(employee) {
            set(employee, 'editing', false);
            employee.rollbackAttributes();
        }
    });
});