define('crm/serializers/task', ['exports', 'crm/serializers/application', 'ember-data', 'crm/mixins/serializers/files-serializer'], function (exports, _application, _emberData, _filesSerializer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
    exports.default = _application.default.extend(EmbeddedRecordsMixin, _filesSerializer.default, {
        includeUser: true,

        attrs: {
            days: { embedded: 'always' },
            comments: { embedded: 'always' },
            todos: { embedded: 'always' },
            fields: { embedded: 'always' }
        }
    });
});