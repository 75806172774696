define('crm/components/modal-panes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var A = Ember.A,
        get = Ember.get,
        set = Ember.set;
    exports.default = Ember.Component.extend({
        classNames: ['modal-panes'],
        current: 1,
        defaultStep: 1,

        onRegister: function onRegister() {},
        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'panes', A());
            get(this, 'onRegister')(this);
        },
        didInsertElement: function didInsertElement() {
            this.goTo(get(this, 'defaultStep'), false);
        },
        goTo: function goTo(index) {
            var animate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

            if (index !== get(this, 'current')) {
                set(this, 'current', index);

                var pane = get(this, 'panes').objectAt(index - 1);
                var offset = get(pane, 'offset');

                this.$('.modal-panes-content').toggleClass('animated', animate);

                this.$('.modal-panes-content').css({
                    transform: 'translateX(-' + offset + 'px)'
                });
            }
        },
        addPane: function addPane(step) {
            get(this, 'panes').addObject(step);
        },
        removePane: function removePane(step) {
            get(this, 'panes').removeObject(step);
        }
    });
});