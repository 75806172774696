define('crm/routes/user/roles', ['exports', 'crm/mixins/routes/loading'], function (exports, _loading) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        model: function model() {
            return this.store.findAll('role');
        }
    });
});