define('crm/models/lead-analysis', ['exports', 'ember-data', 'crm/mixins/model/persist-relationships'], function (exports, _emberData, _persistRelationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_persistRelationships.default, {
        processed: _emberData.default.attr('boolean', {
            defaultValue: false
        }),

        status: Ember.computed('processed', function () {
            return this.get('processed') ? 2 : 1;
        }),

        lead: _emberData.default.belongsTo('lead'),
        answers: _emberData.default.hasMany('lead-analysis/answer', {
            persist: true
        }),
        createdAt: _emberData.default.attr('datetime')
    });
});