define('crm/macros/group-by', ['exports', 'ember-macro-helpers/create-class-computed', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key', 'crm/utils/arrays'], function (exports, _createClassComputed, _computed, _normalizeArrayKey, _arrays) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        isArray = Ember.isArray;
    exports.default = (0, _createClassComputed.default)([false, true], function (array, groupByKey) {
        var multiple = false,
            key = groupByKey;

        if (isArray(key)) {
            key = key.map(function (group) {
                return get(group, 'key');
            }).filter(function (key) {
                return key !== null;
            });
            multiple = true;
        } else {
            key = [key];
        }

        return (0, _computed.default)((0, _normalizeArrayKey.default)(array, key), function (array) {
            return !multiple ? (0, _arrays.groupBy)(array, groupByKey) : (0, _arrays.groupByMultiple)(array, groupByKey);
        });
    });
});