define('crm/helpers/lead-items-sort-by', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.leadItemsSortBy = leadItemsSortBy;
    var get = Ember.get;
    function leadItemsSortBy(params, hash) {
        var group = hash.group,
            groupBy = hash.groupBy,
            sortBy = hash.sortBy;


        if (groupBy === 'meetingGroupingValue') {
            return ['meetingSortingValue:desc', 'createdAt:desc'];
        } else if (group && get(group, 'key') === 'meetingFilterByValue') {
            return ['meetingSortingValue:asc'];
        }

        return sortBy;
    }

    exports.default = Ember.Helper.helper(leadItemsSortBy);
});