define('crm/components/data-grid/header/cell', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        notEmpty = _Ember$computed.notEmpty,
        alias = _Ember$computed.alias;
    exports.default = Ember.Component.extend({
        tagName: 'th',
        attributeBindings: ['width'],

        sortable: notEmpty('sortBy'),
        sortingBy: alias('grid.sortBy'),
        isSorting: computed('sortingBy.[]', 'sortBy', function () {
            return get(this, 'sortingBy').findBy('column', get(this, 'sortBy'));
        }),

        btnClass: computed('isSorting', function () {
            return get(this, 'isSorting') ? 'btn btn-sort active' : 'btn btn-sort';
        }),

        sort: function sort(event) {
            var sortBy = get(this, 'sortBy');

            if (event.altKey || event.ctrlKey || event.metaKey) {
                get(this, 'grid').sortMultiple(sortBy);
            } else {
                get(this, 'grid').sort(sortBy);
            }
        }
    });
});