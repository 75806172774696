define('crm/helpers/sub', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.sub = sub;
    var helper = Ember.Helper.helper;
    function sub(params) {
        var firstNumber = parseFloat(params[0] || 0),
            secondNumber = parseFloat(params[1] || 0);

        return roundFloat(firstNumber - secondNumber);
    }

    function isFloat(n) {
        return Number(n) === n && n % 1 !== 0;
    }

    function roundFloat(num) {
        if (isFloat(num)) {
            return Math.round(num * 100) / 100;
        }
        return num;
    }

    exports.default = helper(sub);
});