define('crm/components/toasts-container', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    exports.default = Ember.Component.extend({
        classNames: ['toasts-container'],

        messages: alias('toasts.toasts')
    });
});