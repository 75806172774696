define('crm/helpers/replace', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.replace = replace;
    var helper = Ember.Helper.helper;
    function replace(params) {
        if (!params[0]) {
            return '';
        }

        var re = new RegExp(params[1]);

        return params[0].replace(re, params[2]);
    }

    exports.default = helper(replace);
});