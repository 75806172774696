define('crm/components/leads-pipeline', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        states: _environment.default.leadStates,
        statesPlurals: _environment.default.leadStatesPlurals,
        groups: [],
        sortColumnsBy: null,

        toggleDashboardSettings: function toggleDashboardSettings(column) {
            this.events.publish('leads-dashboard:toggleSettings', column);
        },


        filterOptions: {
            'meetingFilterByValue': 'Podľa termínu stretnutia',
            'state': 'Prípady podľa stavu',
            'userGroupingValue': 'Prípady podľa obchodného zástupcu',
            'salesUnitGroupingValue': 'Prípady podľa obchodnej skupiny'
        }
    });
});