define('crm/models/commission-discount', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        index: _emberData.default.attr('number'),
        discount: _emberData.default.attr('float', {
            defaultValue: 0
        }),
        note: _emberData.default.attr('string'),
        user: _emberData.default.belongsTo('user')
    });
});