define('crm/controllers/user/invoice-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            enterAddTypeDialog: function enterAddTypeDialog() {
                this.send('openModal', 'user/invoice-types/edit', this.store.createRecord('invoice-type'), 'user/invoice-types/add');
            },
            enterEditTypeDialog: function enterEditTypeDialog(model) {
                this.send('openModal', 'user/invoice-types/edit', model, 'user/invoice-types/edit');
            }
        }
    });
});