define('crm/models/file', ['exports', 'ember-data', 'crm/models/base', 'crm/config/environment', 'moment'], function (exports, _emberData, _base, _environment, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        name: _emberData.default.attr('string'),
        size: _emberData.default.attr('number'),
        path: _emberData.default.attr('string'),
        user: _emberData.default.belongsTo('user'),
        createdAt: _emberData.default.attr('datetime', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)();
            }
        }),

        isUploaded: Ember.computed('id', function () {
            return !!this.get('id');
        }),

        downloadUrl: Ember.computed('path', 'name', function () {
            var storageUrl = _environment.default.storageURL,
                path = this.get('path'),
                name = this.get('name');

            if (path) {
                return storageUrl + '/' + path + '/' + name;
            }
        })
    });
});