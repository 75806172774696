define('crm/routes/user/statistics', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),

        queryParams: {
            month: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var month = params.month,
                from = (0, _moment.default)(month, 'M-Y').clone().startOf('month'),
                to = (0, _moment.default)(month, 'M-Y').clone().endOf('month');

            return Ember.RSVP.hash({
                statistics: this.ajax.request('/statistics?month=' + month),
                invoices: this.store.query('invoice', {
                    filter: {
                        issueDate: [from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')]
                    }
                })
            });
        }
    });
});