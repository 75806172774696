define('crm/routes/user/invoicing-companies', ['exports', 'crm/mixins/routes/loading'], function (exports, _loading) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        model: function model() {
            return Ember.RSVP.hash({
                invoicingCompanies: this.store.findAll('invoicingCompany')
            });
        },


        actions: {
            enterCreateDialog: function enterCreateDialog() {
                this.send('openModal', 'user/invoicing-companies/edit', this.store.createRecord('invoicingCompany'), 'user/invoicing-companies/add');
            },
            enterEditDialog: function enterEditDialog(company) {
                this.send('openModal', 'user/invoicing-companies/edit', company, 'user/invoicing-companies/edit');
            }
        }
    });
});