define('crm/models/client-branch', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        client: _emberData.default.belongsTo('client'),
        name: _emberData.default.attr('string'),
        includeInMonthlyInvoice: _emberData.default.attr('boolean', {
            defaultValue: true
        })
    });
});