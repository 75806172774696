define('crm/controllers/user/clients/alter-credit', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Upraviť kredit',

        amount: null,

        onSave: function onSave() {
            this.toasts.info('Hodnota kreditu bola zmenená.');
        }
    });
});