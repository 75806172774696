define('crm/routes/user/work-log/grid', ['exports', 'moment', 'crm/mixins/routes/loading'], function (exports, _moment, _loading) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        user: Ember.computed.oneWay('currentUser.user'),
        from: (0, _moment.default)([new Date().getFullYear(), new Date().getMonth()]),
        to: Ember.computed('from', function () {
            return this.get('from').clone().endOf('month');
        }),

        model: function model() {
            var from = this.get('from').clone().format('YYYY-MM-DD'),
                to = this.get('to').clone().format('YYYY-MM-DD');

            return Ember.RSVP.hash({
                from: this.get('from'),
                to: this.get('to'),
                workLogs: this.store.query('workLog', {
                    filter: {
                        userId: this.get('user.id'),
                        clientId: this.get('client.id'),
                        date: [from, to]
                    }
                })
            });
        },
        afterModel: function afterModel(model) {
            model.workLogs = this.store.peekAll('work-log');
        },
        beforeModel: function beforeModel() {
            this.store.unloadAll('workLog');
        },


        actions: {
            reloadWorkLogs: function reloadWorkLogs(data) {
                this.setProperties(data);

                this.refresh();
            }
        }
    });
});