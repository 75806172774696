define('crm/controllers/user/work-log/grid', ['exports', 'crm/config/environment', 'moment'], function (exports, _environment, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),
        types: _environment.default.taskTypes,
        groupBy: 'dateGroupingValue',
        showNotes: false,
        showBoosts: false,

        groupByOptions: {
            'dateGroupingValue': 'Dátumu',
            'typeGroupingValue': 'Typu záznamu',
            'clientGroupingValue': 'Klienta',
            'userGroupingValue': 'Zamestnanca'
        },

        workLogs: Ember.computed.filterBy('model.workLogs', 'isNew', false),

        user: Ember.computed.oneWay('currentUser.user'),
        range: Ember.computed('model.{from,to}', function () {
            return [this.get('model.from'), this.get('model.to')];
        }),

        canChangeEmployee: Ember.computed('currentUser.user.{isSuperadmin,role.reportsVisibility}', function () {
            var user = this.currentUser.get('user'),
                role = user.get('role');

            return !user.get('isSuperadmin') ? role.get('reportsVisibility') > 1 : true;
        }),
        employees: Ember.computed('currentUser.user.role.reportsVisibility', 'entities.{visibleUsers,employedUsers}', function () {
            var user = this.currentUser.get('user'),
                role = user.get('role');

            return role.get('reportsVisibility') === 2 ? this.get('entities.visibleUsers') : this.get('entities.employedUsers');
        }),

        remove: function remove(model) {
            if (confirm('Naozaj chcete odstrániť tento záznam?')) {
                model.destroyRecord();
            }
        },


        actions: {
            reload: function reload() {
                var range = this.get('range');

                this.send('reloadWorkLogs', {
                    user: this.get('user'),
                    client: this.get('client'),
                    from: (0, _moment.default)(range[0]),
                    to: (0, _moment.default)(range[1])
                });
            }
        }
    });
});