define('crm/components/user-profile', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['user-profile'],
        classNameBindings: ['small:user-profile-sm', 'large:user-profile-lg', 'extended:user-profile-extended', 'right:user-profile-right'],

        small: false,
        large: false,
        extended: false,
        picture: true
    });
});