define('crm/routes/user/logout', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),

        beforeModel: function beforeModel(transition) {
            if (window.confirm('Naozaj sa chcete odhlásiť?')) {
                this.get('session').invalidate();
            } else {
                transition.abort();
            }
        }
    });
});