define('crm/routes/user/reports/create-monthly', ['exports', 'crm/mixins/routes/loading', 'moment'], function (exports, _loading, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_loading.default, {
        from: Ember.computed(function () {
            var date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth();

            return (0, _moment.default)(new Date(y, m, 1));
        }),
        to: Ember.computed(function () {
            var date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth();

            return (0, _moment.default)(new Date(y, m + 1, 0));
        }),

        model: function model() {
            this.get('store').unloadAll('delivery-note');

            var from = this.get('from').clone().format('YYYY-MM-DD');
            var to = this.get('to').clone().format('YYYY-MM-DD');

            return Ember.RSVP.hash({
                dateFrom: this.get('from'),
                dateTo: this.get('to'),
                clients: this.store.findAll('client'),
                deliveryNotes: this.store.query('delivery-note', {
                    filter: {
                        date: [from, to]
                    }
                }),
                reports: this.store.findAll('report'),
                invoices: this.store.findAll('invoice')
            });
        },


        actions: {
            selectClient: function selectClient(model) {
                this.transitionTo('user.reports.create-monthly.report', model);
            }
        }
    });
});