define('crm/serializers/attendance', ['exports', 'crm/serializers/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        keyForRelationship: function keyForRelationship(key, relationship) {
            if (key === 'approvedBy') {
                return 'approved_by';
            } else {
                return this._super(key, relationship);
            }
        }
    });
});