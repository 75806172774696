define('crm/helpers/shorten', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.shorten = shorten;
    var helper = Ember.Helper.helper;
    function shorten(params) {
        var string = params[0],
            maxChars = parseInt(params[1]);

        if (string.length > maxChars) {
            string = string.substr(0, maxChars) + '...';
        }

        return string;
    }

    exports.default = helper(shorten);
});