define('crm/components/leads/lead-setup/meeting', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias,
        get = Ember.get,
        set = Ember.set,
        service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        store: service(),

        classNames: ['modal-section'],
        panes: null,

        client: alias('lead.client'),
        employees: alias('client.employees'),
        state: alias('lead.state'),

        isFormValid: function isFormValid() {
            return this.$().closest('form').valid();
        },
        setMeeting: function setMeeting() {
            var meeting = get(this, 'store').createRecord('meeting', {
                lead: get(this, 'lead')
            });

            set(this, 'meeting', meeting);
            get(this, 'panes').goTo(2);
        },
        saveMeeting: function saveMeeting() {
            if (this.isFormValid()) {
                if (get(this, 'state') === 0) {
                    set(this, 'state', 1);
                }

                get(this, 'panes').goTo(1);
            }
        },
        rollbackMeeting: function rollbackMeeting() {
            var _this = this;

            var meeting = get(this, 'meeting');

            meeting.then(function (meeting) {
                meeting.rollbackAttributes();

                get(_this, 'panes').goTo(1);
            });
        }
    });
});