define('crm/components/data-grid/row', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var _get = Ember.get,
        computed = Ember.computed,
        alias = Ember.computed.alias;
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        classNameBindings: ['selected:table-selected', 'rowClass'],

        rowClass: alias('model.rowClass'),
        isSelectable: computed('selection', function () {
            return _get(this, 'selection') !== null;
        }),

        selected: computed('selection.[]', {
            get: function get() {
                if (_get(this, 'selection')) {
                    return _get(this, 'selection').includes(_get(this, 'model'));
                }
            },
            set: function set(key, selected) {
                if (selected) {
                    _get(this, 'selection').addObject(_get(this, 'model'));
                } else {
                    _get(this, 'selection').removeObject(_get(this, 'model'));
                }

                return selected;
            }
        }),

        click: function click(event) {
            if (_get(this, 'isSelectable')) {
                if (event.target.tagName === 'TD') {
                    this.toggleProperty('selected');
                }
            }
        }
    });
});