define('crm/models/role', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var equal = Ember.computed.equal;
    exports.default = _base.default.extend({
        name: _emberData.default.attr('string'),
        accessClients: _emberData.default.attr('boolean'),
        accessReports: _emberData.default.attr('boolean'),
        accessGroups: _emberData.default.attr('boolean'),
        accessUsers: _emberData.default.attr('boolean'),
        accessTeams: _emberData.default.attr('boolean'),
        accessProduction: _emberData.default.attr('boolean'),
        accessInvoicing: _emberData.default.attr('boolean'),
        accessCommissions: _emberData.default.attr('boolean'),
        accessAttendance: _emberData.default.attr('boolean'),
        accessBusiness: _emberData.default.attr('boolean'),
        accessSalesUnits: _emberData.default.attr('boolean'),
        accessTasks: _emberData.default.attr('boolean'),

        clientsVisibility: _emberData.default.attr('number', {
            defaultValue: 2
        }),
        attendanceVisibility: _emberData.default.attr('number', {
            defaultValue: 1
        }),
        reportsVisibility: _emberData.default.attr('number', {
            defaultValue: 1
        }),

        clientProfileAccess: _emberData.default.attr('number', {
            defaultValue: 1
        }),

        users: _emberData.default.hasMany('user'),

        canViewOnlyMineAttendance: equal('attendanceVisibility', 1),
        canViewTeamAttendance: equal('attendanceVisibility', 2),
        canViewAllAttendance: equal('attendanceVisibility', 3)
    });
});