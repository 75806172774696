define('crm/components/edit-client', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        entities: service(),

        commitmentTypes: _environment.default.commitmentTypes
    });
});