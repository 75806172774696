define('crm/components/data-filter/filters/date', ['exports', 'crm/components/date-input', 'crm/mixins/component/filter'], function (exports, _dateInput, _filter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _dateInput.default.extend(_filter.default, {
        getFilteringCondition: function getFilteringCondition() {
            var value = this.get('value');

            if (value) {
                return {
                    property: this.get('name'),
                    value: this.get('value'),
                    operator: 'date'
                };
            }
        },
        clearFilter: function clearFilter() {
            this.set('value', null);
        }
    });
});