define('crm/controllers/user/leads', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        service = Ember.inject.service,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        equal = _Ember$computed.equal,
        filterBy = _Ember$computed.filterBy;
    exports.default = Ember.Controller.extend({
        entities: service(),
        states: _environment.default.leadStates,
        statesPlurals: _environment.default.leadStatesPlurals,
        viewAs: 'dashboard',
        groupBy: 'state',
        sortBy: 'createdAt:desc',

        groupByOptions: {
            'state': 'Stavu prípadu',
            'meetingGroupingValue': 'Termínu kontaktovania',
            'userGroupingValue': 'Obchodného zástupcu',
            'salesUnitGroupingValue': 'Obchodnej skupiny'
        },
        sortByOptions: {
            'createdAt:desc': 'Dátumu vytvorenia',
            'meetingSortingValue:desc': 'Termínu kontaktovania',
            'clientSortingValue:asc': 'Klienta',
            'userGroupingValue:asc': 'Obchodného zástupcu',
            'recentNoteSortingValue:desc': 'Poslednej aktivtiy'
        },

        leads: filterBy('model.leads', 'isNew', false),
        columnsSortingDefinition: computed('groupBy', function () {
            var groupBy = get(this, 'groupBy');

            if (groupBy === 'state') {
                return 'state:asc';
            } else if (groupBy === 'meetingGroupingValue') {
                return 'meetingFilterByValue:desc';
            } else if (groupBy === 'salesUnitGroupingValue') {
                return 'salesUnitSortingValue:asc';
            }

            return groupBy;
        }),

        viewDashboard: equal('viewAs', 'dashboard'),
        viewAsColumns: equal('viewAs', 'columns'),
        viewAsTable: equal('viewAs', 'table'),
        viewMap: equal('viewAs', 'map'),

        openBatchChangeStatesDialog: function openBatchChangeStatesDialog(leads) {
            this.send('openModal', 'user/leads/batch-change-states', leads, 'user/leads/batch-change-states');
        }
    });
});