define('crm/controllers/user/invoices', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),

        statuses: {
            0: 'Nová',
            1: 'Neuhradená',
            2: 'Čiastočne uhradená',
            3: 'Uhradená',
            4: 'Uhradená po splatnosti',
            5: 'Pred splatnosťou'
        },

        totalAmounts: Ember.computed.mapBy('model.invoices', 'totalPrice'),
        highestAmount: Ember.computed.max('totalAmounts'),

        groupBy: null,
        groupByOptions: {
            'client.name': 'Klienta'
        },

        selectedInvoices: [],

        actions: {
            export: function _export() {
                var invoices = this.get('selectedInvoices');

                this.send('openModal', 'user/download-document', invoices, 'user/invoices/download');
            },
            enterCreateDialog: function enterCreateDialog() {
                this.send('openModal', 'user/invoices/edit', this.store.createRecord('invoice'), 'user/invoices/create');
            },
            enterEditDialog: function enterEditDialog(model) {
                this.send('openModal', 'user/invoices/edit', model, 'user/invoices/edit');
            },
            enterDuplicateDialog: function enterDuplicateDialog(model) {
                var invoice = this.store.createRecord('invoice', {
                    client: model.get('client'),
                    branch: model.get('branch'),
                    invoicingCompany: model.get('invoicingCompany')
                });

                model.get('items').forEach(function (item) {
                    invoice.get('items').createRecord(item.toJSON());
                });

                this.send('openModal', 'user/invoices/edit', invoice, 'user/invoices/create');
            },
            enterReminderDialog: function enterReminderDialog(model) {
                this.send('openModal', 'user/invoices/reminder', model, 'user/invoices/reminder');
            },
            enterPayDialog: function enterPayDialog(invoice) {
                this.send('openModal', 'user/invoices/pay', invoice.get('payments').createRecord({
                    note: 'Ručná platba',
                    date: (0, _moment.default)().format('YYYY-MM-DD')
                }), 'user/invoices/pay');
            },
            openPaymentsDialog: function openPaymentsDialog(invoice) {
                this.send('openModal', 'user/invoices/payments', invoice, 'user/invoices/payments');
            },
            enterCreateCreditNoteDialog: function enterCreateCreditNoteDialog(model) {
                var creditNote = this.store.createRecord('credit-note', {
                    client: model.get('client'),
                    branch: model.get('branch'),
                    invoice: model,
                    invoicingCompany: model.get('invoicingCompany')
                });

                model.get('items').forEach(function (item) {
                    item = creditNote.get('items').createRecord(item.toJSON());

                    item.set('price', -1 * item.get('price'));
                });

                this.send('openModal', 'user/credit-notes/edit', creditNote, 'user/credit-notes/create');
            },
            startCollection: function startCollection(model) {
                model.set('collection', true);
                model.save();
            },
            endCollection: function endCollection(model) {
                model.set('collection', false);
                model.save();
            }
        }
    });
});