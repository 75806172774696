define('crm/components/section-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'header',
        classNames: ['section-header'],

        hasFilter: true
    });
});