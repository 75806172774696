define('crm/components/month-year-picker', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['date-month-picker'],

        startMonth: (0, _moment.default)().format('M') - 1,
        startYear: (0, _moment.default)().format('YYYY'),

        months: _moment.default.months(),

        labels: true,
        inline: false
    });
});