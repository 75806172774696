define('crm/helpers/sum', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.sum = sum;
    var helper = Ember.Helper.helper,
        get = Ember.get;
    function sum(params) {
        var sum = 0;

        params[0].forEach(function (item) {
            sum += get(item, params[1]);
        });

        return sum;
    }

    exports.default = helper(sum);
});