define('crm/components/task-files', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        autoUpload: true,

        onUpload: function onUpload() {},
        onUploadAll: function onUploadAll() {},
        onRemove: function onRemove() {}
    });
});