define('crm/macros/depends', ['exports', 'ember-macro-helpers/create-class-computed', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _createClassComputed, _computed, _normalizeArrayKey) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _createClassComputed.default)([false, true], function (array, key) {
        return (0, _computed.default)((0, _normalizeArrayKey.default)(array, key), function (array) {
            return array;
        });
    });
});