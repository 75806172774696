define('crm/components/form-wrapper', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component,
        get = Ember.get;
    exports.default = Component.extend({
        tagName: 'form',
        rules: {
            ignore: []
        },

        onSuccess: function onSuccess() {},
        submit: function submit(e) {
            if (this.$().valid()) {
                this.events.publish('form-wrapper:submit');

                get(this, 'onSuccess')();
            }

            e.preventDefault();
        },
        didInsertElement: function didInsertElement() {
            this.$().validate(get(this, 'rules'));
        }
    });
});