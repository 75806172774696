define('crm/components/full-calendar', ['exports', 'ember-fullcalendar/components/full-calendar'], function (exports, _fullCalendar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fullCalendar.default;
    }
  });
});