define('crm/helpers/date', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Helper = Ember.Helper;
    exports.default = Helper.extend({
        compute: function compute(params) {
            var format = params[1] ? params[1] : 'L';

            if (params[0]) {
                return (0, _moment.default)(params[0]).format(format);
            } else {
                return '-';
            }
        }
    });
});