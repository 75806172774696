define('crm/components/truncate-text', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var TruncateTextComponent = Ember.Component.extend({
        classNames: ['truncate-text'],

        title: null,
        text: null,

        didInsertElement: function didInsertElement() {
            this.$('[data-toggle="popover"]').popover({
                placement: 'bottom',
                trigger: 'click hover'
            });
        }
    });

    TruncateTextComponent.reopenClass({
        positionalParams: ['text']
    });

    exports.default = TruncateTextComponent;
});