define('crm/components/data-filter/filters/check', ['exports', 'crm/mixins/component/filter'], function (exports, _filter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_filter.default, {
        isChecked: false,
        unchecked: true,

        getFilteringCondition: function getFilteringCondition() {
            var isChecked = this.get('isChecked'),
                unchecked = this.get('unchecked');

            if (isChecked || !isChecked && unchecked) {
                return {
                    property: this.get('name'),
                    value: isChecked,
                    operator: 'bool'
                };
            }
        },
        clearFilter: function clearFilter() {
            this.set('isChecked', false);
        }
    });
});