define('crm/components/task-fields', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed,
        alias = Ember.computed.alias;
    exports.default = Ember.Component.extend({
        tagName: '',

        entities: Ember.inject.service(),
        fields: alias('entities.fields'),

        visibleFields: computed('task.type', 'fields.@each.taskType', function () {
            var fields = get(this, 'fields'),
                taskType = get(this, 'task.type');

            return fields.filter(function (field) {
                return get(field, 'taskType') === taskType;
            });
        }),

        didInsertElement: function didInsertElement() {
            var task = get(this, 'task'),
                fields = get(this, 'fields'),
                taskFields = get(task, 'fields');

            fields.forEach(function (field) {
                var taskField = taskFields.findBy('field.id', get(field, 'id')) || get(task, 'fields').createRecord({
                    field: field
                });

                set(field, 'taskField', taskField);
            });
        }
    });
});