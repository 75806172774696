define('crm/components/data-filter/filters/range', ['exports', 'crm/mixins/component/filter'], function (exports, _filter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        oneWay = Ember.computed.oneWay;
    exports.default = Ember.Component.extend(_filter.default, {
        classNames: ['filter-range'],

        // Range
        min: 0,
        max: 0,

        // Initial values
        from: oneWay('min'),
        to: oneWay('max'),

        getFilteringCondition: function getFilteringCondition() {
            var from = get(this, 'from'),
                to = get(this, 'to');

            if (from || to) {
                return {
                    property: get(this, 'name'),
                    value: [from, to],
                    operator: 'range'
                };
            }
        },
        clearFilter: function clearFilter() {
            set(this, 'from', get(this, 'min'));
            set(this, 'to', get(this, 'max'));
        }
    });
});