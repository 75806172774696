define('crm/services/current-user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        alias = _Ember$computed.alias,
        sort = _Ember$computed.sort,
        service = Ember.inject.service,
        classify = Ember.String.classify,
        Service = Ember.Service;
    exports.default = Service.extend({
        store: service(),

        user: null,
        contractConditionsSorting: ['sortId'],

        // Shortcuts
        id: alias('user.id'),
        isSuperadmin: alias('user.isSuperadmin'),

        contractConditions: computed(function () {
            return get(this, 'store').peekAll('contract-condition');
        }),
        sortedConditions: sort('contractConditions', 'contractConditionsSorting'),
        acceptedConditions: computed('sortedConditions.@each.isAccepted', function () {
            return get(this, 'sortedConditions').filterBy('isAccepted', true);
        }),
        unacceptedConditions: computed('sortedConditions.@each.isAccepted', function () {
            return get(this, 'sortedConditions').filterBy('isAccepted', false);
        }),

        hasAccessTo: function hasAccessTo(section) {
            if (get(this, 'isSuperadmin')) {
                return true;
            } else {
                return get(this, 'user.role.access' + classify(section[0]));
            }
        }
    });
});