define('crm/models/gdpr', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;


    var PURPOSES = 'Spr\xE1va web str\xE1nky prev\xE1dzkovate\u013Ea\nTvorba reklamn\xFDch a propaga\u010Dn\xFDch predmetov, materi\xE1lov a tla\u010Dov\xEDn\nSpr\xE1va soci\xE1lnych siet\xED prev\xE1dzkovate\u013Ea\nSpr\xE1va online syst\xE9mov pod\u013Ea pokynov prev\xE1dzkovate\u013Ea\nTvorba marketingov\xFDch kampan\xED pre marketingov\xE9 \xFA\u010Dely prev\xE1dzkovate\u013Ea\nFotografick\xE9 slu\u017Eby a slu\u017Eby videoprodukcie\nSpracovanie datab\xE1z z\xE1kazn\xEDckych kontaktov prev\xE1dzkovate\u013Ea\nSpracovanie datab\xE1z zamestnancov alebo zmluvn\xFDch partnerov prev\xE1dzkovate\u013Ea\nZastupovanie prev\xE1dzkovate\u013Ea pri organiz\xE1cii eventov\nZastupovanie prev\xE1dzkovate\u013Ea pri koordin\xE1cii extern\xFDch dod\xE1vate\u013Eov';

    var FIELDS = 'Meno, priezvisko, titul, adresa, poschodie, e-mail, tel. \u010D\xEDslo, IP adresa, pracovn\xE9 zaradenie, fotografia, videoz\xE1znam, elektronick\xE1 komunik\xE1cia, zvukov\xFD z\xE1znam.';

    var PEOPLE = 'Klienti prev\xE1dzkovate\u013Ea, zamestnanci prev\xE1dzkovate\u013Ea, zmluvn\xED partneri prev\xE1dzkovate\u013Ea';

    exports.default = _base.default.extend({
        purposes: attr('string', {
            defaultValue: PURPOSES
        }),
        fields: attr('string', {
            defaultValue: FIELDS
        }),
        people: attr('string', {
            defaultValue: PEOPLE
        }),
        client: belongsTo('client')
    });
});