define('crm/controllers/user/work-log/calendar', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        htmlSafe = Ember.String.htmlSafe;
    exports.default = Ember.Controller.extend({
        title: computed('currentMonth', function () {
            var currentMonth = get(this, 'currentMonth');

            return htmlSafe('Report <small>(' + currentMonth.format('MM.YYYY') + ')</small>');
        }),

        currentMonth: computed('model.date', function () {
            return (0, _moment.default)(get(this, 'model.date')).startOf('month');
        }),
        prevMonth: computed('currentMonth', function () {
            return get(this, 'currentMonth').clone().subtract(1, 'month');
        }),
        nextMonth: computed('currentMonth', function () {
            return get(this, 'currentMonth').clone().add(1, 'month');
        }),

        days: computed('model.report.days.[]', function () {
            return get(this, 'model.report.days').map(function (day) {
                return (0, _moment.default)(day, 'DD.MM.YYYY');
            });
        }),
        rows: computed('entities.employedUsers.[]', 'model.report.users.[]', function () {
            var users = get(this.entities, 'employedUsers'),
                data = get(this, 'model.report.users'),
                rows = [];

            users.forEach(function (user) {
                var userReport = data[get(user, 'id')];

                if (userReport) {
                    rows.addObject({
                        user: user,
                        days: userReport,
                        total: userReport.reduce(function (a, b) {
                            return a + b;
                        }, 0)
                    });
                }
            });

            return rows;
        }),

        goToDate: function goToDate(date) {
            this.send('reload', date);
        }
    });
});