define('crm/models/task-todo', ['exports', 'ember-data', 'crm/mixins/model/copyable'], function (exports, _emberData, _copyable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_copyable.default, {
        summary: _emberData.default.attr('string'),
        done: _emberData.default.attr('boolean'),
        task: _emberData.default.belongsTo('task')
    });
});