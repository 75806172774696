define('crm/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'crm/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _oauth2PasswordGrant.default.extend({
        serverTokenEndpoint: _environment.default.api.host + '/oauth/token',

        makeRequest: function makeRequest(url, data) {
            data.client_id = _environment.default.api.clientId;
            data.client_secret = _environment.default.api.clientSecret;

            return this._super(url, data);
        }
    });
});