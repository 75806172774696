define('crm/components/compare-statistics', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        value: null,
        compareTo: null,

        difference: Ember.computed('value', 'compareTo', function () {
            return this.get('value') - this.get('compareTo');
        }),
        color: Ember.computed('difference', function () {
            var difference = this.get('difference');

            if (difference === 0) {
                return 'text-primary';
            } else if (difference < 0) {
                return 'text-danger';
            } else {
                return 'text-success';
            }
        }),
        icon: Ember.computed('difference', function () {
            var difference = this.get('difference');

            if (difference === 0) {
                return 'fa fa-minus ml-2 mr-1';
            } else if (difference < 0) {
                return 'fa fa-caret-down ml-2 mr-1';
            } else {
                return 'fa fa-caret-up ml-2 mr-1';
            }
        })
    });
});