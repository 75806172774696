define('crm/controllers/user/attendance/manage', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        service = Ember.inject.service,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        oneWay = _Ember$computed.oneWay,
        alias = _Ember$computed.alias;
    exports.default = Ember.Controller.extend(_modalController.default, {
        attendance: service(),
        entities: service(),
        clients: alias('entities.clients'),

        user: oneWay('currentUser.user'),
        now: new Date(),

        absents: computed('attendance.attendance.@each.{isNew,isAbsence,isPast}', function () {
            return get(this, 'attendance.attendance').filterBy('isNew', false).filterBy('isAbsence', true).filterBy('isPast', false);
        })
    });
});