define('crm/components/invoice-state', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        color: computed('state', function () {
            var state = get(this, 'state');

            switch (state) {
                case 0:
                    {
                        return '#636c72';
                    }

                case 1:
                    {
                        return '#d9534f';
                    }

                case 2:
                    {
                        return '#5bc0de';
                    }

                case 3:
                    {
                        return '#5cb85c';
                    }

                case 4:
                    {
                        return '#613d7c';
                    }

                case 5:
                    {
                        return '#f0ad4e';
                    }
            }
        }),

        stateLabel: computed('state', function () {
            var state = get(this, 'state');

            switch (state) {
                case 0:
                    {
                        return 'Nová';
                    }

                case 1:
                    {
                        return 'Neuhradená';
                    }

                case 2:
                    {
                        return 'Čiastočne uhradená';
                    }

                case 3:
                    {
                        return 'Uhradená';
                    }

                case 4:
                    {
                        return 'Uhradená po splatnosti';
                    }

                case 5:
                    {
                        return 'Pred splatnosťou';
                    }
            }
        })
    });
});