define('crm/controllers/user/clients/regularly-invoiced-items', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Pravidelne fakturované položky',

        addItem: function addItem() {
            this.get('model.regularlyInvoicedItems').createRecord();
        },
        removeItem: function removeItem(item) {
            item.destroyRecord();
        },
        saved: function saved() {
            this.get('toasts').success('Položky boli uložené.');
        }
    });
});