define('crm/controllers/user/fields', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            enterAddFieldDialog: function enterAddFieldDialog() {
                this.send('openModal', 'user/fields/edit', this.store.createRecord('field'), 'user/fields/add');
            },
            enterEditFieldDialog: function enterEditFieldDialog(field) {
                this.send('openModal', 'user/fields/edit', field, 'user/fields/edit');
            },
            deleteField: function deleteField(field) {
                field.destroyRecord();
            }
        }
    });
});