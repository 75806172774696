define('crm/controllers/user/wages/bills-calculator', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var mapBy = Ember.computed.mapBy;
    exports.default = Ember.Controller.extend(_modalController.default, {
        amounts: mapBy('model', 'salaryInCash')
    });
});