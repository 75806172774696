define('crm/models/report-summary', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        report: _emberData.default.belongsTo('report'),

        graphicalHours: _emberData.default.attr('float'),
        extraGraphicalHours: _emberData.default.attr('float'),

        socialNetworksHours: _emberData.default.attr('float'),
        extraSocialNetworksHours: _emberData.default.attr('float'),

        developmentHours: _emberData.default.attr('float'),
        extraDevelopmentHours: _emberData.default.attr('float'),

        newsletterHours: _emberData.default.attr('float'),
        extraNewsletterHours: _emberData.default.attr('float'),

        campaignManagementHours: _emberData.default.attr('float'),
        extraCampaignManagementHours: _emberData.default.attr('float'),

        otherHours: _emberData.default.attr('float'),

        administrativeHours: _emberData.default.attr('float'),
        communicationHours: _emberData.default.attr('float'),

        departures: _emberData.default.attr('number'),
        extraDepartures: _emberData.default.attr('number'),

        stockPhotos: _emberData.default.attr('number'),
        extraStockPhotos: _emberData.default.attr('number'),

        boostsPrice: _emberData.default.attr('float'),

        sumHours: _emberData.default.attr('float'),
        extraHours: _emberData.default.attr('float'),

        extraWorkPrice: _emberData.default.attr('float', {
            defaultValue: 0
        }),
        itemsPrice: _emberData.default.attr('float'),
        goodsPrice: _emberData.default.attr('float')
    });
});