define('crm/helpers/sort-by', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.sortBy = sortBy;
    function sortBy(params /*, hash*/) {
        var model = params[0],
            property = params[1],
            descending = params[2] || false;

        var sorted = model.sortBy(property);

        if (descending) {
            sorted = sorted.reverse();
        }

        return sorted;
    }

    exports.default = Ember.Helper.helper(sortBy);
});