define('crm/controllers/user/cards', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        states: {
            0: 'Povolený',
            1: 'Zakázaný'
        }
    });
});