define('crm/components/data-grid-toggle-selection', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Checkbox.extend({
        selection: null,

        change: function change(e) {
            this._super(e);

            var checked = this.get('checked');
            this.get('selection').forEach(function (row) {
                row.set('selected', checked);
            });
        }
    });
});