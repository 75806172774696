define('crm/models/lead-analysis/question', ['exports', 'ember-data', 'crm/mixins/model/persist-relationships'], function (exports, _emberData, _persistRelationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_persistRelationships.default, {
        question: _emberData.default.attr('string'),
        required: _emberData.default.attr('boolean', {
            defaultValue: true
        }),
        type: _emberData.default.attr('number'),
        min: _emberData.default.attr('number'),
        max: _emberData.default.attr('number'),
        note: _emberData.default.attr('boolean'),

        options: _emberData.default.hasMany('lead-analysis/question-option', {
            persist: true
        }),

        supportsOptions: Ember.computed('type', function () {
            var type = this.get('type');

            return type && type !== 1;
        }),
        supportsMultipleOptions: Ember.computed.equal('type', 3)
    });
});