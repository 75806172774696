define('crm/controllers/user/users/add', ['exports', 'crm/mixins/controller/modal-controller', 'crm/config/environment'], function (exports, _modalController, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        service = Ember.inject.service,
        alias = Ember.computed.alias;
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Pridať používateľa',

        entities: service(),
        roles: alias('entities.roles'),
        teams: alias('entities.teams'),
        workingTimeTypes: _environment.default.workingTimeTypes,
        healthInsurances: _environment.default.healthInsurances,

        addTeam: function addTeam(team) {
            get(this, 'model.teams').addObject(team);
        },
        removeTeam: function removeTeam(team) {
            get(this, 'model.teams').removeObject(team);
        },
        saved: function saved() {
            get(this, 'toasts').success('Používateľ bol vytvorený.');
        }
    });
});