define('crm/mixins/serializers/files-serializer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Mixin = Ember.Mixin;
    exports.default = Mixin.create({
        attrs: {
            files: {
                serialize: 'ids',
                deserialize: 'records'
            }
        }
    });
});