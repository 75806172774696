define('crm/components/task-todos', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        summary: null,
        autoSave: false,
        addFormVisible: false,

        onRemove: function onRemove() {},
        onAdd: function onAdd() {},


        status: Ember.computed('todos.@each.done', function () {
            var done = this.get('todos').filterBy('done', true).get('length'),
                count = this.get('todos').get('length');

            return count > 0 ? '(' + done + ' / ' + count + ')' : null;
        }),

        addTodo: function addTodo() {
            var summary = this.get('summary');

            if (summary) {
                this.get('onAdd')(summary);

                this.set('summary', null);
            }
        },
        showAddForm: function showAddForm() {
            this.set('addFormVisible', true);
        },
        keyPress: function keyPress(e) {
            if (e.keyCode === 13) {
                this.addTodo();

                e.preventDefault();
            }
        }
    });
});