define('crm/components/data-filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        _Ember$run = Ember.run,
        schedule = _Ember$run.schedule,
        debounce = _Ember$run.debounce,
        computed = Ember.computed,
        A = Ember.A;
    exports.default = Ember.Component.extend({
        classNames: ['filter'],
        initialConditions: [],

        isFiltering: computed('conditions.[]', function () {
            return get(this, 'conditions.length') > 0;
        }),

        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'filters', A([]));
            set(this, 'conditions', A([]));
        },
        didInsertElement: function didInsertElement() {
            this.applyInitialConditions();
        },
        didRender: function didRender() {
            this.initializeFilters();
        },
        initializeFilters: function initializeFilters() {
            var $filters = this.$().find('[name]');

            $filters.off('keyup change').on('keyup change', function (event) {
                if (event.type === 'change') {
                    schedule('afterRender', this, this.updateFilters);
                } else {
                    debounce(this, this.updateFilters, 250);
                }
            }.bind(this));
        },
        applyInitialConditions: function applyInitialConditions() {
            var _this = this;

            var initialConditions = get(this, 'initialConditions');

            if (initialConditions) {
                set(this, 'conditions', initialConditions);

                initialConditions.forEach(function (condition) {
                    var filter = get(_this, 'filters').findBy('name', condition.property);

                    if (filter) {
                        filter.setFilteringCondition(condition);
                    }
                });

                this.triggerFilter();
            }
        },
        updateFilters: function updateFilters() {
            var conditions = get(this, 'conditions').clear();

            get(this, 'filters').forEach(function (filter) {
                var condition = filter.getFilteringCondition();

                if (condition) {
                    conditions.addObject(condition);
                }
            });

            set(this, 'conditions', conditions);

            this.triggerFilter();
        },
        triggerFilter: function triggerFilter() {
            this.events.publish('data-grid:filter', get(this, 'conditions'));
        },
        clearFilters: function clearFilters() {
            get(this, 'conditions').clear();

            get(this, 'filters').forEach(function (filter) {
                filter.clearFilter();
            });

            this.events.publish('data-grid:filter', get(this, 'conditions'));
        },
        addFilter: function addFilter(filter) {
            get(this, 'filters').addObject(filter);
        },
        removeFilter: function removeFilter(filter) {
            get(this, 'filters').removeObject(filter);
        }
    });
});