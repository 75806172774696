define('crm/components/date-input', ['exports', 'moment', 'crm/components/date-picker'], function (exports, _moment, _datePicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      isArray = Ember.isArray,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      oneWay = _Ember$computed.oneWay,
      not = _Ember$computed.not;


  var DATE_FORMAT = 'YYYY-MM-DD',
      DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

  exports.default = _datePicker.default.extend({
    tagName: 'input',
    type: 'text',
    classNames: ['form-control', 'form-control-datepicker'],
    attributeBindings: ['name', 'required', 'disabled', 'readonly', 'placeholder'],
    readonly: true,
    targetElement: null,

    selected: oneWay('value'),
    clearButton: not('required'),

    select: function select(formattedDate, date) {
      var value = false;

      if (get(this, 'range')) {
        if (isArray(date)) {
          var dates = date.map(function (date) {
            return (0, _moment.default)(date).format(DATE_FORMAT);
          });

          get(this, 'onChange')(dates);

          value = dates.join(' - ');
        }
      } else {
        if (_moment.default.isDate(date)) {
          date = (0, _moment.default)(date);

          get(this, 'onChange')(date);

          value = date.format(get(this, 'time') ? DATETIME_FORMAT : DATE_FORMAT);
        } else {
          get(this, 'onChange')(null);

          value = null;
        }
      }

      if (value !== false) {
        set(this, 'value', value);
      }

      this.$().trigger('change');
    }
  });
});