define('crm/utils/arrays', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var A = Ember.A,
        get = Ember.get,
        set = Ember.set,
        isPresent = Ember.isPresent,
        compare = Ember.compare;
    var Group = exports.Group = Ember.Object.extend({
        key: null,
        value: null,
        computed: true,
        saved: true,

        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'items', A());
        },
        serialize: function serialize() {
            return {
                key: get(this, 'key'),
                value: get(this, 'value')
            };
        }
    });

    var groupBy = exports.groupBy = function groupBy(collection, _groupBy) {
        var groups = new A();

        collection.forEach(function (item) {
            if (_groupBy === null) {
                return false;
            }

            var value = get(item, _groupBy);
            var group = groups.findBy('value', value);

            if (!isPresent(group)) {
                group = Group.create({
                    key: _groupBy,
                    value: value
                });

                groups.push(group);
            }

            get(group, 'items').push(item);
        });

        return groups;
    };

    var groupByMultiple = exports.groupByMultiple = function groupByMultiple(collection) {
        var groupByDefinitions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

        var groups = new A();

        groupByDefinitions.map(function (groupByDefinition) {
            var key = get(groupByDefinition, 'key'),
                value = get(groupByDefinition, 'value');

            var groupedCollection = groupBy(collection, key);
            var group = groupedCollection.findBy('value', value);

            if (isPresent(group)) {
                set(groupByDefinition, 'items', get(group, 'items'));
            } else {
                get(groupByDefinition, 'items').clear();
            }

            set(groupByDefinition, 'computed', false);

            groups.push(groupByDefinition);
        });

        return groups;
    };

    var groupByAsObject = exports.groupByAsObject = function groupByAsObject(collection, groupBy) {
        var groups = {};

        collection.forEach(function (item) {
            if (groupBy === null) {
                return false;
            }
            var value = get(item, groupBy);
            var group = groups[value];

            if (!isPresent(group)) {
                groups[value] = [];
            }

            groups[value].push(item);
        });

        return groups;
    };

    var sortBy = exports.sortBy = function sortBy(collection, normalizedSortProperties) {
        if (normalizedSortProperties.length === 0) {
            return collection;
        }

        return A(collection.slice().sort(function (itemA, itemB) {
            for (var i = 0; i < normalizedSortProperties.length; i++) {
                var _normalizedSortProper = _slicedToArray(normalizedSortProperties[i], 2),
                    prop = _normalizedSortProper[0],
                    direction = _normalizedSortProper[1];

                var result = compare(get(itemA, prop), get(itemB, prop));

                if (result !== 0) {
                    return direction === 'desc' ? -1 * result : result;
                }
            }

            return 0;
        }));
    };
});