define('crm/models/report', ['exports', 'ember-data', 'crm/models/base', 'crm/mixins/model/persist-relationships', 'crm/utils/numbers', 'moment'], function (exports, _emberData, _base, _persistRelationships, _numbers, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_persistRelationships.default, {
        client: _emberData.default.belongsTo('client'),
        items: _emberData.default.hasMany('report-item', {
            persist: true
        }),
        summary: _emberData.default.belongsTo('report-summary'),
        dateFrom: _emberData.default.attr('date'),
        dateTo: _emberData.default.attr('date'),
        number: _emberData.default.attr('number'),

        dateGroupingValue: Ember.computed('dateFrom', function () {
            return (0, _moment.default)(this.get('dateFrom')).format('MMMM YYYY');
        }),

        invoices: [],
        completed: false,
        sent: false,

        itemsPrice: Ember.computed('items.@each.{price,type}', function () {
            var sum = 0;

            this.get('items').rejectBy('type', 2).forEach(function (item) {
                sum += (0, _numbers.toFloat)(item.get('price') || 0);
            });

            return (0, _numbers.toFloat)(sum);
        }),
        goodsPrice: Ember.computed('items.@each.{price,type}', function () {
            var sum = 0;

            this.get('items').filterBy('type', 2).forEach(function (item) {
                sum += (0, _numbers.toFloat)(item.get('price') || 0);
            });

            return (0, _numbers.toFloat)(sum);
        })
    });
});