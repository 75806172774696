define('crm/models/user', ['exports', 'ember-data', 'crm/models/base', 'moment', 'crm/config/environment', 'crm/utils/numbers'], function (exports, _emberData, _base, _moment, _environment, _numbers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var get = Ember.get,
      computed = Ember.computed,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      filterBy = _Ember$computed.filterBy,
      mapBy = _Ember$computed.mapBy,
      notEmpty = _Ember$computed.notEmpty;
  exports.default = _base.default.extend({
    name: attr('string'),
    email: attr('string'),
    phone: attr('string'),
    password: attr('string'),
    suspended: attr('boolean'),
    position: attr('string'),
    picture: attr('string'),
    superadmin: attr('boolean'),

    // Employment Contract
    employee: attr('boolean'),
    workType: attr('number'),
    salary: attr('float', {
      defaultValue: 0
    }),
    officialSalary: attr('float', {
      defaultValue: 0
    }),
    computedSalary: attr('float'),
    startDate: attr('date'),
    startDateReal: attr('date'),
    endDate: attr('date'),

    workDays: attr('number'),
    paidDays: attr('number'),

    address: attr('string'),
    birthDate: attr('date'),
    idNumber: attr('string'),
    idCard: attr('string'),
    iban: attr('string'),
    healthInsurance: attr('number'),

    createdAt: attr('datetime'),

    notificationsDailyTasks: attr('boolean', {
      defaultValue: true
    }),
    notificationsComments: attr('boolean', {
      defaultValue: true
    }),
    disableStartDayDialog: attr('boolean', {
      defaultValue: false
    }),
    addWorkLogFollowup: attr('boolean', {
      defaultValue: true
    }),
    notificationsTeamMemberNewTask: attr('boolean', {
      defaultValue: false
    }),

    // Relationships
    role: belongsTo('role'),
    workLogs: hasMany('workLog'),
    teams: hasMany('team', {
      inverse: 'users'
    }),
    managingTeams: hasMany('team', {
      inverse: 'teamLeader'
    }),
    salesUnits: hasMany('sales-unit', {
      inverse: 'members'
    }),
    attendance: hasMany('attendance', {
      inverse: 'user'
    }),
    commissions: hasMany('commission'),
    commissionDiscounts: hasMany('commission-discount'),
    salaryDiscounts: hasMany('salary-discount'),
    notifications: hasMany('notification'),
    cards: hasMany('card'),
    accountingClients: hasMany('client', {
      inverse: 'accountManager'
    }),

    discounts: filterBy('salaryDiscounts', 'type', 1),
    bonuses: filterBy('salaryDiscounts', 'type', 2),
    bankTransfers: filterBy('salaryDiscounts', 'type', 3),

    discountsTotalAmount: computed('discounts.@each.amount', function () {
      return (0, _numbers.toFloat)(get(this, 'discounts').mapBy('amount').reduce(function (a, b) {
        return a + b;
      }, 0) * -1);
    }),
    bonusesTotalAmount: computed('bonuses.@each.amount', function () {
      return (0, _numbers.toFloat)(get(this, 'bonuses').mapBy('amount').reduce(function (a, b) {
        return a + b;
      }, 0));
    }),
    bankTransfersTotalAmount: computed('bankTransfers.@each.amount', function () {
      return (0, _numbers.toFloat)(get(this, 'bankTransfers').mapBy('amount').reduce(function (a, b) {
        return a + b;
      }, 0));
    }),
    discountedSalary: computed('computedSalary', 'discountsTotalAmount', 'bonusesTotalAmount', function () {
      return (0, _numbers.toFloat)(get(this, 'computedSalary') + get(this, 'discountsTotalAmount') + get(this, 'bonusesTotalAmount'));
    }),
    salaryInCash: computed('discountedSalary', 'bankTransfersTotalAmount', function () {
      return Math.ceil((0, _numbers.toFloat)(get(this, 'discountedSalary') - get(this, 'bankTransfersTotalAmount')));
    }),

    salesUnit: alias('salesUnits.firstObject'),

    isSuperadmin: computed('superadmin', function () {
      return get(this, 'superadmin');
    }),
    isSuspended: alias('suspended'),
    isActive: computed('suspended', function () {
      return !get(this, 'suspended');
    }),
    isInTeam: computed('teams.@each', function () {
      return get(this, 'teams.length') > 0;
    }),
    isEmployed: computed('onsetDate', 'quitDate', 'isInTeam', function () {
      return get(this, 'isInTeam');
      var onsetDate = get(this, 'onsetDate'),
          quitDate = get(this, 'quitDate');

      if (!onsetDate) {
        return false;
      }

      var today = (0, _moment.default)();

      if (quitDate) {
        quitDate = (0, _moment.default)(quitDate);

        return today.diff(quitDate, 'days') > 0 ? false : true;
      }

      onsetDate = (0, _moment.default)(onsetDate);

      return today.diff(onsetDate, 'days') >= 0;
    }),
    isTeamLeader: notEmpty('managingTeams'),
    isAccountManager: notEmpty('accountingClients'),

    firstName: computed('name', function () {
      return get(this, 'name').split(' ').shift();
    }),
    state: computed('isSuspended', function () {
      return get(this, 'isSuspended') ? 1 : 0;
    }),
    textWorkType: computed('workType', function () {
      return _environment.default.workingTimeTypes[get(this, 'workType')];
    }),
    teamIds: mapBy('teams', 'id'),

    monthlyCommissions: computed('commissions', function () {
      var sum = 0;

      get(this, 'commissions').forEach(function (commission) {
        sum += get(commission, 'firstYearComputed');
      });

      return sum;
    }),

    roleName: alias('role.name')
  });
});