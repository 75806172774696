define('crm/helpers/input-name', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.inputName = inputName;
    var helper = Ember.Helper.helper;
    function inputName(params) {
        return params[1] + '[' + params[0] + ']';
    }

    exports.default = helper(inputName);
});