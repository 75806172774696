define('crm/controllers/user/daily-tasks', ['exports', 'crm/config/environment', 'moment'], function (exports, _environment, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        entities: Ember.inject.service(),

        title: 'Denné úlohy',
        today: (0, _moment.default)(),
        statuses: _environment.default.taskStatuses,
        types: _environment.default.taskTypes,
        showTeamsTasks: false,

        defaultFilters: [{
            property: 'status',
            operator: 'in',
            value: [0, 1, 3]
        }],

        user: Ember.computed.alias('currentUser.user'),
        currentDay: Ember.computed.alias('model.date'),

        isToday: Ember.computed('currentDay', function () {
            return (0, _moment.default)().isSame(this.get('currentDay'), 'day');
        }),

        yesterday: Ember.computed('currentDay', function () {
            return this.get('currentDay').clone().subtract(1, 'day');
        }),
        tomorrow: Ember.computed('currentDay', function () {
            return this.get('currentDay').clone().add(1, 'day');
        }),

        actions: {
            enterEditDialog: function enterEditDialog(model) {
                this.send('openModal', 'user/tasks/edit', model, 'user/tasks/edit');
            },
            enterDetailDialog: function enterDetailDialog(model) {
                this.send('openModal', 'user/tasks/detail', model, 'user/tasks/detail');
            }
        }
    });
});