define('crm/models/notification', ['exports', 'ember-data', 'crm/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = _base.default.extend({
        type: attr('string'),
        payload: attr(),
        readAt: attr('datetime'),
        createdAt: attr('datetime'),

        date: computed('createdAt', function () {
            return (0, _moment.default)(get(this, 'createdAt')).format('L');
        })
    });
});