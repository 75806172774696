define('crm/models/employee', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        name: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string'),
        position: _emberData.default.attr('string'),
        sendInvoiceNotification: _emberData.default.attr('boolean'),
        sendGraphicWorktimeExceededNotification: _emberData.default.attr('boolean'),
        sendSmsReminder: _emberData.default.attr('boolean'),
        sendEmailReminder: _emberData.default.attr('boolean'),

        client: _emberData.default.belongsTo('client'),

        isReminderRecipient: Ember.computed('sendSmsReminder', 'sendEmailReminder', function () {
            return this.get('sendSmsReminder') || this.get('sendEmailReminder');
        })
    });
});