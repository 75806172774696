define('crm/components/data-filter/filters/input', ['exports', 'crm/components/delayed-input', 'crm/mixins/component/filter'], function (exports, _delayedInput, _filter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set;
    exports.default = _delayedInput.default.extend(_filter.default, {
        getFilteringCondition: function getFilteringCondition() {
            var value = get(this, 'value');

            if (value) {
                return {
                    property: this.get('name'),
                    value: this.get('value'),
                    operator: 'like'
                };
            }
        },
        clearFilter: function clearFilter() {
            set(this, 'value', null);
        }
    });
});