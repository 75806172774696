define('crm/models/sales-unit', ['exports', 'ember-data', 'crm/mixins/model/persist-relationships'], function (exports, _emberData, _persistRelationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_persistRelationships.default, {
        name: _emberData.default.attr('string'),
        manager: _emberData.default.belongsTo('user'),
        members: _emberData.default.hasMany('user'),
        rewards: _emberData.default.hasMany('reward', {
            persist: true
        })
    });
});