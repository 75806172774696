define('crm/helpers/or', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.or = or;
  var helper = Ember.Helper.helper;
  function or(params) {
    return params[0] || params[1];
  }

  exports.default = helper(or);
});