define('crm/components/image-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var set = Ember.set;
    exports.default = Ember.TextField.extend({
        tagName: 'input',
        type: 'file',
        classNames: ['form-control'],

        change: function change() {
            var reader = new FileReader(),
                file = this.element.files[0];

            reader.onloadend = function () {
                set(this, 'data', reader.result);
            }.bind(this);

            reader.readAsDataURL(file);
        }
    });
});