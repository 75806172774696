define('crm/controllers/user/fields/add', ['exports', 'crm/mixins/controller/modal-controller', 'crm/config/environment'], function (exports, _modalController, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Pridať atribút',

        taskTypes: _environment.default.taskTypes,
        fieldTypes: _environment.default.fieldTypes,

        saved: function saved() {
            get(this, 'toasts').success('Atribút bol uložený.');
        }
    });
});