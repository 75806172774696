define('crm/controllers/user/invoices/payments', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: Ember.computed('model.variableSymbol', function () {
            var variableSymbol = this.get('model.variableSymbol');

            return 'Platby k fakt\xFAre ' + variableSymbol;
        }),

        deletePayment: function deletePayment(payment) {
            if (window.confirm('Naozaj chcete odstrániť tútu platbu?')) {
                payment.destroyRecord();
            }
        }
    });
});