define('crm/mixins/component/filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        Mixin = Ember.Mixin;
    exports.default = Mixin.create({
        getFilteringCondition: function getFilteringCondition() {
            return null;
        },
        setFilteringCondition: function setFilteringCondition() {},
        clearFilter: function clearFilter() {},
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            get(this, 'filter').addFilter(this);
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            get(this, 'filter').removeFilter(this);
        }
    });
});