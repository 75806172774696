define('crm/helpers/datetime', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.datetime = datetime;
    var helper = Ember.Helper.helper;
    function datetime(params) {
        var format = params[1] ? params[1] : 'DD.MM.YYYY HH:mm';

        if (params[0]) {
            return (0, _moment.default)(params[0]).format(format);
        } else {
            return '-';
        }
    }

    exports.default = helper(datetime);
});