define('crm/services/calendar', ['exports', 'moment', 'npm:moment-range'], function (exports, _moment, _npmMomentRange) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var moment = _npmMomentRange.default.extendMoment(_moment.default);

    var get = Ember.get,
        computed = Ember.computed,
        service = Ember.inject.service,
        isEmpty = Ember.isEmpty;
    exports.default = Ember.Service.extend({
        store: service(),
        moment: service(),

        init: function init() {
            this._super.apply(this, arguments);

            get(this, 'moment').setLocale('sk');
        },


        holidays: computed(function () {
            return get(this, 'store').peekAll('holiday').map(function (holiday) {
                return moment(get(holiday, 'date'));
            });
        }),

        isWeekend: function isWeekend(date) {
            return moment(date).weekday() === 5 || moment(date).weekday() === 6;
        },
        isHoliday: function isHoliday(date) {
            return !isEmpty(get(this, 'holidays').filter(function (holiday) {
                return moment(date).isSame(holiday, 'day');
            }));
        },
        getWorkingDays: function getWorkingDays(from, to) {
            var _this = this;

            var range = moment.range(moment(from), moment(to)),
                dates = Array.from(range.by('day'));

            return dates.filter(function (date) {
                return !_this.isWeekend(date) && !_this.isHoliday(date);
            });
        }
    });
});