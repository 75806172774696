define('crm/controllers/user/documents/create-folder', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Vytvoriť priečinok',

        saved: function saved() {
            this.get('toasts').success('Priečinok bol vytvorený.');
        }
    });
});