define('crm/controllers/user/teams/add', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Vytvoriť tím',

        entities: Ember.inject.service(),
        teams: Ember.computed.alias('entities.teams'),
        users: Ember.computed.alias('entities.employedUsers'),

        addUser: function addUser(user) {
            this.get('model.users').addObject(user);
        },
        removeUser: function removeUser(user) {
            this.get('model.users').removeObject(user);
        },
        saved: function saved() {
            this.get('toasts').success('Tím bol vytvorený.');
        }
    });
});