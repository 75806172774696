define('crm/components/invoice-items', ['exports', 'crm/utils/numbers'], function (exports, _numbers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['table-responsive'],
        editable: true,

        canRemove: Ember.computed('items.[]', 'editable', function () {
            return this.get('editable') && this.get('items.length') > 1;
        }),
        prices: Ember.computed.mapBy('items', 'price'),
        totalAmount: Ember.computed.sum('prices'),
        totalAmountWithVat: Ember.computed('totalAmount', function () {
            return (0, _numbers.toFloat)(this.get('totalAmount') * 1.2);
        }),

        onAdd: function onAdd() {},
        onRemove: function onRemove() {},
        didInsertElement: function didInsertElement() {
            if (this.get('items.length') === 0) {
                this.get('onAdd')();
            }
        },
        remove: function remove(item) {
            if (confirm('Naozaj si želáte odstrániť túto položku?')) {
                this.get('onRemove')(item);
            }
        }
    });
});