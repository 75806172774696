define('crm/routes/user/wages', ['exports', 'crm/mixins/routes/loading', 'moment'], function (exports, _loading, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;
    exports.default = Ember.Route.extend(_loading.default, {
        month: (0, _moment.default)().month(),
        year: (0, _moment.default)().year(),

        beforeModel: function beforeModel() {
            //return this.store.unloadAll('salary-discount');
        },
        model: function model() {
            var month = get(this, 'month'),
                year = get(this, 'year');

            return Ember.RSVP.hash({
                month: month,
                year: year,

                users: this.store.query('user', {
                    calculateSalaries: {
                        month: month,
                        year: year
                    }
                })
            });
        },


        actions: {
            reloadWages: function reloadWages(data) {
                this.setProperties(data);

                this.refresh();
            }
        }
    });
});