define('crm/initializers/ember-pusher-injections', ['exports', 'ember-pusher/initializers/ember-pusher-injections'], function (exports, _emberPusherInjections) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberPusherInjections.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _emberPusherInjections.initialize;
    }
  });
});