define('crm/components/task-state', ['exports', 'crm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;


    var TASK_COLORS = {
        0: '#FFA000',
        1: '#2196F3',
        2: '#4CAF50',
        3: '#009688'
    };

    exports.default = Ember.Component.extend({
        color: computed('state', function () {
            return TASK_COLORS[get(this, 'state')];
        }),

        label: computed('state', function () {
            return _environment.default.taskStatuses[get(this, 'state')];
        })
    });
});