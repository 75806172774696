define('crm/models/meeting', ['exports', 'ember-data', 'crm/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        _Ember$computed = Ember.computed,
        alias = _Ember$computed.alias,
        not = _Ember$computed.not;
    exports.default = _base.default.extend({
        lead: _emberData.default.belongsTo('lead'),
        date: _emberData.default.attr('datetime'),
        employee: _emberData.default.belongsTo('employee'),
        note: _emberData.default.attr('string'),

        client: alias('lead.client'),
        user: alias('lead.user'),

        isToday: computed('date', function () {
            return (0, _moment.default)(get(this, 'date')).isSame((0, _moment.default)(), 'd');
        }),
        isPast: computed('date', function () {
            return (0, _moment.default)().diff((0, _moment.default)(get(this, 'date')), 'days') > 0;
        }),
        isPresent: not('isPast'),
        isPlanned: computed('date', function () {
            return (0, _moment.default)(get(this, 'date')).diff((0, _moment.default)(), 'days') > 1;
        }),

        day: computed('date', function () {
            var date = get(this, 'date');

            return date ? (0, _moment.default)(date).format('L') : null;
        }),
        groupBy: alias('day')
    });
});