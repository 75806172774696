define('crm/controllers/user/work-log/add-table', ['exports', 'crm/controllers/user/work-log/add'], function (exports, _add) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _add.default.extend({
        title: 'Pridať záznamy o činnosti',
        size: null,
        wizard: null,
        showEmployee: false,

        isGraphical: Ember.computed.equal('type', 1),
        isSocial: Ember.computed.equal('type', 2),
        isDeparture: Ember.computed.equal('type', 3),

        prepare: function prepare() {
            this.setProperties({
                type: null,
                size: null,
                model: Ember.A()
            });
        },
        selectType: function selectType() {
            this.addWorkLog();

            this.set('size', 'modal-full');

            this.get('wizard').next();
        },
        addWorkLog: function addWorkLog() {
            var after = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            var type = this.get('type'),
                workLog = this.store.createRecord('work-log', {
                type: type
            });

            if (after) {
                workLog.setProperties({
                    date: after.get('date')
                });
            }

            this.get('model').pushObject(workLog);
        }
    });
});