define('crm/models/report-item', ['exports', 'ember-data', 'crm/models/base'], function (exports, _emberData, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        report: _emberData.default.belongsTo('report'),
        description: _emberData.default.attr('string'),
        note: _emberData.default.attr('string'),
        price: _emberData.default.attr('float'),
        qty: _emberData.default.attr('float'),
        type: _emberData.default.attr('number'),

        // Reporting
        deliveryNoteItem: null,

        invoiced: Ember.computed('deliveryNoteItem.deliveryNote.invoices.@each', function () {
            return this.get('deliveryNoteItem.deliveryNote.invoices.length') > 0;
        })
    });
});