define('crm/components/task-recurrence-days', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        textDays: ['Po', 'Ut', 'St', 'Št', 'Pi', 'So', 'Ne'],

        days: Ember.computed('task.{recurrence,days.@each}', function () {
            var days = [],
                task = this.get('task'),
                isWeeklyRecurrency = task.get('isRecurrentWeekly'),
                selectedDays = this.get('task.selectedDays');

            for (var i = 0; i < 28; i++) {
                var text = isWeeklyRecurrency ? this.get('textDays')[i % 7] : i + 1 + '.';

                var day = {
                    day: i,
                    text: text,
                    selected: selectedDays.indexOf(i) !== -1
                };

                days.push(day);
            }

            return days;
        }),

        actions: {
            selectDay: function selectDay(selectedDay) {
                var days = this.get('task.days'),
                    selected = days.filterBy('day', selectedDay.day);

                if (!selected.get('length')) {
                    days.createRecord({
                        day: selectedDay.day
                    });
                } else {
                    selected.get('firstObject').destroyRecord();
                }
            }
        }
    });
});