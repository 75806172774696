define('crm/components/sales-unit-goals', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        service = Ember.inject.service,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        store: service(),

        copyFromSalesUnit: null,

        salesUnits: computed(function () {
            return get(this, 'store').peekAll('sales-unit');
        }),

        actions: {
            addReward: function addReward() {
                get(this, 'rewards').createRecord();
            },
            removeReward: function removeReward(reward) {
                reward.destroyRecord();
            },
            copyRewards: function copyRewards() {
                var salesUnit = get(this, 'copyFromSalesUnit'),
                    rewards = get(this, 'rewards');

                if (salesUnit) {
                    get(salesUnit, 'rewards').forEach(function (reward) {
                        rewards.createRecord(reward.toJSON());
                    });
                }
            }
        }
    });
});