define('crm/components/data-pipeline/column', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        alias = Ember.computed.alias,
        Component = Ember.Component,
        schedule = Ember.run.schedule;
    exports.default = Component.extend({
        classNames: ['pipeline-column'],
        classNameBindings: ['columnGroupClass', 'expanded:pipeline-column-expanded'],

        groupBy: null,
        sortBy: null,
        sortItemsBy: alias('sortBy').oneWay(),

        expanded: false,
        panes: null,
        columnGroupClassPrefix: null,

        columnGroupClass: computed('group.{key,value}', 'columnGroupClassPrefix', function () {
            var group = get(this, 'group'),
                prefix = get(this, 'columnGroupClassPrefix');

            return prefix + '-column-' + group.key + '-' + group.value;
        }),

        onAdd: function onAdd() {},
        onRemove: function onRemove() {},
        didInsertElement: function didInsertElement() {
            var group = get(this, 'group');

            if (!get(group, 'saved')) {
                schedule('afterRender', this, function () {
                    //this.toggleOptionsPane();
                });
            }
        },
        toggleOptionsPane: function toggleOptionsPane() {
            if (get(this, 'panes.current') === 1) {
                get(this, 'panes').goTo(2, false);
            } else {
                get(this, 'panes').goTo(1, false);
            }
        },


        actions: {
            toggleOptions: function toggleOptions() {
                this.toggleOptionsPane();
            }
        }
    });
});