define('crm/components/form-wizard', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        A = Ember.A;
    exports.default = Ember.Component.extend({
        classNames: ['form-wizard'],
        current: 1,

        onRegister: function onRegister() {},
        init: function init() {
            this._super.apply(this, arguments);

            set(this, 'steps', A([]));

            get(this, 'onRegister')(this);
        },
        isLast: function isLast() {
            return get(this, 'steps.length') === get(this, 'current');
        },
        next: function next() {
            this.incrementProperty('current');
        },
        prev: function prev() {
            this.decrementProperty('current');
        },
        addStep: function addStep(step) {
            get(this, 'steps').addObject(step);
        },
        removeStep: function removeStep(step) {
            get(this, 'steps').removeObject(step);
        }
    });
});