define('crm/controllers/user/profile/edit', ['exports', 'crm/mixins/controller/modal-controller'], function (exports, _modalController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalController.default, {
        title: 'Váš profil',

        session: Ember.inject.service('session'),
        model: Ember.computed.alias('currentUser.user'),

        saved: function saved() {
            this.get('toasts').success('Vaše údaje boli uložené.');
        },


        actions: {
            init: function init() {
                this.set('changePassword', false);
            },
            logout: function logout() {
                if (confirm('Naozaj sa chcete odhlásiť?')) {
                    this.get('session').invalidate();
                }
            }
        }
    });
});