define('crm/controllers/user/wages', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        isArray = Ember.isArray,
        A = Ember.A,
        alias = Ember.computed.alias,
        computed = Ember.computed,
        htmlSafe = Ember.String.htmlSafe;
    exports.default = Ember.Controller.extend({
        month: alias('model.month'),
        year: alias('model.year'),

        title: computed('paymentDate', function () {
            var paymentDate = get(this, 'paymentDate');

            return htmlSafe('V\xFDplaty <small>(' + paymentDate.format('MM.YYYY') + ')</small>');
        }),

        paymentDate: computed('month', 'year', function () {
            var month = get(this, 'month'),
                year = get(this, 'year');

            return (0, _moment.default)().year(year).month(month).startOf('month');
        }),

        startDate: computed('paymentDate', function () {
            return get(this, 'paymentDate').clone().subtract(1, 'month');
        }),
        endDate: computed('startDate', function () {
            return get(this, 'startDate').clone().endOf('month');
        }),
        prevDate: computed('paymentDate', function () {
            return get(this, 'paymentDate').clone().subtract(1, 'month');
        }),
        nextDate: computed('paymentDate', function () {
            return get(this, 'paymentDate').clone().add(1, 'month');
        }),

        workDays: computed('model.users.@each.workDays', function () {
            return get(this, 'model.users.firstObject.workDays');
        }),

        openDiscounts: function openDiscounts(user) {
            this.send('openModal', 'user/wages/discounts', user, 'user/wages/discounts');
        },
        openDetail: function openDetail(user) {
            this.send('openModal', 'user/wages/detail', user, 'user/wages/detail');
        },
        openBillsCalculator: function openBillsCalculator(model) {
            model = !isArray(model) ? A([model]) : model;

            this.send('openModal', 'user/wages/bills-calculator', model, 'user/wages/bills-calculator');
        },
        goToDate: function goToDate(date) {
            this.setProperties({
                month: date.month(),
                year: date.year()
            });

            this.send('reload');
        },


        actions: {
            reload: function reload() {
                this.send('reloadWages', {
                    month: get(this, 'month'),
                    year: get(this, 'year')
                });
            }
        }
    });
});