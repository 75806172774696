define('crm/components/navbar/navbar-toggle', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'button',
        classNames: ['navbar-toggler'],

        isOpen: false,

        click: function click() {
            this.toggleProperty('isOpen');

            Ember.$('body').toggleClass('nav-open');
        }
    });
});