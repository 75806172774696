define('crm/models/delivery-note', ['exports', 'ember-data', 'crm/models/base', 'crm/mixins/model/persist-relationships', 'moment'], function (exports, _emberData, _base, _persistRelationships, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_persistRelationships.default, {
        received: _emberData.default.attr('date'),
        receivedBy: _emberData.default.attr('string'),
        signature: _emberData.default.attr('string'),
        date: _emberData.default.attr('date', {
            defaultValue: function defaultValue() {
                return (0, _moment.default)();
            }
        }),
        invoiced: _emberData.default.attr('boolean'),
        client: _emberData.default.belongsTo('client'),
        branch: _emberData.default.belongsTo('client-branch'),
        invoicedBranches: _emberData.default.hasMany('client-branch'),
        employee: _emberData.default.belongsTo('employee'),
        items: _emberData.default.hasMany('delivery-note-item', {
            persist: true
        }),
        invoices: _emberData.default.hasMany('invoice'),

        delivered: Ember.computed('received', function () {
            return !!this.get('received');
        }),
        undelivered: Ember.computed.not('delivered'),

        prices: Ember.computed.mapBy('items', 'price'),
        totalPrice: Ember.computed.sum('prices'),

        clientName: Ember.computed.alias('client.name')
    });
});