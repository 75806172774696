define('crm/routes/user/cards', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var set = Ember.set,
        hash = Ember.RSVP.hash;
    exports.default = Ember.Route.extend({
        model: function model() {
            return hash({
                cards: this.store.findAll('card')
            });
        },


        actions: {
            enableCard: function enableCard(card) {
                set(card, 'disabled', false);

                card.save();
            },
            disableCard: function disableCard(card) {
                set(card, 'disabled', true);

                card.save();
            },
            enterEditDialog: function enterEditDialog(card) {
                this.send('openModal', 'user/cards/edit', card, 'user/cards/edit');
            }
        }
    });
});