define('crm/components/form-wizard-step', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed,
        observer = Ember.observer;
    exports.default = Ember.Component.extend({
        classNames: ['form-wizard-step'],

        onEnter: function onEnter() {},


        isCurrent: computed('wizard.{steps.@each,current}', function () {
            var wizard = get(this, 'wizard'),
                steps = get(wizard, 'steps'),
                index = steps.indexOf(this),
                current = get(wizard, 'current');

            return current === index + 1;
        }),

        isCurrentObserver: observer('isCurrent', function () {
            if (get(this, 'isCurrent')) {
                get(this, 'onEnter')();
            }
        }),

        didInsertElement: function didInsertElement() {
            get(this, 'wizard').addStep(this);
        },
        willDestroyElement: function willDestroyElement() {
            get(this, 'wizard').removeStep(this);
        }
    });
});