define('crm/components/task-comments', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        visibleComments: Ember.computed.filterBy('comments', 'isNew', false),

        actions: {
            deleteComment: function deleteComment(comment) {
                if (confirm('Naozaj chcete vymazať vybraný komentár?')) {
                    comment.destroyRecord();
                }
            }
        }
    });
});