define('crm/components/dropdown-list/item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        tagName: ['a'],
        classNames: ['dropdown-item'],
        classNameBindings: ['selectedClass'],
        attributeBindings: ['href', 'title', 'target'],

        href: '#',
        target: null,
        title: null,
        selected: null,

        selectedClass: computed('selected', function () {
            var selected = get(this, 'selected');

            if (selected !== null) {
                return selected ? 'dropdown-item-selected' : 'dropdown-item-not-selected';
            }
        }),

        click: function click(e) {
            if (get(this, 'onClick')) {
                e.preventDefault();

                return get(this, 'onClick')();
            }
        }
    });
});