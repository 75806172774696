define('crm/components/invoice-recipients', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        classNames: ['form-group'],
        canBeDeleted: computed('recipients.@each', function () {
            return get(this, 'recipients.length') > 1;
        }),

        keyPress: function keyPress(e) {
            if (e.keyCode === 13) {
                this.addRecipient();

                e.preventDefault();
            }
        },
        init: function init() {
            this._super.apply(this, arguments);

            this.events.subscribe('form-wrapper:submit', this, 'addRecipient');
        },
        willDestroyElement: function willDestroyElement() {
            this.events.unsubscribe('form-wrapper:submit', this, 'addRecipient');
        },
        addRecipient: function addRecipient() {
            var recipient = get(this, 'recipient');

            if (recipient) {
                var recipients = get(this, 'recipients');
                recipients.addObject(recipient);

                set(this, 'recipient', null);
            }
        },
        removeRecipient: function removeRecipient(recipient) {
            get(this, 'recipients').removeObject(recipient);
        }
    });
});