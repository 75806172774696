define('crm/components/color-compare', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;


    var ColorCompareComponent = Ember.Component.extend({
        tagName: 'h6',
        classNameBindings: ['compareClass'],

        compareClass: Ember.computed('values.[]', function () {
            var values = get(this, 'values');

            if (values[0] < values[1]) {
                return 'text-danger';
            } else if (values[0] === values[1]) {
                return 'text-warning';
            } else {
                return 'text-success';
            }
        })
    });

    ColorCompareComponent.reopenClass({
        positionalParams: 'values'
    });

    exports.default = ColorCompareComponent;
});