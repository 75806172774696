define('crm/components/input-group', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['input-group'],
        classNameBindings: ['sizeClass'],

        sizeClass: Ember.computed('size', function () {
            if (this.get('size')) {
                var size = this.get('size');

                return 'input-group-' + size;
            }
        }),

        append: null,
        prepend: null,
        size: null
    });
});