define('crm/controllers/user/invoices/edit', ['exports', 'crm/controllers/user/invoices/create'], function (exports, _create) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _create.default.extend({
        title: 'Upraviť faktúru',

        saved: function saved() {
            this.get('toasts').success('Faktúra bola uložená.');
        }
    });
});