define('crm/controllers/user/challenges', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            enterAddDialog: function enterAddDialog() {
                this.send('openModal', 'user/challenges/edit', this.store.createRecord('challenge'), 'user/challenges/add');
            },
            enterEditDialog: function enterEditDialog(model) {
                this.send('openModal', 'user/challenges/edit', model, 'user/challenges/edit');
            },
            remove: function remove(model) {
                if (window.confirm('Naozaj chcete odstrániť túto výzvu?')) {
                    model.destroyRecord();
                }
            }
        }
    });
});