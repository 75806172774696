define('crm/models/lead', ['exports', 'ember-data', 'crm/models/base', 'crm/mixins/model/persist-relationships', 'moment', 'crm/config/environment'], function (exports, _emberData, _base, _persistRelationships, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;
    exports.default = _base.default.extend(_persistRelationships.default, {
        state: _emberData.default.attr('number', {
            defaultValue: 0
        }),
        createdAt: _emberData.default.attr('datetime'),
        updatedAt: _emberData.default.attr('datetime'),
        client: _emberData.default.belongsTo('client', {
            persist: true
        }),
        user: _emberData.default.belongsTo('user'),
        notes: _emberData.default.hasMany('lead-note', {
            persist: true
        }),
        analysis: _emberData.default.belongsTo('lead-analysis'),
        meeting: _emberData.default.belongsTo('meeting', {
            persist: true
        }),

        day: Ember.computed('createdAt', function () {
            return (0, _moment.default)(this.get('createdAt')).format('L');
        }),
        sortedNotes: Ember.computed('notes.@each.date', function () {
            return this.get('notes').sortBy('date');
        }),
        latestNotes: Ember.computed('sortedNotes.@each', function () {
            return this.get('sortedNotes').reverse();
        }),
        recentNote: Ember.computed.alias('sortedNotes.lastObject'),
        recentOthersNote: Ember.computed('sortedNotes', function () {
            return this.get('notes').sortBy('date').rejectBy('isMine', true).get('lastObject');
        }),

        isUnsaved: Ember.computed.empty('id'),

        //isNew: Ember.computed.equal('state', 0),
        isOpen: Ember.computed.equal('state', 1),
        isClosed: Ember.computed.equal('state', 2),
        isPaused: Ember.computed.equal('state', 3),
        isWon: Ember.computed.equal('state', 4),

        stateLabel: Ember.computed('state', function () {
            return _environment.default.leadStates[this.get('state')];
        }),

        // Sorting aliases
        meetingSortingValue: Ember.computed('meeting.{date,isToday}', function () {
            var meeting = get(this, 'meeting');

            if (get(meeting, 'isToday')) {
                return 'Today';
            } else if (get(meeting, 'day')) {
                return (0, _moment.default)(get(meeting, 'date')).format('X');
            } else {
                return 0;
            }
        }),
        clientSortingValue: Ember.computed.alias('client.name'),
        companyIdSortingValue: Ember.computed.alias('client.companyId'),
        userSortingValue: Ember.computed.alias('user.name'),
        salesUnitSortingValue: Ember.computed('user.salesUnit.id', function () {
            var salesUnit = get(this, 'user.salesUnit');

            return salesUnit ? get(salesUnit, 'name') : false;
        }),
        recentNoteSortingValue: Ember.computed('recentOthersNote.createdAt', function () {
            var recentNote = get(this, 'recentOthersNote');

            return recentNote ? get(recentNote, 'createdAt') : null;
        }),

        // Grouping aliases
        meetingGroupingValue: Ember.computed.alias('meeting.day'),
        userGroupingValue: Ember.computed.alias('user.id'),
        salesUnitGroupingValue: Ember.computed.alias('user.salesUnit.id'),

        // Filtering options
        meetingFilterByValue: Ember.computed('meeting.date', function () {
            var meeting = get(this, 'meeting');

            if (get(meeting, 'isToday')) {
                return 0;
            } else if (get(meeting, 'isPlanned')) {
                return 1;
            } else if (get(meeting, 'isPast')) {
                return -1;
            } else {
                return null;
            }
        })
    });
});